<div class="input-group">
  <div
    class="form-control search-container d-flex pe-1"
    [ngClass]="{
      expanded: expanded,
      'home-form-control': this.commonService.isHome()
    }"
    (sqClickOutside)="onClickOutside()"
  >
    <button
      id="button-addon1"
      class="btn btn-link btn-search text-primary ml-3"
      type="button"
      (click)="onInputEnter()"
      title="{{ 'msg#searchForm.search' | sqMessage }}"
      [attr.disabled]="!loginService.complete ? '' : null"
      aria-hidden="true"
    >
      <i
        [ngClass]="
          expanded
            ? 'fa-regular fa-magnifying-glass'
            : 'fa-regular fa-magnifying-glass'
        "
      ></i>
    </button>

    <!-- Search input -->
    <div class="d-flex search-form-control scrollbar-hidden">
      <input
        #searchInput
        type="text"
        spellcheck="false"
        autocomplete="off"
        class="search-input font-family-inter-regular flex-grow-1"
        [placeholder]="
          (voiceState
            ? 'msg#searchForm.listening'
            : commonService.isInTdammApp()
            ? 'Search within TDAMM'
            : 'Type to search...'
          ) | sqMessage
        "
        [disabled]="!loginService.complete"
        [(ngModel)]="editedQuery.text"
        (focus)="onInputFocus()"
        (click)="onInputClick()"
        (input)="onInputText()"
        (keyup.enter)="onInputEnter()"
        (keyup.shift.enter)="onInputEnter()"
      />
    </div>
    <!-- keyup.shift.enter is necessary for iOS13+: https://github.com/angular/angular/issues/32963 -->

    <!-- Clear Button (not displayed when expanded to avoid the confusion of users trying to close the box) -->
    <button
      aria-label="Clear the search text entered."
      type="button"
      *ngIf="editedQuery.text"
      class="btn p-0 me-3 clear-btn"
      [sqTooltip]="'msg#searchForm.clearAll' | sqMessage"
      (click)="clearForm()"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>

    <!-- Toggle voice recognition -->
    <button
      joyrideStep="eightStep"
      title="2 - VOICE SEARCH"
      [stepContent]="customContentEight"
      type="button"
      *ngIf="enableVoiceRecognition && voiceService.available"
      class="btn p-0 ml-2 voice-rec-btn"
      [sqTooltip]="'msg#searchForm.voiceRecognition' | sqMessage"
      (click)="toggleVoice()"
    >
      <i *ngIf="voiceState" class="fa-solid fa-microphone"></i>
      <i *ngIf="!voiceState" class="fa-regular fa-microphone"></i>
    </button>
    <ng-template #customContentEight>
      <p class="joyride-content">
        To conduct a speech-activated search, click the microphone icon. You
        will need to allow your web browser to access your microphone.
      </p>
    </ng-template>

    <!-- Neural search Button -->
    <!-- <button
      type="button"
      *ngIf="enableNeuralSearch && appService.isNeural()"
      class="btn p-0 me-2"
      [sqTooltip]="
        (neuralSearch
          ? 'msg#searchForm.neural.on'
          : 'msg#searchForm.neural.off'
        ) | sqMessage
      "
      [disabled]="!loginService.complete"
      (click)="toggleNeuralSearch()"
    >
      <i
        class="fas fa-fw fa-brain"
        [ngClass]="neuralSearch ? 'light-green' : 'text-muted'"
      ></i>
    </button> -->

    <!-- Apply Button -->
    <!-- <button
      type="button"
      *ngIf="canApply"
      class="btn btn-sm btn-primary fw-bold rounded-4 py-0 px-2 me-1"
      [sqTooltip]="'msg#searchForm.applyTitle' | sqMessage"
      (click)="applyFilters()"
    >
      {{ "msg#searchForm.apply" | sqMessage }}
    </button> -->

    <!-- Filter count -->
    <!-- <button
      *ngIf="showFilterCount && filterCount"
      type="button"
      class="p-0 text-muted bg-transparent border-0 position-relative me-3"
      [sqTooltip]="
        'msg#searchForm.filterCount' | sqMessage : { value: filterCount }
      "
      (click)="onFilterCountClick()"
    >
      <i class="fas fa-filter"></i>
      <span
        class="position-absolute bottom-0 badge rounded-pill bg-danger"
        style="font-size: 10px; left: 0.75rem"
      >
        {{ filterCount }}
      </span>
    </button> -->
  </div>
</div>
<div *ngIf="expanded">
  <ng-container
    [ngTemplateOutlet]="dropdown"
    [ngTemplateOutletContext]="{ $implicit: editedQuery }"
  >
  </ng-container>
</div>
