<ng-container *ngIf="metadataValue.valueItems.length > 0">
    <!-- Icon -->
    <span *ngIf="icon" class="sq-metadata-col metadata-{{layout}} {{icon}}"></span>

    <!-- Title -->
    <span *ngIf="label" class="sq-metadata-col metadata-{{layout}} sq-metadata-item-label">
        {{label | sqMessage}}:
    </span>

    <!-- Value -->
    <span class="sq-metadata-col sq-metadata-values metadata-{{layout}}" [ngClass]="{'collapsed': !!collapseRows && collapsed}" #values>
        <!-- Collapse button -->
        <a *ngIf="needsCollapse" role="button" (click)="toggleCollapse()" class="collapse-button me-1">
            <i class="fas fa-fw fa-caret-{{collapsed? 'right' : 'down'}}"></i>
        </a>

        <!-- If tree -->
        <ng-container *ngIf="metadataValue.isTree">
            <ng-container *ngFor="let valueItem of metadataValue.valueItems; let $last = last">
                <ol class="breadcrumb" *ngIf="!!valueItem.parts && valueItem.parts.length > 0">
                    <li *ngFor="let part of valueItem.parts; let $innerLast = last">
                        <ng-container *ngTemplateOutlet="valueTpl; context: {$implicit: part}"></ng-container>
                        <span *ngIf="!$innerLast" class="sq-metadata-item-tree-node-separator">{{'msg#metadata.item.treeNodeSeparator' | sqMessage}}</span>
                    </li>
                </ol>
                <span *ngIf="!$last" class="sq-metadata-item-tree-separator">{{'msg#metadata.item.treeSeparator' | sqMessage}}</span>
            </ng-container>
        </ng-container>

        <!-- Not a Tree-->
        <ng-container *ngIf="!metadataValue.isTree">
            <ng-container *ngFor="let valueItem of metadataValue.valueItems; let $last = last">
                <ng-container *ngTemplateOutlet="valueTpl; context: {$implicit: valueItem}"></ng-container>
                <span class="sq-metadata-separator" *ngIf="!$last">{{'msg#metadata.item.listSeparator' | sqMessage}}</span>
            </ng-container>
        </ng-container>

        <ng-template #valueTpl let-value>
            <span
                [attr.title]="(label || metadataValue.fnEntityTooltip) ? null : (label | sqMessage)"
                [sqTooltip]="getTooltip"
                [placement]="'top'"
                [hoverableTooltip]="true"
                [tooltipClass]="'p-0 bg-transparent'"
                [sqTooltipData]="value"
                [sqTooltipTemplate]="tooltipTpl"
                class="sq-metadata-item {{'sq-metadata-item-' + field}} {{fieldClass}}"
                [class.sq-filtered]="value.filtered"
                [class.sq-excluded]="value.excluded"
                (click)="valueClicked.emit({field: field, value: value})"
            >{{value | sqValue:metadataValue.column}}</span>
        </ng-template>

        <ng-template #tooltipTpl let-data>
            <div class="sq-metadata-tooltip">
                <div *ngIf="data.entityExtract" class="p-2">
                    <div class="sq-metadata-tooltip-entity"
                        [style.--sq-max-lines]="entityExtractMaxLines"
                        [innerHTML]="data.entityExtract">
                    </div>
                </div>
                <div *ngIf="data.actions.length" class="btn-group w-100"
                    [sq-action-buttons]="{items: data.actions, style: actionsButtonsStyle, size: actionsButtonsSize, rightAligned: true}">
                </div>
            </div>
        </ng-template>
    </span>
</ng-container>
