<form name="renameLabel" novalidate [formGroup]="form">
    <sq-modal [title]="'msg#renameLabel.title'" [buttons]="buttons" [isProcessingState]="isProcessing">
        <div class="mb-3 sq-form-group">
            <div class="alert alert-danger" role="alert">
                {{'msg#renameLabel.alertText' | sqMessage}}
            </div>
            <section class="mb-3">
                <div *ngFor="let item of model.properties.radioButtons" class="form-check">
                    <input type="radio" class="form-check-input" id="{{item.id}}" name="nature" value="{{item.value}}"
                        [checked]="item.checked" [disabled]="item.disabled" (click)="updateLabelsNature(item.value)" [ngClass]="{'disabled': item.disabled, 'clickable': !item.disabled}">
                    <label role="button" class="form-check-label" for="{{item.id}}">{{ item.name | sqMessage }}</label>
                </div>
            </section>
            
            <section class="mb-3">
                <label class="form-label fw-bold" for="label">{{'msg#renameLabel.oldLabels' | sqMessage}}</label>
                <sq-labels-autocomplete
                    (labelsUpdate)="onLabelsChanged($event)"
                    [public]="model.properties.public"
                    [disableAutocomplete]="model.properties.disableAutocomplete"
                    [allowNewLabels]="model.properties.allowNewLabels"
                    [allowManagePublicLabels]="model.properties.allowManagePublicLabels">
                </sq-labels-autocomplete>
            </section>
            
            <section>
                <label class="form-label fw-bold" for="label">{{'msg#renameLabel.newLabel' | sqMessage}}</label>
                <input
                    [sqValidation]="form"
                    type="text"
                    class="form-control"
                    id="label"
                    formControlName="label"
                    autocomplete="off"
                    spellcheck="off"
                    placeholder="{{'msg#renameLabel.newLabel' | sqMessage}}">
            </section>
        </div>
    </sq-modal>
</form>
