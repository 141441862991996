import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { Router } from "@angular/router";
import {
  SearchOptions,
  SearchService,
} from "./../../../../components/search/search.service";
import { AppService, FormatService, Query } from "./../../../../core/app-utils";
import { IntlService } from "./../../../../core/intl";
import { LoginService } from "./../../../../core/login";
import { NotificationsService } from "./../../../../core/notification";
import {
  AuditEventType,
  AuditWebService,
  Filter,
  isValueFilter,
  QueryIntentWebService,
  QueryWebService,
  Results,
} from "./../../../../core/web-services";

export const SEARCH_OPTIONS = new InjectionToken<SearchOptions>(
  "SEARCH_OPTIONS"
);

export interface ModifiedQuery extends Query {
  advanced?: any;
}

@Injectable({
  providedIn: "root",
})
export class SdeSearchService<
  T extends Results = Results
> extends SearchService<T> {
  protected _queryEJ: Query | undefined;
  intendedUseExprs: any[] = [];
  constructor(
    @Optional()
    @Inject(SEARCH_OPTIONS)
    protected override options: SearchOptions,
    protected override router: Router,
    protected override appService: AppService,
    protected override queryService: QueryWebService<T>,
    protected override loginService: LoginService,
    protected override intlService: IntlService,
    protected override formatService: FormatService,
    protected override auditService: AuditWebService,
    protected override notificationsService: NotificationsService,
    protected override queryIntentWebService: QueryIntentWebService
  ) {
    super(
      options,
      router,
      appService,
      queryService,
      loginService,
      intlService,
      formatService,
      auditService,
      notificationsService,
      queryIntentWebService
    );

    if (!this.options) {
      this.options = {
        routes: ["results", "ej/results/preview"],
      };
    }
  }

  override get query(): ModifiedQuery {
    if (!this._query) {
      this._query = this.makeQuery();
      this._events.next({ type: "new-query", query: this._query });
    }
    return this._query;
  }

  override selectScope(scope: string) {
    this.query.scope = scope;
    //  Added specific check for advanced since the dataset in focus - advanced collection wasn't getting removed when scope is selected
    if (this.query.advanced) {
      delete this.query.advanced;
    }
    this.search({});
  }

  get ejquery(): Query {
    if (!this._queryEJ) {
      this._queryEJ = this.makeQuery(undefined);
      this._events.next({ type: "new-query", query: this._queryEJ });
    }
    return this._queryEJ;
  }

  revertToDefaultQuery() {
    let defaultQuery = this.appService.defaultCCQuery;
    this.appService.ccquery = defaultQuery;
  }

  switchToEJQuery() {
    let ejQuery = this.appService.getCCQuery("query_ej_primary");
    this.appService.ccquery = ejQuery;
  }

  searchEjText(path?: string): Promise<boolean> {
    if (!this.checkEmptySearch(this.ejquery)) {
      return Promise.resolve(false);
    }
    return this.search(
      {
        path,
        analyzeQueryText: true,
      },
      this.makeAuditEvent({
        type: AuditEventType.Search_Text,
        detail: {
          text: this.ejquery.text,
          scope: this.ejquery.scope,
          language: this.intlService.currentLocale.name,
          "neural.search":
            this.appService.isNeural() && this.ejquery.neuralSearch !== false,
        },
      })
    );
  }

  getQueryFiltersLength() {
    if (Array.isArray(this.query.filters)) {
      return this.query.filters?.length;
    } else {
      return 1;
    }
  }

  getSelectsArray() {
    return this.query.select;
  }

  findFilterContainingField(
    fieldName: string,
    queryFromJson: Query
  ): Filter | undefined {
    if (queryFromJson && queryFromJson.filters) {
      return this.query.findFilter(
        (f) => isValueFilter(f) && f.field === fieldName,
        queryFromJson.filters
      );
    } else {
      return this.query.findFilter(
        (f) => isValueFilter(f) && f.field === fieldName
      );
    }
  }
}
