import { Component, Input, OnInit } from "@angular/core";
import { Snack } from "../../library/snackbar/snackbar.component";

@Component({
  selector: "app-search-action-menu",
  templateUrl: "./search-action-menu.component.html",
  styleUrls: ["./search-action-menu.component.scss"],
})
export class SearchActionMenuComponent implements OnInit {
  @Input() record: any;

  activeSnack: Snack | null;

  // Whether the record belongs to a given source

  constructor() {}

  ngOnInit(): void {}

  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }

  /*Copies text onclick. Utilities exist in Material UI for this, but cdkclipboard didn't work */
  copyText(text: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.innerText = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    console.log("copied to clipboard: ", text);
    this.activeSnack = {
      _id: "1",
      message: "Operation done.",
      duration: 5,
    };
  }

  getActiveSnack(): Snack | null {
    return this.activeSnack;
  }
}
