<sq-facet-card *ngFor="let entity of entities"
  class="my-3"
  [collapsible]="false"
  [buttonsStyle]="style">
  <sq-preview-entity-facet #facet
    [entity]="entity.type"
    [data]="entity.values"
    [preview]="preview"
    [previewData]="previewData"
    [label]="entity.display"
    [icon]="entity.icon"
    [highlights]="entity.highlights">
  </sq-preview-entity-facet>
</sq-facet-card>

<div class="d-flex justify-content-center align-items-center h-100" *ngIf="!entities">
  <div class="spinner-grow" role="status"></div>
</div>
