<!--<div class="d-inline">-->
<div class="mb-3">
    <label class="form-label">{{label | sqMessage}}</label>
    <section class="sq-dropdown-form">
        <div class="form-control d-flex flex-wrap gap-1 align-items-baseline"
            sqAdvancedFormValidation
            [field]="field"
            [validationForm]="form">
            <span
                *ngFor="let item of items"
                class="badge rounded-pill bg-secondary ms-1 cursor-default" [ngClass]="item.category">
                {{ item.display }}
                <span class="fas fa-times-circle ms-1 clickable" (click)="removeItem(item)"></span>
            </span>
            <input
                class="input-autocomplete flex-grow-1 rounded-2"
                type="text"
                autocomplete="off"
                spellcheck="off"
                sqAdvancedFormAutocompleteMultiInput
                [field]="field"
                [off]="!suggestQuery"
                [suggestQuery]="suggestQuery"
                (itemsUpdate)="onItemsChanged($event)"
                [items]="items"
                [dropdown]="dropdown"
                (keydown)="keydown($event)"
                (keypress)="keypress($event)">
        </div>
        <sq-autocomplete-list #dropdown>
            <ng-template #itemTpl let-item>
                <div class="autocomplete-item p-2">{{item.display}}
                </div>
            </ng-template>
        </sq-autocomplete-list>
    </section>
</div>
