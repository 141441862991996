<form name="overrideUser" novalidate [formGroup]="form">
    <sq-modal [title]="'msg#overrideUser.title'" [buttons]="buttons">
        <div class="mb-3 sq-form-group">
            <label class="form-label" for="userName">{{'msg#overrideUser.userName' | sqMessage}}</label>
            <input [sqValidation]="form" type="text" class="form-control" id="userName" formControlName="userName" spellcheck="off" sqAutofocus>
        </div>
        <div class="mb-3 sq-form-group">
            <label class="form-label" for="domain">{{'msg#overrideUser.domain' | sqMessage}}</label>
            <input [sqValidation]="form" type="text" class="form-control" id="domain" formControlName="domain" spellcheck="off">
        </div>
    </sq-modal>
</form>