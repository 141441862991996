<ng-container *ngIf="!!sponsoredlinks && sponsoredlinks.length">
  <div class="main-links-container py-3 px-4">
    <div class="sponsoredlinks-title font-family-rajdhani-bold pb-3">
      Relevant NASA Data Repositories
    </div>
    <ul
      class="sq-sponsored-links-list"
      *ngIf="!!sponsoredlinks && sponsoredlinks.length"
    >
      <li *ngFor="let link of sponsoredlinks" class="sponsored-item py-1">
        <div class="sq-sponsored-link-view">
          <div *ngIf="link.thumbnail" class="sq-sponsored-link-view-thumbnail">
            <a
              href="{{ link.url }}"
              (click)="click(link)"
              target="_blank"
              class="sponsored-links"
            >
              <img src="{{ link.thumbnail }}" />
            </a>
          </div>
          <div class="sq-sponsored-link-view-info">
            <a
              class="d-flex align-items-center"
              href="{{ link.url }}"
              (click)="click(link)"
              target="_blank"
              title="{{ link.tooltip || link.title | sqMessage }}"
            >
              <img
                class="link-icon"
                src="assets/img/related-links.png"
                alt="links"
              />
              <p class="link-text">{{ link.title | sqMessage }}</p>
            </a>
            <div *ngIf="link.summary" class="sq-sponsored-link-view-summary">
              {{ link.summary | sqMessage }}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
