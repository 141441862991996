import { Location } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UIService } from "@sinequa/components/utils";
import { LoginService } from "@sinequa/core/login";
import { PrincipalWebService } from "@sinequa/core/web-services";
import { HeaderService } from "sde/src/app/services/header.service";
import googleAnalyticsConstants from "../../analytics/google-analytics.constant";
import { faqQuestionsDataService } from "./../../services/faqQuestionsData.service";

@Component({
  selector: "app-help-page",
  templateUrl: "./help-page.component.html",
  styleUrls: ["./help-page.component.scss"],
})
export class HelpPageComponent implements OnInit {
  // private _loginSubscription: Subscription;
  // private _principalService: Subscription;
  public welcomeUserText: string | undefined = "Waiting for Authentication...";
  private _shouldShowCuratorMenu: boolean = false;
  @ViewChild("searchInputRef") searchInputRef: ElementRef;
  faqQuestionsData: any;
  accordionStates: boolean[] = [];
  activeAccordionIndexes: { mainIndex: number; subIndex: number }[] = [];
  searchResults: any[] = [];
  originalFaqQuestionsDataForLeftSideQuestions: any;
  originalFaqData: any; // Define a variable to store the original data
  @Input() waitUntilAuthentication: boolean = false;
  noResultsFoundPage: boolean; // Flag to track if any results are found

  // TODO: Use this to add curator menu to navbar

  constructor(
    public loginService: LoginService,
    public ui: UIService,
    private principalService: PrincipalWebService,
    private titleService: Title,
    // private intlService: IntlService,
    public headerSrv: HeaderService,
    private faqQuestionsDataService: faqQuestionsDataService,
    private location: Location
  ) {
    this.waitUntilAuthentication = false;
  }

  public get showCurator(): boolean {
    this._shouldShowCuratorMenu = this.headerSrv.showCuratorsMenu;
    return this._shouldShowCuratorMenu;
  }

  ngOnInit(): void {
    this.titleService.setTitle(googleAnalyticsConstants.currentPage.helpPage);
    // this.titleService.setTitle(this.intlService.formatMessage("msg#app.name"));
    this.principalService.events.subscribe((event) => {});
    // subscribing the accordion FAQ questions coming through Json and service
    this.faqQuestionsDataService.getFaqJsonData().subscribe((data) => {
      this.faqQuestionsData = data;
      this.originalFaqData = data;
      this.originalFaqQuestionsDataForLeftSideQuestions = data; // Store the original data
    });
  }

  // to back to previous page from where we landed
  goBack() {
    this.location.back();
  }

  handleInputChange(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    this.searchResults = [];

    if (searchTerm.length >= 3) {
      const groupedResults = {};

      const searchInObject = (obj, term) => {
        if (typeof obj === "string") {
          return obj.toLowerCase().includes(term);
        } else if (Array.isArray(obj)) {
          for (const item of obj) {
            if (searchInObject(item, term)) {
              return true;
            }
          }
        } else if (typeof obj === "object") {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (searchInObject(obj[key], term)) {
                return true;
              }
            }
          }
        }
        return false;
      };

      for (const faqItem of this.faqQuestionsData.FaqIems) {
        for (const faqQuestion of faqItem.faqQuestions) {
          if (
            searchInObject(faqQuestion.question, searchTerm) ||
            searchInObject(faqQuestion.answer, searchTerm)
          ) {
            const mainHeading = faqItem.mainHeading;

            if (!groupedResults[mainHeading]) {
              groupedResults[mainHeading] = [];
            }

            groupedResults[mainHeading].push({
              question: faqQuestion.question,
              answer: faqQuestion.answer,
              images: faqQuestion.images,
              imageWidth: faqQuestion.imageWidth, // Include imageWidth
            });
          }
        }
      }

      // Convert groupedResults object back to an array
      for (const mainHeading in groupedResults) {
        if (groupedResults.hasOwnProperty(mainHeading)) {
          this.searchResults.push({
            mainHeading: mainHeading,
            faqQuestions: groupedResults[mainHeading],
          });
        }
      }

      this.faqQuestionsData = { FaqIems: this.searchResults };

      // Check if searchResults is empty
      if (this.searchResults.length === 0) {
        this.noResultsFoundPage = true;
        // this.searchResults = []; // Clear any previous results
      } else {
        this.noResultsFoundPage = false;
        // this.searchResults = []; // Clear any previous results
      }
    } else {
      this.resetInputOnClick();
      this.noResultsFoundPage = false;
    }
  }

  handleInputClearOnCrossIcon() {
    this.resetInput();
  }

  toggleAccordion(mainIndex: number, subIndex: number): void {
    const index = this.activeAccordionIndexes.findIndex(
      (item) => item.mainIndex === mainIndex && item.subIndex === subIndex
    );

    if (index !== -1) {
      this.activeAccordionIndexes.splice(index, 1);
    } else {
      // Reset all accordion states at the same mainIndex
      const indexesToRemove = this.activeAccordionIndexes
        .filter((item) => item.mainIndex === mainIndex)
        .map((item) => this.activeAccordionIndexes.indexOf(item))
        .reverse();
      indexesToRemove.forEach((indexToRemove) => {
        this.activeAccordionIndexes.splice(indexToRemove, 1);
      });
      this.activeAccordionIndexes.push({ mainIndex, subIndex });
    }
  }

  isAccordionOpen(mainIndex: number, subIndex: number): boolean {
    return this.activeAccordionIndexes.some(
      (item) => item.mainIndex === mainIndex && item.subIndex === subIndex
    );
  }

  // first left side accordion for all the questions list
  toggleAccordionLeftSide(a: number): void {
    this.accordionStates[a] = !this.accordionStates[a];
    for (let index = 0; index < this.accordionStates.length; index++) {
      if (index !== a) {
        this.accordionStates[index] = false;
      }
    }
  }

  // Add an event listener to reset the input when the cross icon is clicked
  resetInputOnClick() {
    // calling the function in this
    this.resetInput();
  }

  resetInput() {
    if (this.originalFaqData) {
      // Clear the search results
      this.searchResults = [];
      // Restore the original data
      this.faqQuestionsData = this.originalFaqData;
      this.noResultsFoundPage = false;
    }
  }

  get showAuthentication(): boolean {
    return this.waitUntilAuthentication;
  }

  ngOnDestroy() {
    // this._loginSubscription.unsubscribe();
    // this._principalService.unsubscribe();
  }
  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }

  onClick(id) {
    let scrollToElementID = document.getElementById(id);
    if (scrollToElementID !== null && scrollToElementID !== undefined) {
      scrollToElementID.scrollIntoView(true);
    }
  }
}
