
<a *ngFor="let location of locations"
  role="button"
  class="minimap-section"
  [ngClass]="{selected: location.id === selectedId}"
  (click)="preview.select(location.id)"
  [title]="location.text"
  [style.top.%]="location.top"
  [style.height.%]="location.height">
</a>

<!-- Marker for the selected element (if not already in the list above) -->
<div *ngIf="selectedLocation && selectedLocation.type !== minimapType"
  class="minimap-section selected"
  [title]="selectedLocation.text"
  [style.top.%]="selectedLocation.top"
  [style.height.%]="selectedLocation.height">
</div>
