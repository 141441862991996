import { Component, Input, OnInit } from "@angular/core";
import { SearchService } from "@sinequa/components/search";
import { Results } from "@sinequa/core/web-services";
import { HeaderService } from "../../services/header.service";

@Component({
  selector: "app-search-facets",
  templateUrl: "./search-facets.component.html",
  styleUrls: ["./search-facets.component.scss"],
})
export class SearchFacetsComponent implements OnInit {
  @Input() results: Results;
  public hasPeopleFinder: boolean;

  constructor(
    public searchService: SearchService,
    private headerService: HeaderService
  ) {
    this.hasPeopleFinder = this.headerService.hasPeopleFinder;
  }

  ngOnInit(): void {}

  includesAstroFilters(): boolean {
    return this.results.aggregations.some(
      (aggregation) =>
        aggregation.name.includes("Messengers") ||
        aggregation.name.includes("Objects") ||
        aggregation.name.includes("Signals")
    );
  }
}
