import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ImagePreloaderService {
  private preloadedImages: Map<string, HTMLImageElement> = new Map<
    string,
    HTMLImageElement
  >();

  preload(images: string[]): Promise<void[]> {
    const promises: Promise<void>[] = [];

    images.forEach((imageUrl) => {
      if (!this.preloadedImages.has(imageUrl)) {
        const promise = new Promise<void>((resolve) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = () => {
            this.preloadedImages.set(imageUrl, img);
            resolve();
          };
        });

        promises.push(promise);
      }
    });

    return Promise.all(promises);
  }

  getImage(url: string): HTMLImageElement | undefined {
    return this.preloadedImages.get(url);
  }
}
