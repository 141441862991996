export default {
    metadata: {
        extractslocationsLabel: "Relevant extract",
        extractslocationsPluralLabel: "Relevant extracts",
        geoLabel: "Place",
        geoPluralLabel: "Places",
        personLabel: "Person",
        personPluralLabel: "People",
        companyLabel: "Company",
        companyPluralLabel: "Companies",
        treepathLabel: "Source",
        treepathPluralLabel: "Sources",
        matchlocationsLabel: "Search term",
        matchlocationsPluralLabel: "Search terms",
        titleLabel: "Title",
        titlePluralLabel: "Titles",
        authorsLabel: "Author",
        authorsPluralLabel: "Authors",
        filenameLabel: "Filename",
        filenamePluralLabel: "Filenames",
        modifiedLabel: "Date",
        modifiedPluralLabel: "Dates",
        sizeLabel: "Size",
        sizePluralLabel: "Sizes",
        docformatLabel: "Format",
        docformatPluralLabel: "Formats",
        documentlanguagesLabel: "Language",
        documentlanguagesPluralLabel: "Languages",
        geo_label: "Place",
        geo_plural_label: "Places",
        person_label: "Person",
        person_plural_label: "People",
        company_label: "Company",
        company_plural_label: "Companies",
        treepath_label: "Source",
        treepath_plural_label: "Sources",
        title_label: "Title",
        title_plural_label: "Titles",
        authors_label: "Author",
        authors_plural_label: "Authors",
        filename_label: "Filename",
        filename_plural_label: "Filenames",
        modified_label: "Date",
        modified_plural_label: "Dates",
        size_label: "Size",
        size_plural_label: "Sizes",
        docformat_label: "Format",
        docformat_plural_label: "Formats",
        documentlanguages_label: "Language",
        documentlanguages_plural_label: "Languages",
        accessLists: {
            authorizedListTitleText: "Authorized access list",
            authorizedListHeader: "Authorized access list {value, number}",
            deniedListTitleText: "Denied access list",
            deniedListHeader: "Denied access list {value, number}"
        },
        item: {
            label: "{label}:",
            separator: " ",
            treeSeparator: " ; ",
            treeNodeSeparator: " ❯ ",
            listSeparator: ", ",
            empty_boolean: 'false',
            empty_number: '0'
        },
        actions: {
            filter: "Filter",
            exclude: "Exclude",
            removeFilter: "Remove filter"
        }
    },
};
