<form name="addLabel" novalidate>
    <sq-modal [title]="'msg#bulkAddLabel.title'" [buttons]="buttons" [isProcessingState]="isProcessing">
        <div class="mb-3 sq-form-group">
            <div class="alert alert-warning" role="alert">
                {{'msg#bulkAddLabel.alertText' | sqMessage}}
            </div>
            <div *ngIf="model.properties.allowManagePublicLabels || !model.properties.public " class="alert alert-info" role="alert">
                {{'msg#bulkAddLabel.infoText' | sqMessage}}
            </div>
            <section class="mb-3">
                <div *ngFor="let item of model.properties.radioButtons" class="form-check">
                    <input type="radio" class="form-check-input" id="{{item.id}}" name="nature" value="{{item.value}}"
                        [checked]="item.checked" [disabled]="item.disabled" (click)="updateLabelsNature(item.value)" [ngClass]="{'disabled': item.disabled, 'clickable': !item.disabled}">
                    <label class="form-check-label" for="{{item.id}}">{{ item.name | sqMessage }}</label>
                </div>
            </section>
            <label class="form-label fw-bold" for="label">{{'msg#labels.labels' | sqMessage}}</label>
            <sq-labels-autocomplete
                (labelsUpdate)="onLabelsChanged($event)"
                [public]="model.properties.public"
                [disableAutocomplete]="model.properties.disableAutocomplete"
                [allowNewLabels]="model.properties.allowNewLabels"
                [allowManagePublicLabels]="model.properties.allowManagePublicLabels">
            </sq-labels-autocomplete>
        </div>
    </sq-modal>
</form>
