<ng-container *ngIf="getActiveSnack()">
  <snackbar [snack]="getActiveSnack()"></snackbar>
</ng-container>

<div class="dropdown action-menu ml-auto" id="dropdownMenuDiv">
  <button
    class="btn"
    type="button"
    id="dropdownMenuButton"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    title="{{ 'msg#actionMenu.title' | sqMessage }}"
  >
    <i
      class="far fa-ellipsis-v"
      [ngStyle]="{ color: isDark() ? '#2566a9' : 'black' }"
    ></i>
  </button>
  <div
    class="dropdown-menu dropdown-menu-right overflow-visible"
    aria-labelledby="dropdownMenuButton"
  >
    <a
      *ngIf="!record.url1"
      class="dropdown-item"
      (click)="copyText(record.filePath)"
      style="font-size: x-medium"
      [ngStyle]="{ color: isDark() ? '#2566a9' : 'black' }"
      >{{ "msg#actionMenu.fileToClipboard" | sqMessage }}
    </a>
    <a
      *ngIf="!record.url1"
      class="dropdown-item"
      (click)="
        copyText(
          record.filePath.substring(0, record.filePath.lastIndexOf('\\'))
        )
      "
      style="font-size: x-medium"
      [ngStyle]="{ color: isDark() ? '#2566a9' : 'black' }"
      >{{ "msg#actionMenu.folderToClipboard" | sqMessage }}
    </a>

    <a
      *ngIf="record.url1"
      class="dropdown-item"
      (click)="copyText(record.url1)"
      style="font-size: x-medium"
      [ngStyle]="{ color: isDark() ? '#2566a9' : 'black' }"
      >{{ "msg#actionMenu.urlToClipboard" | sqMessage }}
    </a>
    <a
      *ngIf="record.url1"
      class="dropdown-item"
      [href]="record.url1"
      target="_blank"
      style="font-size: x-medium"
      [ngStyle]="{ color: isDark() ? '#2566a9' : 'black' }"
      >{{ "msg#actionMenu.navigateToLink" | sqMessage }}
    </a>
  </div>
</div>
