import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nasa-loader',
  templateUrl: './nasa-loader.component.html',
  styleUrls: ['./nasa-loader.component.scss']
})
export class NasaLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
