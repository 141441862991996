<ng-container *ngIf="!isMobile">
  <ul
    role="presentation"
    *ngIf="!!searchtabs || !!customtabs"
    class="nav nav-tabs d-flex mb-1"
  >
    <ng-container *ngIf="!!searchtabs">
      <li
        role="tab"
        *ngFor="let tab of searchtabs.slice(1)"
        class="nav-item text-center"
      >
        <a
          role="presentation"
          href="#"
          class="nav-link"
          [ngClass]="{
            active: tab == currentTab,
            disabled: isTabDisabled(tab)
          }"
          (click)="selectTab(tab)"
        >
          <i [ngClass]="iconMap[tab.name]"></i>
          {{ tab.display || tab.name | sqMessage }}
          <span class="count" *ngIf="showCounts"
            >({{ tab.count | sqNumber }})</span
          >
        </a>
      </li>
    </ng-container>

    <ng-container *ngIf="!!customtabs">
      <li
        role="tab"
        *ngFor="let tab of customtabs"
        class="nav-item text-center"
        [ngClass]="{ active: tab == currentTab, disabled: isTabDisabled(tab) }"
      >
        <a
          role="presentation"
          href="#"
          class="nav-link"
          [ngClass]="{ active: tab == currentTab }"
          (click)="selectTab(tab, false)"
        >
          <i [ngClass]="iconMap[tab.name]"></i>
          {{ tab.display || tab.name | sqMessage }}
          <span class="count" *ngIf="showCounts && tab.count >= 0"
            >({{ tab.count | sqNumber }})</span
          >
        </a>
      </li>
    </ng-container>
  </ul>
</ng-container>

<div *ngIf="isMobile" class="tabs-container-mobile">
  <ng-container *ngIf="!!searchtabs">
    <div class="mobile-tabs">
      <div class="tabs d-flex">
        <div
          [ngClass]="{
            'tab-selected': tab == currentTab,
            disabled: isTabDisabled(tab)
          }"
          class="tab font-family-rajdhani-semibold"
          *ngFor="let tab of searchtabs.slice(1)"
        >
          <a
            href="#"
            [ngClass]="
              tab == currentTab ? 'link-text-selected' : 'link-text-unselected'
            "
            (click)="selectTab(tab)"
          >
            <span>{{ tab.display || tab.name | sqMessage }}</span>
            <span *ngIf="showCounts"> ({{ tab.count | sqNumber }}) </span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!!customtabs">
    <div
      role="tab"
      *ngFor="let tab of customtabs"
      class="nav-item text-center"
      [ngClass]="{ active: tab == currentTab, disabled: isTabDisabled(tab) }"
    >
      <a
        role="presentation"
        href="#"
        class="nav-link"
        [ngClass]="{ active: tab == currentTab }"
        (click)="selectTab(tab, false)"
      >
        <i [ngClass]="iconMap[tab.name]"></i>
        {{ tab.display || tab.name | sqMessage }}
        <span class="count" *ngIf="showCounts && tab.count >= 0"
          >({{ tab.count | sqNumber }})</span
        >
      </a>
    </div>
  </ng-container>
</div>
