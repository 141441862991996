<ul class="cloud" [attr.data-show-count]="showCount ? '' : null">
    <li *ngFor="let data of tagCloudData">
        <a
            href="#"
            [attr.data-count]="data.item.count"
            [attr.data-weight]="proportionalWeight ? data.weight : null"
            [ngClass]="data.aggregation.column"
            (click)="filterItem(data, $event)">
            <i class="far fa-check-circle filtered" *ngIf="data.item.$filtered"></i>
            {{data.item | sqValue:data.item.$column}}
        </a>
    </li>
</ul>
