<ng-container *ngIf="text || longExtracts">
    <p *ngIf="text" class="{{extractsClass}} sq-text sq-single-extract">
        <span *ngIf="record.modified && !hideDate" class="extracts-date">{{record.modified | sqDate:dateFormat}}{{!!text ? ' - ' : ''}}</span>
        <span class="extracts-text" [innerHTML]="text"></span>
    </p>
    <ng-container *ngIf="longExtracts">
        <p *ngIf="record.modified && !hideDate" class="{{extractsClass}} extracts-date sq-text">{{record.modified | sqDate:dateFormat}}</p>
        <ul *ngIf="longExtracts" class="{{extractsClass}} sq-text">
            <li *ngFor="let extract of longExtracts" [innerHTML]="extract"></li>
        </ul>
    </ng-container>
</ng-container>
