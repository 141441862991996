export default {
    "help": {
        "title": "Aide en ligne"
    },

    "overrideUser": {
        "title": "Emprunt d'identité",
        "userName": "Identifiant",
        "domain": "Domaine"
    },

    "btnClose": {
        "text": "Fermer"
    }
};
