<sq-heatmap *ngIf="data && ready && !loading"
    [data]="data"
    [height]="heightPref"
    [width]="widthPref"
    [margin]="margin"
    [transition]="transition"
    [itemsClickable]="itemsClickable"
    [axisClickable]="axisClickable"
    [buckets]="buckets"
    [colorScheme]="colorSchemePref"
    [maxX]="maxXPref"
    [maxY]="maxYPref"
    [theme]="theme"

    (itemClicked)="onItemClicked($event)"
    (axisClicked)="onAxisClicked($event)"
>
</sq-heatmap>

<div class="text-center py-5 px-3" *ngIf="!data && !loading">
    <i>{{'msg#heatmap.nodata' | sqMessage}}</i>
</div>

<div class="text-center py-5 px-3" *ngIf="loading">
    <i>{{'msg#heatmap.loading' | sqMessage}}</i>
</div>

<ng-template #settingsTpl>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="mb-3">
                <label class="form-label" for="maxX">{{'msg#heatmap.maxX' | sqMessage}} ({{maxXPref}})</label>
                <input type="range" class="form-range" id="maxX" min="10" max="40" formControlName="maxX">
                <label class="form-label" for="maxY">{{'msg#heatmap.maxY' | sqMessage}} ({{maxYPref}})</label>
                <input type="range" class="form-range" id="maxY" min="10" max="100" formControlName="maxY">
                <div class="form-check my-1" *ngIf="allowResizeOption">
                    <input class="form-check-input" type="checkbox" id="scaleAxes" formControlName="scaleAxes">
                    <label class="form-check-label" for="scaleAxes" role="button">{{'msg#heatmap.rescale' | sqMessage}}</label>
                </div>

                <hr>

                <label class="form-label" for="colors">{{'msg#heatmap.colors' | sqMessage}}</label>
                <select id="colors" class="form-select" formControlName="colors">
                    <option [ngValue]="'schemeBlues'">{{'msg#heatmap.blue' | sqMessage}}</option>
                    <option [ngValue]="'schemeReds'">{{'msg#heatmap.red' | sqMessage}}</option>
                    <option [ngValue]="'schemeGreens'">{{'msg#heatmap.green' | sqMessage}}</option>
                    <option [ngValue]="'schemeRdBu'">{{'msg#heatmap.diverging' | sqMessage}}</option>
                    <option [ngValue]="'schemeSpectral'">{{'msg#heatmap.diverging' | sqMessage}}</option>
                    <option [ngValue]="'schemeYlGnBu'">{{'msg#heatmap.cool' | sqMessage}}</option>
                </select>

                <hr>

                <button class="btn btn-light" (click)="setDefaults()">{{'msg#heatmap.defaults' | sqMessage}}</button>
            </div>
        </form>
    </div>
</ng-template>
