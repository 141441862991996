export default {
    "help": {
        "title": "Online-Hilfe"
    },

    "overrideUser": {
        "title": "Benutzer wechseln",
        "userName": "Benutzername",
        "domain": "Domäne"
    },

    "btnClose": {
        "text": "Schließen"
    }
};
