<form novalidate [formGroup]="form">
    <sq-modal
        [title]="'msg#modal.login.title'"
        [buttons]="buttons">
        <div class="mb-3 sq-form-group">
            <label class="form-label" for="userName">{{'msg#modal.login.userName' | sqMessage}}</label>
            <input [sqValidation]="form" type="text" class="form-control" id="userName" formControlName="userName" spellcheck="off" cdkFocusInitial>
        </div>
        <div class="mb-3 sq-form-group">
            <label class="form-label" for="password">{{'msg#modal.login.password' | sqMessage}}</label>
            <input [sqValidation]="form" type="password" class="form-control" id="password" formControlName="password" autocomplete="off" spellcheck="off">
        </div>
    </sq-modal>
</form>