import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { UIService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { LoginService } from "@sinequa/core/login";
import { PrincipalWebService } from "@sinequa/core/web-services";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import googleAnalyticsConstants from "../../analytics/google-analytics.constant";
import { NotificationsComponent } from "../../home/notifications/notifications.component";
import { CommonService } from "../../services/common.service";
import { HeaderService } from "../../services/header.service";

@Component({
  selector: "app-contact-us-page",
  templateUrl: "./contact-us-page.component.html",
  styleUrls: ["./contact-us-page.component.scss"],
})
export class ContactUsPageComponent implements OnInit {
  contactUsForm: FormGroup;
  contactUsFormSubmitted: boolean = false;
  contactUsContainer: any;
  userFullName: string | undefined = "";
  notifications: [];
  source: string = "SDE";

  constructor(
    private commonService: CommonService,
    private titleService: Title,
    // private intlService: IntlService,
    private principalService: PrincipalWebService,
    private headerSrv: HeaderService,
    private appService: AppService,
    public loginService: LoginService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public ui: UIService
  ) {
    this.route.queryParamMap.subscribe((p: any) => {
      if (p?.params?.source) this.source = p.params.source;
    });
    // this.ui.screenSizeIsLessOrEqual;
  }

  ngOnInit(): void {
    this.principalService.events.subscribe((event) => {
      if (this.principalService.principal?.fullName != undefined) {
        this.userFullName =
          environment.title + "-" + this.principalService.principal?.fullName;
      } else this.userFullName = "Waiting for Authentication...";
    });
    this.titleService.setTitle(
      googleAnalyticsConstants.currentPage.contactUsPage
    );

    this.loginService.events.subscribe((event) => {
      if (!this.headerSrv.notificationShown && this.appService.app) {
        this.showNotifications();
        this.headerSrv.notificationShown = !this.headerSrv.notificationShown;
      }
    });

    this.contactUsForm = new FormGroup({
      firstName: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      subject: new FormControl("", [Validators.required]),
      comments: new FormControl("", Validators.required),
    });
  }
  get f() {
    return this.contactUsForm.controls;
  }

  setContainerHeight(containerRef: HTMLDivElement) {
    let top = containerRef.getBoundingClientRect().top;
    /* prettier-ignore */
    let style = {
      "overflow-y" : "auto",
      "height": `calc(100vh - ${top + "px"})`,
    };
    return style;
  }

  showNotifications() {
    this.notifications = this.appService.app?.data?.notifications as any;
    if (this.notifications != null) {
      this.notifications?.forEach(
        (app: { id: number; title: string; details: string }, index: any) => {
          this.openDialog(app.title, app.details);
        }
      );
    }
  }
  goBack() {
    this._location.back();
  }
  goBackToEjHome() {
    this.router.navigate(["ej/home"]);
  }
  goBackToSDEHome() {
    this.router.navigate(["/home"]);
  }
  openDialog(title, details) {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      hasBackdrop: true,
      width: "450px",
      height: "200px",
      position: {
        top: "50vh",
        left: "36vw",
      },
      panelClass: "makeItMiddle",
      autoFocus: true,
      disableClose: true,
      backdropClass: "custom-dialog-backdrop-class",
      data: {
        title: title,
        details: details,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  onSubmitContactUsForm() {
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched();
      this.contactUsFormSubmitted = false;
    } else {
      let request = {
        name: this.contactUsForm.value.firstName,
        email: this.contactUsForm.value.email,
        subject: this.contactUsForm.value.subject,
        comments: this.contactUsForm.value.comments,
        source: this.source,
      };
      this.commonService.submitContactUsForm(request).subscribe({
        next: (response) => {
          this.contactUsFormSubmitted = true;
        },
        error: (error) => {
          throwError(() => new Error("Please try again later"));
        },
      });
    }
  }
}
