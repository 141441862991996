import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import googleAnalyticsConstants from "sde/src/app/analytics/google-analytics.constant";
import { CommonService } from "sde/src/app/services/common.service";
import { ImagePreloaderService } from "sde/src/app/services/image-preloader.service";
import { SearchStoryDataService } from "./../../../services/search-story-data.service";

@Component({
  selector: "app-search-story",
  templateUrl: "./search-story.component.html",
  styleUrls: ["./search-story.component.scss"],
})
export class SearchStoryComponent implements OnInit {
  searchStoryData: any;
  readMoreState: boolean[] = [];

  constructor(
    private searchStoryService: SearchStoryDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    public ui: UIService,
    public imagePreloadService: ImagePreloaderService,
    private titleService: Title,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.commonService.globalNasaLoader.next(true);
    this.titleService.setTitle(
      googleAnalyticsConstants.currentPage.mySearchStory
    );
    this.searchStoryService.getSearchStoryData().subscribe((data: any) => {
      this.searchStoryData = data.SearchStoryItems;
      this.searchStoryData.forEach(() => this.readMoreState.push(false));
    });

    this.activatedRoute.data.subscribe((data) => {
      if (data) {
      
        this.commonService.globalNasaLoader.next(false);
      }
    });
  }

  setImagesForStory(images) {
    let image = this.imagePreloadService.getImage(images);
    return image?.src;
  }

  goToHomepage() {
    this.router.navigate(["/home"]);
  }

  goToResultsPage(text) {
    this.searchService.query.text = text;
    this.searchService.searchText("search");
  }

  toggleReadMore(i) {
    this.readMoreState[i] = !this.readMoreState[i];
  }

  submitYours() {
    window.open("https://forms.gle/dxmZFomYBPdhPkor8", "_blank");
  }
}
