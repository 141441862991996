import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";
import { createPopper } from "@popperjs/core";

@Directive({
  selector: "[appTooltip]",
})
export class TooltipDirective {
  @Input("appTooltip") tooltipContent: string;
  @Input() hoverHelpEnabled: boolean;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener("mouseenter")
  onMouseEnter(): void {
    if (this.hoverHelpEnabled && this.tooltipContent.length > 0) {
      this.showTooltip();
    }
  }

  @HostListener("mouseleave")
  @HostListener("focusout")
  @HostListener("click")
  onMouseLeave(): void {
    if (this.hoverHelpEnabled) {
      this.hideTooltip();
    }
  }

  private showTooltip(): void {
    const tooltip = this.renderer.createElement("div");
    this.renderer.addClass(tooltip, "tooltip");
    const img = this.renderer.createElement("img");
    this.renderer.setAttribute(img, "src", "assets/img/info.svg");
    this.renderer.setStyle(img, "margin-right", "5px");

    const contentSpan = this.renderer.createElement("span");
    this.renderer.appendChild(
      contentSpan,
      this.renderer.createText(this.tooltipContent)
    );

    this.renderer.appendChild(tooltip, img);
    this.renderer.appendChild(tooltip, contentSpan);
    // Append the tooltip to the elementRef.nativeElement
    this.renderer.appendChild(this.elementRef.nativeElement, tooltip);
    // Apply CSS styles to the tooltip
    this.renderer.setStyle(tooltip, "position", "absolute");
    this.renderer.setStyle(tooltip, "color", "black");
    this.renderer.setStyle(tooltip, "background-color", "#FFF7AD");
    this.renderer.setStyle(tooltip, "padding", "5px");
    this.renderer.setStyle(tooltip, "border-radius", "4px");
    this.renderer.setStyle(tooltip, "font-size", "18px");
    this.renderer.setStyle(tooltip, "z-index", "9999");
    this.renderer.setStyle(tooltip, "opacity", "1");
    this.renderer.setStyle(tooltip, "transition", "opacity 0.2s ease-in-out");
    this.renderer.setStyle(tooltip, "white-space", "nowrap"); // Prevent line breaks

    // using popper.js
    createPopper(this.elementRef.nativeElement, tooltip, {
      placement: "bottom-start",
    });
  }

  private hideTooltip(): void {
    const tooltip = this.elementRef.nativeElement.querySelector(".tooltip");

    if (tooltip) {
      this.renderer.removeChild(this.elementRef.nativeElement, tooltip);
    }
  }
}
