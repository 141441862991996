<form name="manageSavedQueries" novalidate>
    <sq-modal [title]="'msg#manageSavedQueries.title'" [buttons]="buttons">
        <div *ngIf="model.savedQueries.length" class="mb-3 clearfix">
            <button type="button" class="btn btn-secondary float-right" (click)="reorder()">{{(reordering ? "msg#manageSavedQueries.save" : "msg#manageSavedQueries.reorder") | sqMessage}}</button>
        </div>
        <div class="mb-3">
            <ul class="list-group" cdkDropList [cdkDropListData]="model.savedQueries" [cdkDropListDisabled]="!reordering" (cdkDropListDropped)="dropped($event)">
                <li *ngFor="let savedQuery of model.savedQueries; let $index = index" cdkDrag 
                    role="button"
                    class="list-group-item list-group-item-action d-flex"
                    [ngClass]="{'cursor-move': reordering}">
                    <div *ngIf="reordering" class="sq-saved-query-text">{{savedQuery.name}}</div>
                    <sq-editable *ngIf="!reordering" [value]="savedQuery.name" (valueChange)="setName(savedQuery, $event)" name="msg#manageSavedQueries.name" [model]="savedQuery" [validators]="nameValidators"></sq-editable>
                    <div class="ms-auto">
                        <i *ngIf="!reordering && savedQueriesService.hasExportConfig()" class="fas fa-download sq-export ms-2" (click)="export(savedQuery)" title="{{'msg#manageSavedQueries.export' | sqMessage}}"></i>
                        <a *ngIf="!reordering && savedQueriesService.hasRssEnabled()" class="ms-2" href="{{savedQueriesService.rssHref(savedQuery)}}" target="_blank" title="{{'msg#manageSavedQueries.rss' | sqMessage}}">
                            <i class="fas fa-rss sq-rss"></i>
                        </a>
                        <i *ngIf="!reordering" class="fas fa-times sq-remove ms-2" (click)="remove(savedQuery, $index)" title="{{'msg#manageSavedQueries.remove' | sqMessage}}"></i>
                        <i *ngIf="reordering" class="ms-2 fas fa-bars sq-move"></i>
                    </div>
                </li>
            </ul>
        </div>
    </sq-modal>
</form>
