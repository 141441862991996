import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Record } from "@sinequa/core/web-services";
import { SearchService } from "@sinequa/components/search";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { CopierService } from "../../services/copier.service";
import {
  NotificationType,
  NotificationsService,
} from "@sinequa/core/notification";

@Component({
  selector: "share-document-popup",
  templateUrl: "./share-document-popup.component.html",
  styleUrls: ["./share-document-popup.component.scss"],
})
export class ShareDocumentPopupComponent implements OnInit {
  copyPath: string = "";

  constructor(
    public dialogRef: MatDialogRef<ShareDocumentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public recordData: Record,
    private searchService: SearchService,
    private previewService: PreviewService,
    private urlCopierService: CopierService,
    private notificationsService: NotificationsService,
    private router: Router
  ) {}

  ngOnInit() {}

  closeShareDocument() {
    this.dialogRef.close();
  }

  openEmailDraftWithLink() {
    this.copyURL(false);
    const body =
      "Hi [Recipient's Name], \n\n Please click the link below to view the document shared with you: \n\n" +
      this.copyPath +
      "\n\n\n" +
      "If you have any questions or need further assistance, feel free to contact me. \n \n" +
      "Thank you! \n\n";
    const link =
      "mailto:" +
      "?subject=Science Discovery Engine - Document Link" +
      "&body=" +
      encodeURIComponent(body);
    window.location.href = link;
  }

  copyURL(copy = false) {
    this.copyPath = "";
    const appendPath = this.router.createUrlTree(["preview"], {
      queryParams: {
        id: this.recordData.id,
        query: this.previewService
          .makeQuery(this.searchService.query)
          .toJsonForQueryString(),
      },
    });
    if (!copy) {
      this.copyPath =
        window.location.origin + "#" + this.router.serializeUrl(appendPath);
    } else {
      this.urlCopierService.copyText(
        window.location.origin + "#" + this.router.serializeUrl(appendPath)
      );
      this.notificationsService.notify(
        NotificationType.Success,
        "msg#actionMenu.urlCopiedToClipboard",
        {},
        undefined,
        true
      );
    }
    this.closeShareDocument();
  }
}
