// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  app: "nasa-sba-smd", //Passed to startConfig Sinequa.core
  ejApp: "nasa-sba-ej",
  title: "QA Environment",
  production: false, //Passed to startConfig Sinequa.core
  environmentName: "qa",
  // autoSAMLProvider: "Dev-Angular",
  url: "http://sde-qa.nasa-impact.net/",
  apiUrl: "http://sde-qa.nasa-impact.net/",
  feedbackURL: "https://sde-indexing-helper.nasa-impact.net/feedback/",
  appPrefix: "smd",
  googleAnalyticsKey: "G-RW18TBBJQG",
  googleAnalyticsDebugMode: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
