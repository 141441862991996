import { Directive, HostListener } from "@angular/core";
import { CommonService } from "../services/common.service";
@Directive({
  selector: "[scroll-adjust]",
})
export class ScrollAdjustDirective {
  constructor(private commonService: CommonService) {}

  @HostListener("scroll", ["$event"]) onContainerScroll(event: any) {
    // console.log('Scroll Position', event.target.scrollTop);
    this.commonService.currentScrollPosition = event.target.scrollTop;
  }
}
