import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JsonMethodPluginService, Results } from "@sinequa/core/web-services";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  _showMenu: boolean = false;
  public showCUIBanner: boolean =
    false; /* Show government banner when go to URL */
  public results: Results | undefined;
  public notificationShown: boolean = false; //TODO: until I find a better way
  public hasFilterEnabled = true; // This is the flag that turns the filter all the time
  public _showFilters: boolean = this.hasFilterEnabled; // true if filter pane is visible
  private _shouldShowCuratorMenu: boolean = false;
  public _peopleFinderIsLoaded: boolean = false;
  public hasCuratorReport: boolean = false; //Enable curator report capability
  public hasPeopleFinder: boolean = false; // Enable the peoplefinder capability
  public peopleInformation: any;
  public peopleInformationParsed: any;
  private _peopleSubscription: Subscription;
  constructor(
    private router: Router,
    public pluginService: JsonMethodPluginService
  ) {
    if (this.hasCuratorReport) {
      this.pluginService
        .get("CuratorVerificationEndpoint", {})
        .subscribe((isCurator) => {
          if (isCurator.is_curator) {
            this._shouldShowCuratorMenu = isCurator.is_curator === true;
          }
        });
    }
  }

  ngDestroy() {
    if (this.peopleInformationParsed != null)
      this.peopleInformationParsed = null;
    if (this._peopleSubscription) this._peopleSubscription.unsubscribe();
  }

  get showCuratorsMenu() {
    return this._shouldShowCuratorMenu;
  }

  get showMenu() {
    return this._showMenu;
  }

  get showFilters() {
    return this._showFilters;
  }

  set showFilters(value: boolean) {
    this._showFilters = value;
  }

  get isMinimal() {
    if (this.router.routerState.snapshot.url.startsWith("/search")) {
      return false;
    } else {
      return true;
    }
  }

  /*     get isFilterToggleVisible() {
        return this._isFilterToggleVisible || this.hasFilterEnabled ;
    } */

  toggleMenu() {
    this._showMenu = !this._showMenu;
  }

  toggleFilters() {
    this._showFilters = !this._showFilters;
  }
  goToResultWithPreview() {
    if (!this.hasFilterEnabled) this._showFilters = false;
  }

  getPeopleFinderData() {
    if (this.hasPeopleFinder && !this._peopleFinderIsLoaded) {
      if (this.peopleInformationParsed != null) {
        this.peopleInformationParsed = null;
      }
      const obs = this.pluginService.post("GetPeopleFinderInformation", {});
      this._peopleSubscription = obs.subscribe((response) => {
        console.log(new Date(Date.now()), "Got PeopleFinder Information");
        this._peopleFinderIsLoaded = true;

        this.peopleInformationParsed = JSON.parse(response.data);
        response = null;
      });
    } else {
      if (this.hasPeopleFinder)
        console.log(
          new Date(Date.now()),
          "Already cached...PeopleFinder is loaded."
        );
    }
  }
}
