<!-- Obviously, you could use HTML5 tags like `header`, `footer` and `section` -->

<div class="h-100" [ngClass]="{ bg: _isBackgroundVisible, box: _isPreview }">
  <ng-container *ngIf="globalLoader">
    <app-nasa-loader></app-nasa-loader>
  </ng-container>
  <!-- Application body where routing takes place -->
  <router-outlet></router-outlet>

  <!-- Root level notifications component -->
  <!--   Do not show Server errors - TODO:implement Sinequa Notification Module
per Sinequa documentation -->
  <sde-notifications></sde-notifications>
</div>
