<div [@autoClose]="autoClose" class="alert alert-{{alertClass}} sq-notification alert-dismissible" role="alert">
    <div class="sq-notification-container">
        <div>
            <i class="sq-notification-icon {{notificationClass}}"></i>
        </div>
        <div class="sq-notification-message">
            <span *ngIf="!!notification.title" class="sq-notification-title">{{notification.title | sqMessage}}</span>
            <p class="sq-notification-text">{{notification.text | sqMessage:{values: notification.params} }}</p>
        </div>
    </div>
    <button *ngIf="showClose" type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>