<div class="modal-footer">
    <ng-container *ngIf="!isProcessingState">
        <section *ngFor="let button of buttons">
            <button *ngIf="button.visible && !button.anchor" type="{{button.primary ? 'submit' : 'button'}}" class="btn {{button.primary ? 'btn-primary' : button.result < 0 ? 'btn-outline-secondary' : 'btn-secondary'}}" (click)="buttonClick(button)">{{button.getText() | sqMessage}}</button>
            <a *ngIf="button.visible && button.anchor"
                href="#"
                class="btn {{button.primary ? 'btn-primary' : button.result < 0 ? 'sq-anchor-btn' : 'btn-secondary'}}"
                (click)="buttonClick(button)">{{button.getText() | sqMessage}}</a>
        </section>
    </ng-container>
    <ng-container *ngIf="isProcessingState">
        <span class="loader"></span>
        <button type="button" class="btn" (click)="close()">{{ 'msg#btnClose.text' | sqMessage }}</button>
    </ng-container>
    <ng-content></ng-content>
</div>


