import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { environment } from "sde/src/environments/environment";
import { ResultTitle } from "./../../../../../components/result/result-title/result-title";

@Component({
  selector: "app-sde-result-title",
  templateUrl: "./sde-result-title.component.html",
  styleUrls: ["./sde-result-title.component.scss"],
})
export class SdeResultTitleComponent extends ResultTitle {
  constructor(
    public override searchService: SearchService,
    private router: Router,
    private previewService: PreviewService
  ) {
    super(searchService);
  }

  // NB: Do we have a better way to do this?
  getDocumentNavigatorURL() {
    let query = this.searchService.query.copy();
    const urlTree = this.router.createUrlTree(["/preview"], {
      queryParams: {
        id: this.record.id,
        query: this.previewService.makeQuery(query).toJsonForQueryString(),
      },
    });

    if (window.location.origin.includes("localhost")) {
      return window.location.origin + "/#" + this.router.serializeUrl(urlTree);
    } else {
      // prettier-ignore
      return window.location.origin + "/app/" + environment.app + "/#" + this.router.serializeUrl(urlTree);
    }
  }
}
