import { Inject, Injectable } from "@angular/core";
import { Utils } from "@sinequa/core/base";
import {
  HttpService,
  SqHttpClient,
  StartConfig,
  START_CONFIG,
} from "@sinequa/core/web-services";
import { throwError } from "rxjs";

// Interface to be updated
export interface AcronymAndFullForm {}

@Injectable({
  providedIn: "root",
})
export class AcronymService extends HttpService {
  constructor(
    @Inject(START_CONFIG) startConfig: StartConfig,
    protected override httpClient: SqHttpClient
  ) {
    super();
  }

  protected endPoint = "search.dataset/dataset_smd_acronym";

  getConfig(body) {
    if (!body) {
      return throwError({ error: "no search criteria for acronym" });
    }
    const observable = this.httpClient.post<AcronymAndFullForm>(
      this.makeUrl(this.endPoint),
      body
    );
    Utils.subscribe(
      observable,
      (response) => {
        console.log("acronym success - data: ", response);
        return response;
      },
      (error) => {
        console.log("acronym failure - error: ", error);
      }
    );
    return observable;
  }
}
