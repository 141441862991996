<div class="mb-3" [formGroup]="form">
    <label class="form-label" for="{{forName}}">{{label | sqMessage}}</label>
    <div *ngIf="!isDate"
        class="d-flex align-items-center justify-content-between gap-2 sq-advanced-form-range"
        sqAdvancedFormValidation
        [field]="field"
        [validationForm]="form"
        [id]="field">
        <div class="flex-grow-1">
            <input
                type="text"
                autocomplete="off"
                spellcheck="off"
                class="form-control sq-range-from"
                [id]="fromName"
                [ngModel]="value[0]"
                (ngModelChange)="updateFrom($event)"
                [ngModelOptions]="{standalone: true}"/>
        </div>
        <div class="sq-separator">{{'msg#advanced.rangeSeparator' | sqMessage}}</div>
        <div class="flex-grow-1">
            <input
                type="text"
                autocomplete="off"
                spellcheck="off"
                class="form-control sq-range-to"
                [id]="toName"
                [ngModel]="value[1]"
                (ngModelChange)="updateTo($event)"
                [ngModelOptions]="{standalone: true}"/>
        </div>
    </div>
    <sq-date-range-picker *ngIf="isDate"
        [options]="{name: field, system: false, closedRange: false, minDate: minDate, maxDate: maxDate}"
        [formControlName]="field"
        [id]="field"
        sqAdvancedFormValidation
        [field]="field"
        [validationForm]="form">
    </sq-date-range-picker>
</div>
