import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent {
  title: string;
  details: string;
  fromDialog: string = ""; // if you want to send data back to the calling component
  constructor(
    public dialogRef: MatDialogRef<NotificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.details = data.details;
  }
  close() {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.fromDialog });
  }
}
