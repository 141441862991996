<span>
  <a
    href="#"
    *ngFor="let s of source"
    class="path font-family-inter-semi-bold"
    (click)="selectModified(s, $event)"
    title="Select this source"
    >{{ s.display }}</a
  >
</span>
