<div
  class="list-group list-group-flush search-bar-autocomplete"
  *ngIf="items.length"
>
  <a
    role="button"
    *ngFor="let item of items; let i = index"
    class="list-group-item list-group-item-action"
    [hidden]="hideCategory(item)"
    [ngClass]="{ 'list-group-item-primary': i === selectedIndex }"
    (click)="selectItem(item)"
  >
    <!-- Autocomplete icon -->
    <!-- <i
      class="icon flex-shrink-0"
      [ngClass]="autocompleteIcon(item.category)"
    ></i> -->

    <!-- Autocomplete display -->

    <div class="autocomplete-item p-2">
      <span class="main-text">{{ generateSuggestTitle(item?.display) }}</span>
      <div class="container">
        <div class="flex-fields">
          <!-- Contents of the div that starts at the beginning -->
          <div class="text-new" *ngIf="item?.scientific_focus">
            <label class="text">Focus: </label>
            <label ngDefaultControl> &nbsp;{{ item?.scientific_focus }} </label>
          </div>
          <div class="text-new" *ngIf="item?.itemformat">
            <label class="text">File Type: </label>
            <label ngDefaultControl> &nbsp;{{ item?.itemformat }} </label>
          </div>
          <div class="text-new" *ngIf="item?.modified">
            <label class="text">Updated on: </label>
            <label ngDefaultControl> &nbsp;{{ item?.modified }} </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Autocomplete category -->
    <!-- <span
      *ngIf="item.category"
      class="category small text-muted text-nowrap ms-3"
    >
      {{ item.label || item.category | sqMessage }}
    </span> -->
  </a>
</div>
