<div class="mb-3" [formGroup]="form">
    <div class="form-check">
        <input
            id="check_{{field}}"
            class="form-check-input"
            type="checkbox"
            sqAdvancedFormValidation
            [field]="field"
            [validationForm]="form"
            [formControlName]="field">
        <label role="button" for="check_{{field}}" class="form-check-label">{{label | sqMessage}}</label>
    </div>
</div>
