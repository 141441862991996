import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class faqQuestionsDataService {
  constructor(private http: HttpClient) {}

  getFaqJsonData() {
    return this.http
      .get("assets/faqQuestionsData.json")
      .pipe(tap((data) => {}));
  }
}
