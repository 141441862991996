<ul class="pagination my-0" *ngIf="pageCount > 1" role="presentation">
  <li
    *ngFor="let item of items"
    title="{{ item.title | sqMessage : { values: { page: item.page } } }}"
    class="page-item"
    [ngClass]="{
      active: item.active,
      disabled: item.disabled,
      'sq-navigation': item.isNavigation,
      'sq-page': item.isPage,
      'sq-ellipsis': item.isEllipsis
    }"
    (click)="onItemClick($event, item.page, item.disabled)"
  >
    <a role="presentation" href="javascript:void(0)" class="page-link" [attr.aria-label]="getPageLabel(item.display, item.currentPage)">
      <span>{{ item.display | sqNumber }}</span>
    </a>
  </li>
</ul>
