export default {
    "preview": {
        "previous": "Vorheriges",
        "next": "Nächstes",
        "relevanceSortHighlightButtonText": "Relevanz",
        "textOrderSortHighlightButtonText": "Textreihenfolge",
        "showAll": "Zeige alle Werte",
        "sortAlphabetically": "Alphabetische Sortierung",
        "sortFrequency": "Sortierung nach Häufigkeit",
        "loading": "Laden...",
        "noextract": "kein Auszug",
        "search": "Suche",
        "closeTitle": "Schließen",
        "expandTitle": "Maximieren",
        "minimize": "Rauszoomen",
        "maximize": "hineinzoomen",
        "toggleHighlight": "Alle Hervorhebungen schalten",
        "toggleExtracts": "Schalten Sie die Hervorhebung relevanter Auszüge um",
        "extracts": "Relevante Auszüge",
        "toggleEntities": "Schalten Sie die Hervorhebung von Objekten",
        "entities": "Objekten",
        "downloadPdf": "PDF-Version herunterladen",
        "viewPreview": "Vorschau",
        "viewPassages": "Ausschnitte",
        "viewDuplicates": "Duplikate",
        "documentPreview": "Dokumentvorschau",
        "documentPreviewTitle": "Dies ist die Dokumentenvorschau",
        "documentPreviewDescription": "Klicken Sie auf eines Ihrer Suchergebnisse, um das Dokument hier in der Vorschau anzuzeigen."
    }
};
