import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BsActionModule } from "@sinequa/components/action";
import { CollapseModule } from "@sinequa/components/collapse";
import { FiltersModule } from "@sinequa/components/filters";
import { MetadataModule } from "@sinequa/components/metadata";
import { BsNotificationModule } from "@sinequa/components/notification";
import { BsSearchModule } from "@sinequa/components/search";
import { UtilsModule } from "@sinequa/components/utils";
import { IntlModule } from "@sinequa/core/intl";
import { AgGridModule as AGModule } from "ag-grid-angular";
import { JoyrideModule } from "ngx-joyride";
import { BsFacetModule } from "../../../../components/facet/bootstrap/facet.module";
import { ResultModule } from "../../../../components/result/result-module";
import { SdePreviewRelatedDocsPanelComponent } from "../../app/components/sde-preview-related-docs-panel/sde-preview-related-docs-panel.component";
import { SdeFacetCardComponent } from "../components/sde-facet-card/sde-facet-card.component";
import { SdePreviewEntityFacetComponent } from "../components/sde-preview-entity-facet/sde-preview-entity-facet.component";
import { SdePreviewEntityPanelComponent } from "../components/sde-preview-entity-panel/sde-preview-entity-panel.component";
import { SdeMetadataViewerModule } from "../sde-metadata-viewer/sde-metadata-viewer.module";
import { SdeFacetListComponent } from "./../components/sde-facet-list/sde-facet-list.component";
import { SdePagerComponent } from "./../components/sde-pager/sde-pager.component";
import { SdePreviewExtractsPanelComponent } from "./../components/sde-preview-extracts-panel/sde-preview-extracts-panel.component";
import { SdePreviewComponent } from "./../components/sde-preview/sde-preview.component";
import { SdeAppliedFiltersListComponent } from "./sde-applied-filters-list/sde-applied-filters-list/sde-applied-filters-list.component";
import { SdeAppliedFiltersComponent } from "./sde-applied-filters/sde-applied-filters/sde-applied-filters.component";
import { SdeDidYouMeanComponent } from "./sde-did-you-mean/sde-did-you-mean.component";
import { SdeNotificationComponent } from "./sde-notification/sde-notification.component";
import { SdeNotificationsComponent } from "./sde-notifications/sde-notifications.component";
import { SdeResultSourceComponent } from "./sde-result-source/sde-result-source.component";
import { SdeResultTitleComponent } from "./sde-result-title/sde-result-title.component";
import { SdeSearchFormComponent } from "./sde-search-form/sde-search-form/sde-search-form.component";
import { SdeSponsoredLinksComponent } from "./sde-sponsored-links/sde-sponsored-links.component";
import { SdeTabsComponent } from "./sde-tabs/sde-tabs.component";

@NgModule({
  exports: [
    IntlModule,
    CommonModule,
    FormsModule,
    AGModule,
    ReactiveFormsModule,
    SdePreviewRelatedDocsPanelComponent,
    SdePreviewEntityFacetComponent,
    SdePreviewEntityPanelComponent,
    SdeFacetCardComponent,
    SdePreviewExtractsPanelComponent,
    SdeFacetListComponent,
    SdePagerComponent,
    SdePreviewComponent,
    SdeSponsoredLinksComponent,
    SdeResultSourceComponent,
    SdeNotificationComponent,
    SdeNotificationsComponent,
    SdeTabsComponent,
    SdeDidYouMeanComponent,
    SdeSearchFormComponent,
    SdeAppliedFiltersComponent,
    SdeResultTitleComponent,
  ],
  declarations: [
    SdePreviewRelatedDocsPanelComponent,
    SdePreviewEntityFacetComponent,
    SdePreviewEntityPanelComponent,
    SdeFacetCardComponent,
    SdePreviewExtractsPanelComponent,
    SdeFacetListComponent,
    SdePagerComponent,
    SdePreviewComponent,
    SdeSponsoredLinksComponent,
    SdeResultSourceComponent,
    SdeNotificationComponent,
    SdeNotificationsComponent,
    SdeTabsComponent,
    SdeDidYouMeanComponent,
    SdeSearchFormComponent,
    SdeAppliedFiltersComponent,
    SdeAppliedFiltersListComponent,
    SdeResultTitleComponent,
  ],
  providers: [],
  imports: [
    FiltersModule,
    CommonModule,
    BsSearchModule,
    IntlModule,
    BsActionModule,
    MetadataModule,
    SdeMetadataViewerModule,
    ScrollingModule,
    UtilsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    BsFacetModule,
    BsNotificationModule,
    AGModule,
    ResultModule,
    JoyrideModule.forRoot(),
  ],
})
export class InheritedModule {}
