<ng-container *ngIf="data">
    <div #slider class="card-body">
        <ngx-slider
            *ngIf="sliderActive"
            [(value)]="value"
            [(highValue)]="highValue"
            [options]="options"
            [manualRefresh]="manualRefresh"
            (userChangeEnd)="onUserChangeEnd($event)">
        </ngx-slider>
        <span *ngIf="!sliderActive">{{'msg#facet.range.unavailable' | sqMessage}}</span>
    </div>
</ng-container>
