import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { Query } from "@sinequa/core/app-utils";
import { Utils } from "@sinequa/core/base";
import {
  Aggregation,
  Record,
  StartConfig,
  START_CONFIG,
} from "@sinequa/core/web-services";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { BehaviorSubject, Subject, Subscription, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import googleAnalyticsConstants from "../analytics/google-analytics.constant";
import { SdeRecord } from "../model/extended.interface";
import { CopierService } from "./copier.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public _loadingFlag: boolean = true;
  private sciXLocalUrl = "/v1/search/query";
  private sciXApiUrl = "https://scixplorer.org/v1/search/query";
  private sciXApiToken = "iwwLL05WwFJVwI87Tq8XOftmMtk4sdUeVIUSCLmc";
  searchText: string;
  sciXCount: number;
  resultsCardLoader: boolean;
  selectedScientificFocus: string = "";
  modalRef?: BsModalRef;
  dropdownSubscription: Subscription;
  currentScrollPosition: any;
  globalNasaLoader: BehaviorSubject<any> = new BehaviorSubject(false);
  closeMetadataViewer: BehaviorSubject<any> = new BehaviorSubject(false);
  clearInputFieldSource: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );
  isFocusDropdownDisabledEvent: Subject<boolean> = new Subject();
  // Observable to listen for clearing input field action
  clearInputField$ = this.clearInputFieldSource.asObservable();
  clearFilterEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  ejSelectedFocusChange: Subject<string> = new Subject<string>();
  // for ngx joyride walkthrough SDE , flags checks to start walkthrough on results page else home page
  public currentPageSource = new Subject<string>();
  currentPage$ = this.currentPageSource.asObservable();
  // for ngx joyride walkthrough SDE -EJ, flags checks to start walkthrough on results page else home page
  public currentPageSourceEj = new Subject<string>();
  currentPageEj$ = this.currentPageSourceEj.asObservable();
  public showWalkthroughStep = new BehaviorSubject<boolean>(false);
  public isInScix = new BehaviorSubject<string>("");
  public isDropdownOpen = new BehaviorSubject<boolean>(false);
  public isEJDropdownOpen = new BehaviorSubject<boolean>(false);
  projectWalkthroughStep: Subject<any> = new Subject();
  showDidYouMean: boolean | null = null;
  // hover help
  private hoverHelpEnabledSubject = new BehaviorSubject<boolean>(false);
  hoverHelpEnabled$ = this.hoverHelpEnabledSubject.asObservable();
  //// did you mean , setting a flag through service  (search instead for original text)
  private isSearchWithOriginalTextSource = new BehaviorSubject<boolean>(false);
  isSearchWithOriginalText$ =
    this.isSearchWithOriginalTextSource.asObservable();
  protected contactUsURL = "contact-us-api/";
  protected contentCurationURL = "content-curation-request-api/";
  focusAreaAggregation: Aggregation | undefined;
  sharedData: any = null;
  gridColumnApi: Subject<any> = new Subject<any>();
  openVideoFromExternalAction: Subject<any> = new Subject<any>();
  selectAllEntities: Subject<any> = new Subject();
  mobileModalOpen: boolean = false;
  mobileVideoTime: any = 0;
  secretProdEnvs: string[] = ["dev", "qa", "staging", "lrm-dev", "production"];
  intendedUseDropdownObj: any;
  windowPath: string;

  constructor(
    private modalService: BsModalService,
    private previewService: PreviewService,
    private urlCopierService: CopierService,
    private router: Router,
    private httpClient: HttpClient,
    private $gaService: GoogleAnalyticsService,
    private searchService: SearchService,

    @Inject(START_CONFIG) private startConfig: StartConfig
  ) {}

  setSearchWithOriginalTextFlag(value: boolean) {
    this.isSearchWithOriginalTextSource.next(value);
  }

  loadingBetweenComponents(flag: boolean) {
    this._loadingFlag = flag;
  }

  toggleHoverHelp(enabled: boolean): void {
    this.hoverHelpEnabledSubject.next(enabled);
  }

  setWalkthroughStepValue(value) {
    this.showWalkthroughStep.next(value);
  }

  setIsInScixValue(value: any) {
    this.isInScix.next(value);
  }

  setDropdownValue(value) {
    this.isDropdownOpen.next(value);
  }

  setEJDropdownValue(value) {
    this.isEJDropdownOpen.next(value);
  }

  // for ngx joyride walkthrough for SDE ,flags checks to start walkthrough on results page else home page
  updateCurrentPage(currentPage: string) {
    this.currentPageSource.next(currentPage);
  }

  // for ngx joyride walkthrough for SDE-EJ ,flags checks to start walkthrough on results page else home page
  updateCurrentPageEj(eJCurrentPage: string) {
    this.currentPageSourceEj.next(eJCurrentPage);
  }

  // Method to trigger clearing input field action
  clearInputField() {
    this.clearInputFieldSource.next(true);
  }
  openModal(template: TemplateRef<any>, config: ModalOptions) {
    this.modalRef = this.modalService.show(template, config);
  }

  closeModal() {
    this.modalRef?.hide();
  }

  isHome(): boolean {
    if (this.router.url.startsWith("/home")) return true;
    else return false;
  }

  /* Copy URL to clipboard */
  copyURL(record: Record, query: Query, path = "preview") {
    const appendPath = this.router.createUrlTree([path], {
      queryParams: {
        id: record.id,
        query: this.previewService.makeQuery(query).toJsonForQueryString(),
      },
    });

    if (window.location.origin.includes("localhost")) {
      this.urlCopierService.copyText(
        window.location.origin + "#" + this.router.serializeUrl(appendPath)
      );
    } else if (this.secretProdEnvs.includes(environment.environmentName)) {
      this.urlCopierService.copyText(
        window.location.origin +
          "/" +
          "app" +
          "/" +
          this.startConfig.app +
          "/#" +
          this.router.serializeUrl(appendPath)
      );
    }
  }

  // Download document with docformat = pdf
  downloadDocument(record: SdeRecord) {
    if (record && record.download_url) {
      if (record.docformat === "pdf") {
        // Method - 1 - Since it is PDF the browser will open it then the user will have to download
        window.open(record?.download_url, "_blank");
      } else {
        // Method - 2 - In case of other docformats this will download files automatically
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = record?.download_url;
        link.target = "_blank";
        link.click();
      }
    }
  }

  makeUrl(api: string): string {
    return Utils.addUrl(environment.feedbackURL, api);
  }

  submitContactUsForm(body) {
    if (!body) {
      return throwError({ error: "Issue with form fields" });
    }
    return this.httpClient.post<{}>(this.makeUrl(this.contactUsURL), body);
  }

  submitContentCurationForm(body) {
    if (!body) {
      return throwError({ error: "Issue with form fields" });
    }
    return this.httpClient.post<{}>(
      this.makeUrl(this.contentCurationURL),
      body
    );
  }

  setSearchText(text: string) {
    this.searchText = text;
  }

  getSearchText() {
    return this.searchText;
  }

  setSciXCount(count: number) {
    this.sciXCount = count;
  }

  getSciXCount() {
    return this.sciXCount;
  }

  getPublicationsSciX(start: number, rows: number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.sciXApiToken}`,
    });

    const searchText = this.searchText;

    const params = {
      q: searchText,
      fl: "title, bibcode, author, abstract, pub, pubdate, bibstem, date, volume, issue, page",
      start: start,
      rows: rows,
      sort: "score desc",
    };

    if (window.location.origin.includes("localhost")) {
      return this.httpClient.get(this.sciXLocalUrl, { headers, params });
    } else {
      return this.httpClient.get(this.sciXApiUrl, { headers, params });
    }
  }

  navigateToEjApplicationNewTab() {
    window.open(
      window.location.origin + "/app/" + environment.ejApp + "/#/ej/home",
      "_blank"
    );
  }

  navigateToEjApplicationExistingTab() {
    window.location.replace(
      window.location.origin + "/app/" + environment.ejApp + "/#/ej/home"
    );
  }
  // For Google Analytics document accessing
  createDocumentEventDetailsObject(
    docTitle: string,
    docCollection: any,
    docTreepath: any,
    docId: string,
    downloadDocument?: any,
    copyLink?: any
  ): object {
    return {
      document_title: docTitle,
      document_collection: docCollection,
      document_treepath: docTreepath,
      doc_id: docId,
      download_documunet: downloadDocument,
      copied_link: copyLink,
    };
  }

  // For search event
  createSearchEventDetailsObject(
    searchTerm: any,
    scientificFocus: string
  ): Object {
    return {
      search_term: searchTerm,
      search_scientific_focus: scientificFocus,
    };
  }

  // For Google Analytics meta data link
  createMetaDataLinkClickedObject(
    docTitle: string,
    docCollection: any,
    docTreepath: any,
    docId: string,
    UrlContentType: string,
    dataSource: string
  ): object {
    return {
      document_title: docTitle,
      document_collection: docCollection,
      document_treepath: docTreepath,
      doc_id: docId,
      UrlContent_type: UrlContentType,
      data_source: dataSource,
    };
  }
  createFilterClickObject(
    searchTerm: any,
    scientificFocus: string,
    selectedFilters: any
  ): object {
    return {
      search_term: searchTerm,
      search_scientific_focus: scientificFocus,
      selected_filters_value: selectedFilters,
    };
  }

  createScixDocumentClickedObject(
    bibcode: string,
    abstract: string,
    author: string[],
    bibstem: string[],
    publication: string,
    pubdate: string,
    title: string[]
  ): object {
    return {
      bibcode: bibcode,
      abstract: abstract,
      author: author,
      bibstem: bibstem,
      Publication: publication,
      pubdate: pubdate,
      title: title,
    };
  }
  // common method written here which is to be called from header and search-form
  trackSearchEventForGoogleAnalytics(currentSciFocus: string) {
    let searchQueryText = this.searchService.query.text;
    const eventDetails = {
      app_name: this.isInTdammApp() ? "TDAMM" : "SDE",
      url: this.router.url,
      debug_mode: environment.googleAnalyticsDebugMode,
      ...this.createSearchEventDetailsObject(
        searchQueryText ? searchQueryText : "EMPTY SEARCH",
        currentSciFocus
      ),
    };
    this.$gaService.event(
      googleAnalyticsConstants.action.search,
      googleAnalyticsConstants.category.search,
      searchQueryText ? searchQueryText : "EMPTY SEARCH",
      0,
      true,
      eventDetails
    );
  }

  isInTdammApp(): boolean {
    if (this.searchService.query.name === "tdamm_query_service") return true;
    else return false;
  }

  isSDEApp(): boolean {
    if (this.searchService.query.name === "query-smd-primary") return true;
    else return false;
  }

  isTdammHome(): boolean {
    if (this.router.url.startsWith("/tdamm")) return true;
    else return false;
  }
}
