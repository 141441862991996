<div>
  <!-- Fileshare Badges -->
  <span
    title="{{ 'msg#badges.fileshares' | sqMessage }}"
    *ngIf="record.collection[0].indexOf('Fileshares') > -1"
    class="badge filesharesBadge"
    (click)="doSearch('Treepath', 'Fileshares', $event, false)"
  >
    Fileshares
  </span>
  <span
    title="{{ 'msg#badges.orgCode' | sqMessage }} {{ record.orgCode }}"
    *ngIf="record.collection[0].indexOf('Fileshares') > -1 && record.orgCode"
    class="badge orgBadge"
    (click)="doSearch('Badge_OrgCode', record.orgCode, $event)"
  >
    {{ record.orgCode }}
  </span>
  <!-- Sharepoint Badges -->
  <span
    title="{{ 'msg#badges.sharepoint' | sqMessage }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1"
    class="badge sharepointBadge"
    (click)="doSearch('Treepath', 'SharePoint', $event, false)"
  >
    Sharepoint
  </span>
  <span
    title="{{ 'msg#badges.droid' | sqMessage }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 &&
      record.collection[0].includes('DROID')
    "
    class="badge collectionBadge"
    (click)="doSearch('Treepath', 'SharePoint', $event, false, 'DROID')"
  >
    DROID
  </span>
  <span
    title="{{ 'msg#badges.engineering' | sqMessage }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 &&
      record.collection[0].includes('Engineering')
    "
    class="badge collectionBadge"
    (click)="doSearch('Treepath', 'SharePoint', $event, false, 'Engineering')"
  >
    Engineering
  </span>
  <span
    title="{{ 'msg#badges.lasse' | sqMessage }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 &&
      record.collection[0].includes('LaSSe')
    "
    class="badge collectionBadge"
    (click)="doSearch('Treepath', 'SharePoint', $event, false, 'LaSSe')"
  >
    LaSSe
  </span>
  <span
    title="{{ 'msg#badges.pece' | sqMessage }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 &&
      record.collection[0].includes('PECE')
    "
    class="badge collectionBadge"
    (click)="doSearch('Treepath', 'SharePoint', $event, false, 'PECE')"
  >
    PECE
  </span>
  <span
    title="{{ 'msg#badges.problem_document' | sqMessage }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 &&
      record.collection[0].includes('Problem_Document')
    "
    class="badge collectionBadge"
    (click)="
      doSearch('Treepath', 'SharePoint', $event, false, 'Problem_Document')
    "
  >
    Problem Document
  </span>
  <span
    title="{{ 'msg#badges.records_management' | sqMessage }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 &&
      record.collection[0].includes('Records_Management')
    "
    class="badge collectionBadge"
    (click)="
      doSearch('Treepath', 'SharePoint', $event, false, 'Records_Management')
    "
  >
    Records Management
  </span>
  <span
    title="{{ 'msg#badges.repository_management' | sqMessage }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 &&
      record.collection[0].includes('Repository_Management')
    "
    class="badge collectionBadge"
    (click)="
      doSearch('Treepath', 'SharePoint', $event, false, 'Repository_Management')
    "
  >
    Repository Management
  </span>
  <span
    title="{{ 'msg#badges.dRDNum' | sqMessage }} {{ record.dRDNum }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.dRDNum"
    class="badge drdNumBadge"
    (click)="doSearch('Badge_DrdNumber', record.dRDNum, $event)"
  >
    {{ record.dRDNum }}
  </span>
  <span
    title="{{ 'msg#badges.cCBDNum' | sqMessage }} {{ record.cCBDNum }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.cCBDNum"
    class="badge ccbdNumBadge"
    (click)="doSearch('Badge_CcbdNumber', record.cCBDNum, $event)"
  >
    {{ record.cCBDNum }}
  </span>
  <span
    title="{{ 'msg#badges.dPDIDRDDRLNum' | sqMessage }} {{
      record.dPDIDRDDRLNum
    }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 && record.dPDIDRDDRLNum
    "
    class="badge dpdNumBadge"
    (click)="doSearch('Badge_DpdNumber', record.dPDIDRDDRLNum, $event)"
  >
    {{ record.dPDIDRDDRLNum }}
  </span>
  <span
    title="{{ 'msg#badges.contractNum' | sqMessage }} {{ record.contractNum }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 && record.contractNum
    "
    class="badge contractNumberBadge"
    (click)="doSearch('Badge_ContractNumber', record.contractNum, $event)"
  >
    {{ record.contractNum }}
  </span>
  <span
    title="{{ 'msg#badges.laSSeNum' | sqMessage }} {{ record.laSSeNum }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.laSSeNum"
    class="badge documentReportBadge"
    (click)="doSearch('Badge_DocReportNumber', record.laSSeNum, $event)"
  >
    {{ record.laSSeNum }}
  </span>
  <span
    title="{{ 'msg#badges.changeNum' | sqMessage }} {{ record.changeNum }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.changeNum"
    class="badge changeNumberBadge"
    (click)="doSearch('Badge_ChangeNumber', record.changeNum, $event)"
  >
    {{ record.changeNum }}
  </span>
  <!--                         <span
        title="{{'msg#badges.revision' | sqMessage}} {{record.revision}}"
        *ngIf="record.collection[0].indexOf('Sharepoint') >-1 && record.revision"
        class="badge revisionBadge"
        (click)="DoNotProgagateClickEvent($event)">
        {{record.revision}}
    </span> -->
  <span
    title="{{ 'msg#badges.status' | sqMessage }} {{ record.status }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.status"
    class="badge statusBadge"
    (click)="doSearch('Badge_Status', record.status, $event)"
  >
    {{ record.status }}
  </span>
  <span
    title="{{ 'msg#badges.storageMed' | sqMessage }} {{ record.storageMed }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.storageMed"
    class="badge storMedBadge"
    (click)="doSearch('Badge_StorageMedium', record.storageMed, $event)"
  >
    {{ record.storageMed }}
  </span>
  <span
    title="{{ 'msg#badges.multiDocSet' | sqMessage }} {{ record.multiDocSet }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 && record.multiDocSet
    "
    class="badge multiDocSetBadge"
    (click)="doSearch('Badge_MultiDocSet', record.multiDocSet, $event)"
  >
    {{ record.multiDocSet }}
  </span>
  <span
    title="{{ 'msg#badges.laSSeSource' | sqMessage }} {{ record.laSSeSource }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 && record.laSSeSource
    "
    class="badge lasseSourceBadge"
    (click)="doSearch('Badge_LasseSource', record.laSSeSource, $event)"
  >
    {{ record.laSSeSource }}
  </span>
  <span
    title="{{ 'msg#badges.laSSeCategory' | sqMessage }} {{
      record.laSSeCategory
    }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 && record.laSSeCategory
    "
    class="badge lasseCategoryBadge"
    (click)="doSearch('Badge_LasseCategory', record.laSSeCategory, $event)"
  >
    {{ record.laSSeCategory }}
  </span>
  <span
    title="{{ 'msg#badges.contNum' | sqMessage }} {{ record.contNum }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.contNum"
    class="badge contNumBadge"
    (click)="doSearch('Badge_ContainerNumber', record.contNum, $event)"
  >
    {{ record.contNum }}
  </span>
  <span
    title="{{ 'msg#badges.oPR' | sqMessage }} {{ record.oPR }}"
    *ngIf="record.collection[0].indexOf('Sharepoint') > -1 && record.oPR"
    class="badge contNameBadge"
    (click)="doSearch('Badge_ContainerName', record.oPR, $event)"
  >
    {{ record.oPR }}
  </span>
  <span
    title="{{ 'msg#badges.projectName' | sqMessage }} {{ record.projectName }}"
    *ngIf="
      record.collection[0].indexOf('Sharepoint') > -1 && record.projectName
    "
    class="badge projOldBadge"
    (click)="doSearch('Badge_ProjectName', record.projectName, $event)"
  >
    {{ record.projectName }}
  </span>
  <!-- Windchill Badges -->
  <span
    title="{{ 'msg#badges.windchill' | sqMessage }}"
    *ngIf="record.collection[0].indexOf('Windchill') > -1"
    class="badge windchillBadge"
    (click)="doSearch('Treepath', 'Windchill', $event, false)"
  >
    Windchill
  </span>
  <!--     <span
        title="{{'msg#badges.docNum' | sqMessage}} {{record.iteMNum}}"
        *ngIf="record.collection[0].indexOf('Windchill') >-1 && record.iteMNum"
        class="badge documentNumberBadge"
        (click)="doSearch('iteMNum',record.iteMNum, $event)">
        {{record.iteMNum}}
    </span> -->
  <span
    title="{{ 'msg#results.authorityLevel' | sqMessage }} {{
      record.authorityLevel
    }}"
    *ngIf="
      record.collection[0].indexOf('Windchill') > -1 && record.authorityLevel
    "
    class="badge contractAuthorityLevel"
    (click)="doSearch('authorityLevel', record.authorityLevel, $event)"
  >
    {{ record.authorityLevel }}
  </span>
  <span
    title="{{ 'msg#badges.contractNum' | sqMessage }} {{ record.contractNum }}"
    *ngIf="record.collection[0].indexOf('Windchill') > -1 && record.contractNum"
    class="badge contractNumberBadge"
    (click)="doSearch('Badge_ContractNumber', record.contractNum, $event)"
  >
    {{ record.contractNum }}
  </span>
  <span
    title="{{ 'msg#badges.cAGECode' | sqMessage }} {{ record.cAGECode }}"
    *ngIf="record.collection[0].indexOf('Windchill') > -1 && record.cAGECode"
    class="badge cageCodeBadge"
    (click)="doSearch('Badge_CageCode', record.cAGECode, $event)"
  >
    {{ record.cAGECode }}
  </span>
  <span
    title="{{ 'msg#badges.partNum' | sqMessage }} {{ record.partNum }}"
    *ngIf="record.collection[0].indexOf('Windchill') > -1 && record.partNum"
    class="badge partNumberBadge"
    (click)="doSearch('Badge_PartNumber', record.partNum, $event)"
  >
    {{ record.partNum }}
  </span>
  <!--     <span
        title="{{'msg#badges.version' | sqMessage}} {{record.version}}"
        *ngIf="record.collection[0].indexOf('Windchill') >-1 && record.version"
        class="badge versionBadge"
        (click)="doSearch('version',record.version, $event)">
        {{record.version}}
    </span> -->
  <span
    title="{{ 'msg#badges.docState' | sqMessage }} {{ record.docState }}"
    *ngIf="record.collection[0].indexOf('Windchill') > -1 && record.docState"
    class="badge stateBadge"
    (click)="doSearch('Badge_State', record.docState, $event)"
  >
    {{ record.docState }}
  </span>
  <!--     <span
        title="{{'msg#badges.role' | sqMessage}} {{record.role}}"
        *ngIf="record.collection[0].indexOf('Windchill') >-1 && record.role"
        class="badge roleBadge"
        (click)="doSearchHandler('role',record.role, $event)">
        {{record.role}}
    </span> -->
</div>
