<!-- prettier-ignore -->

<div
  *ngIf="item"
  class="mb-3 sq-did-you-mean sq-text"
  [ngSwitch]="results.didYouMean?.spellingCorrectionMode"
>
  <ng-container *ngSwitchCase="'DYMOnly'">
	<p class="did-you-mean-text">{{'msg#didYouMean.dymonlyBeforeCorrected' | sqMessage}}<a class="sq-did-you-mean-corrected" href="#" (click)="selectCorrected()">{{item.corrected}}</a>?</p>
	<div class="d-flex">
		<p class="result-text me-1">{{ "msg#didYouMean.correctBeforeCorrected" | sqMessage}}	<a class="sq-did-you-mean-corrected-2" href="#" (click)="selectCorrected()">{{ item.corrected }}</a></p>
		<p class="result-text"> {{"|"}} </p>
		<p class="result-text ms-1">{{ "msg#didYouMean.correctBeforeOriginal" | sqMessage}}	<u><a class="sq-did-you-mean-corrected-2" href="#" (click)="selectOriginal()">{{ item.original }}</a></u></p>
	</div>
		
  </ng-container>
  <ng-container *ngSwitchCase="'Smart'">
    {{ "msg#didYouMean.smartBeforeOriginal" | sqMessage
    }}<a class="sq-did-you-mean-original" href="#" (click)="selectOriginal()">{{
      item.original
    }}</a
    >{{ "msg#didYouMean.smartBeforeCorrected" | sqMessage
    }}<a
      class="sq-did-you-mean-original"
      href="#"
      (click)="selectCorrected()"
      >{{ item.corrected }}</a
    >{{ "msg#didYouMean.smartAfterCorrected" | sqMessage }}
  </ng-container>
</div>
