<div class="card-body">
    <form role="search" novalidate [formGroup]="form">

        <div class="d-flex flex-column flex-grow-1 position-relative">
            <div class="input-group">
                <label class="visually-hidden">{{'msg#facet.refine.input.label' | sqMessage}}</label>

                <input type="text"
                    formControlName="search"
                    class="form-control"
                    spellcheck="false"
                    autocomplete="off"

                    sqAutocomplete
                    [placeholder]="'msg#facet.refine.input.placeholder' | sqMessage"
                    [dropdown]="dropdown"
                    [suggestQuery]="suggestQuery"
                    [off]="!autocompleteEnabled"
                    [suggestDelay]="suggestDelay"

                    (submit)="doRefine()">

                <button class="btn btn-outline-secondary" type="button" (click)="doRefine()" title="{{'msg#facet.refine.input.buttonTitle' | sqMessage}}">
                    <i class="fas fa-search"></i>
                </button>
            </div>

            <sq-autocomplete-list #dropdown>
                <ng-template #itemTpl let-item>
                    <div class="py-2" style="padding-left:0.75rem;">{{item.display}}
                        <small *ngIf="item.category" class="ms-2 text-muted">{{(item.label || item.category) | sqMessage}}</small>
                    </div>
                </ng-template>
            </sq-autocomplete-list>
        </div>
    </form>
</div>
