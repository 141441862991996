export default {
    "modal": {
        "login": {
            "title": "Connexion",
            "userName": "Identifiant",
            "password": "Mot de passe",
            "singleSignOn": "Authentification unique",
            "signInWith": "S'identifier avec ..."
        }
    },
    "login": {
        "retry": "Déconnecté... Nouvel essai {prettyDelay}"
    }
};
