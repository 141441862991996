<form name="labelsForm" class="d-inline">
    <section class="sq-dropdown-form">
        <div class="form-control">
            <span
                *ngFor="let item of labelsItems"
                class="badge rounded-pill align-self-center"
                [ngClass]="{'label-public': public, 'label-private': !public}">
                {{ item.display }}
                <span class="fas fa-times-circle clickable" (click)="removeItem(item)"></span>
            </span>
            <input type="text"
                class="input-autocomplete flex-grow-1"
                name="labelName"
                spellcheck="false"
                autocomplete="off"
                sqAutocompleteLabels
                [public]="public"
                [placeholder]="'msg#labels.selectLabel' | sqMessage"
                [dropdown]="dropdown"
                [allowNewLabels]="allowNewLabels"
                [allowManagePublicLabels]="allowManagePublicLabels"
                (keydown)="keydown($event)"
                (keypress)="keypress($event)"
                (itemsUpdate)="onLabelsItemsChanged($event)"
                [disabled]="disableAutocomplete"
                [off]="disableAutocomplete"
                [class.disabled]="disableAutocomplete"
                [labelsItems]="labelsItems">
        </div>
        <sq-autocomplete-list #dropdown>
            <ng-template #itemTpl let-item>
                <div class="autocomplete-item p-2">{{item.display}}
                </div>
            </ng-template>
        </sq-autocomplete-list>
    </section>
</form>


