<div
  [hidden]="isHidden()"
  [ngClass]="{
    'messenger-bg-ul': tdammFilters === 'Messengers',
    'object-bg-ul': tdammFilters === 'Objects',
    'signal-bg-ul': tdammFilters === 'Signals'
  }"
>
  <div *ngIf="searchable" [formGroup]="searchGroup">
    <input
      class="item-search form-control rounded-0 border border-dark"
      #searchInput
      type="search"
      spellcheck="false"
      autocomplete="off"
      formControlName="searchQuery"
      [placeholder]="'msg#facet.searchPlaceholder' | sqMessage"
    />
  </div>

  <div class="position-relative">
    <sq-loading-bar [active]="searchActive | async"></sq-loading-bar>
  </div>

  <ul class="pb-3 list-group list-group-flush">
    <ng-container
      *ngFor="let item of items"
      [ngTemplateOutlet]="itemTpl"
      [ngTemplateOutletContext]="{ $implicit: item }"
    >
    </ng-container>

    <li
      *ngIf="!suggests && data?.$hasMore"
      class="my-3 text-center list-group-item list-group-item-action text-center"
      role="button"
      (click)="loadMore()"
    >
      <span class="load-more-btn">{{ "msg#facet.loadMore" | sqMessage }}</span>
    </li>

    <li class="list-group-item text-center py-3" *ngIf="suggests?.length === 0">
      <span>{{ "msg#facet.searchNoResult" | sqMessage }}</span>
    </li>
  </ul>
</div>

<!-- Template for displaying an aggregation item-->
<ng-template #itemTpl let-item>
  <li
    class="list-group-item list-group-item-action d-flex"
    [ngClass]="{
      'sq-selected': item.$selected,
      'sq-filtered': item.$filtered,
      'font-22': item.$level == 0,
      'font-20': item.$level == 1,
      'font-18': item.$level >= 2
    }"
    role="button"
  >
    <!-- Tree aggregation opener -->
    <span
      *ngIf="data?.isTree"
      class="item-opener"
      [ngStyle]="{ '--depth': item.$level ?? 0 }"
    >
      <a
        *ngIf="item.hasChildren"
        href="#"
        (click)="open(item, $event)"
        [title]="
          (item.$opened ? 'msg#facet.closeItem' : 'msg#facet.openItem')
            | sqMessage
        "
      >
        <span *ngIf="item.$opening" class="fas fa-sync fa-fw fa-spin"></span>
        <span
          *ngIf="!item.$opening && item.$opened"
          class="fas fa-chevron-down fa-fw"
        ></span>
        <span
          *ngIf="!item.$opening && !item.$opened"
          class="fas fa-chevron-right fa-fw"
        ></span>
      </a>
      <span *ngIf="!item.hasChildren" class="fas fa-fw"></span>
    </span>

    <!-- Checkbox for multi-selection -->
    <div>
      <input
        type="checkbox"
        class="form-check-input ms-2 me-2 facet-list-checkbox"
        tabindex="-1"
        [checked]="item.$selected || item.$filtered"
        [disabled]="item.$filtered"
        (change)="selectItemModified(item, $event)"
        (click)="$event.stopPropagation()"
        *ngIf="showCheckbox"
      />
    </div>

    <!-- item display / value -->
    <span class="me-auto" [title]="item | sqValue : item.$column">
      <span
        class="item-text"
        [ngClass]="{
          'messenger-text': tdammFilters === 'Messengers',
          'object-text': tdammFilters === 'Objects',
          'signal-text': tdammFilters === 'Signals'
        }"
        >{{ item | sqValue : item.$column }}</span
      >
      <span
        class="item-count"
        [ngClass]="{
          'messenger-text': tdammFilters === 'Messengers',
          'object-text': tdammFilters === 'Objects',
          'signal-text': tdammFilters === 'Signals'
        }"
        *ngIf="item.count"
      >
        ({{ item.count | sqNumber }} Results)
      </span>
      <span class="item-count" *ngIf="!item.count"> (0 Results) </span>
    </span>
  </li>

  <!-- Tree aggregation sub-elements -->
  <ng-container *ngIf="item.$opened">
    <ng-container
      *ngFor="let i of item.items"
      [ngTemplateOutlet]="itemTpl"
      [ngTemplateOutletContext]="{ $implicit: i }"
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #headerTpl>
  <div class="flex-grow-1 text-nowrap text-end">
    <div class="btn-group btn-group-sm" role="group" *ngIf="showCheckbox">
      <i
        *ngIf="selected.length > 0"
        (click)="addFilter(selected)"
        class="ms-2 me-2 fas fa-filter cursor-pointer"
      ></i>
      <i
        *ngIf="data?.$filtered.length"
        (click)="clearAllFilters()"
        class="ms-2 me-2 fas fa-filter-slash cursor-pointer"
      ></i>
    </div>
  </div>
</ng-template>
