import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "dialog-animations-example-dialog",
  templateUrl: "modal-preview.component.html",
})
export class ModalPreviewComponent {
  constructor(public dialogRef: MatDialogRef<ModalPreviewComponent>) {}
}
