import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Action } from "@sinequa/components/action";
import { FacetConfig, FacetService } from "@sinequa/components/facet";
import {
  PreviewHighlightColors,
  PreviewService,
} from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { SelectionService } from "@sinequa/components/selection";
import { UIService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { IntlService } from "@sinequa/core/intl";
import { LoginService } from "@sinequa/core/login";
import {
  NotificationsService,
  NotificationType,
} from "@sinequa/core/notification";
import {
  isFieldFilter,
  JsonMethodPluginService,
  PrincipalWebService,
  Record,
} from "@sinequa/core/web-services";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PopoverDirective } from "ngx-bootstrap/popover";

import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { filter, Subscription, tap } from "rxjs";
import { SdeSearchService } from "sde/src/app/services/sde-search.service";
import { Network } from "vis-network/peer";
import { FACETS, FEATURES, METADATA, PREVIEW_HIGHLIGHTS } from "../../config";
import { environment } from "../../environments/environment";
import googleAnalyticsConstants from "../analytics/google-analytics.constant";
import { SdeSponsoredLinksComponent } from "../components/sde-sponsored-links/sde-sponsored-links.component";
import { TooltipDirective } from "../home/notifications/tooltip/tooltip.directive";
import { ModalPreviewComponent } from "../library/modal-preview/modal-preview.component";
import { ShareDocumentPopupComponent } from "../library/share-document-popup/share-document-popup.component";
import { SdeRecord } from "../model/extended.interface";
import { CommonService } from "../services/common.service";
import { HeaderService } from "../services/header.service";
import { ImagePreloaderService } from "../services/image-preloader.service";

enum Views {
  List,
  Node,
  Heatmap,
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TooltipDirective], // Add the TooltipDirective to the providers array
})
export class SearchComponent implements OnInit, OnDestroy {
  // Dynamic display of facets titles/icons in the multi-facet component
  public multiFacetIcon? = "fas fa-filter fa-fw";
  public multiFacetTitle = "msg#facet.filters.title";
  modalRef?: BsModalRef;

  // Document "opened" via a click (opens the preview facet)
  public openedDoc?: SdeRecord;

  // Custom action for the preview facet (open the preview route)
  public previewCustomActions: Action[];

  // Whether the record belongs to a given source
  private _searchServiceSubscription: Subscription;
  private _heatmapFullscreenSubscription: Subscription;
  private _principalSubscription: Subscription;
  private _closeMetadataSubscription: Subscription;

  // A custom Action for the heatmap facet. See constructor for definition
  fullscreenFacetAction: Action;

  // Boolean indicating if search is in full-screen mode
  public _fullscreen: boolean;

  // Reference that can be used to set results container to fullscreen
  @ViewChild("resultsEl") resultsEl: ElementRef;
  @ViewChild("tabsEl") tabsEl: ElementRef;
  @ViewChild("cardsPage") cardsPage: ElementRef;

  @ViewChild("resultsFilter") resultsFilter: ElementRef;

  // Reference that can be used to set heatmap facet card to fullscreen
  @ViewChild("heatmapEl", { read: ElementRef }) heatmapEl: ElementRef;

  // Gets all thumbnail popovers
  @ViewChildren("thumbnailPopover")
  thumbnailPopovers: QueryList<PopoverDirective>;

  @ViewChild("viewList", { read: ElementRef }) viewListEl: ElementRef;
  @ViewChild(SdeSponsoredLinksComponent)
  sponsoredLinksComponent: SdeSponsoredLinksComponent;

  @ViewChild("filtersBlock") filtersBlock: ElementRef;
  @ViewChild("shortPreview") shortPreview: ElementRef;

  _clearFilterSubscription: Subscription;
  didYouMeanObj: any;
  counter: number = 0;
  showSciX: boolean = false;
  selectedSciXMetadata: any;
  showSciXMetadata: boolean = false;
  sciXData: any[] = [];
  itemsPerPage: number = 50;

  @Input() dateFormat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  page: number;
  returnedArray: any[];
  sciXCurrentPage: number = 3;
  startItem: number = 0;
  sciXCount: number = 0;
  totalData: number = 0;
  currentfilters: any[] = [];
  results: any;
  tabName: string;
  isDidYouMean: any;
  // The "Additional Search Terms" facet uses a Sinequa component which doesn't let us pass in a title attribute
  // We're using a roundabout way to set a "title" attribute here for accessibility
  @ViewChild("refineFacet", { read: ElementRef }) set content(
    content: ElementRef
  ) {
    if (content) {
      const refineFacet = content.nativeElement as HTMLElement;
      const refineField = refineFacet.querySelector(
        'input[placeholder="Refine by..."]'
      );

      if (refineField) {
        refineField.setAttribute(
          "title",
          "Type additional search terms to refine the current query"
        );
      }
    }
  }

  // Whether the menu is shown on small screens
  public _showMenu = false;

  // TODO: Use this to add curator menu to navbar
  private _shouldShowCuratorMenu: boolean = false;
  private _showShortPreview: boolean;
  private _loginSubscription: Subscription;

  public hasBadges: boolean = false; // Badges are no longer used in this application
  public hasFacets: boolean = true;
  public hasActionMenu: boolean = false;
  public hasVisualization: boolean = false;
  public hasPropertyDetails: boolean = false;
  public hasGraphicView: boolean = false;
  public hasMetadataInPreview: boolean = false;
  public hasRedirect: boolean = false;
  public hasHeatmap: boolean = false;
  disableInteractions: boolean = false;

  spellingCorrectionMode: string = "DYMOnly"; // Set your desired string value here

  userFullName: string | undefined = "Waiting for Authentication...";
  filterCount: number;
  entityHighlightFlag: boolean = true;
  extractsHighlightFlag: boolean = true;
  hideFilterTab: boolean = false;

  oldQueryText: string;
  isSciFocusDropdownActive: boolean = false;
  acronymSearchText: string = "";
  isRotated = false;
  acronymData: any;
  currentSciFocus: string = "";
  sdeHeaderStyle: any = "";
  publicationScixData: any;
  showBoundaryLinks = true;
  searchText: string;
  selectedScixIndex: number | null = null;

  sciFocusList: any[] = [
    {
      name: "All",
      icon: "assets/img/all-image.svg",
    },
    {
      name: "Astrophysics",
      icon: "assets/img/astrophysics-image.svg",
    },
    {
      name: "Biological & Physical Sciences",
      icon: "assets/img/biophysical-image.svg",
    },
    {
      name: "Earth Science",
      icon: "assets/img/earth-science.svg",
    },
    {
      name: "Heliophysics",
      icon: "assets/img/heliophysics-image.svg",
    },
    {
      name: "Planetary Science",
      icon: "assets/img/planetary-image.svg",
    },
  ];
  sciFocusListWhite: any[] = [
    {
      name: "All",
      icon: "assets/img/all-image-white.svg",
    },
    {
      name: "Astrophysics",
      icon: "assets/img/astrophysics-image-white.svg",
    },
    {
      name: "Biological & Physical Sciences",
      icon: "assets/img/biophysical-image-white.svg",
    },
    {
      name: "Earth Science",
      icon: "assets/img/earth-science-image-white.svg",
    },
    {
      name: "Heliophysics",
      icon: "assets/img/heliophysics-image-white.svg",
    },
    {
      name: "Planetary Science",
      icon: "assets/img/planetary-image-white.svg",
    },
  ];

  // for hover help feature in search
  hoverHelpEnabled = false;
  previewStyle: any;

  @ViewChild("visNetwork", { static: false }) visNetwork!: ElementRef;
  @ViewChild("dropdown") dropdown: BsDropdownDirective;

  //@ts-ignore
  private networkInstance: Network;
  hoverTitle: string = "Node View";

  viewType: number = Views.List;
  goBackToOriginalParent: boolean = false;

  constructor(
    public searchService: SearchService,
    public selectionService: SelectionService,
    private principalService: PrincipalWebService,
    public sdeSearchService: SdeSearchService,
    private previewService: PreviewService,
    private titleService: Title,
    // @ts-ignore  - Adding this because we want the service to load, but this component does not use it directly.
    private facetService: FacetService,
    private intlService: IntlService,
    private appService: AppService,
    protected commonService: CommonService,
    public loginService: LoginService,
    public ui: UIService,
    public headerSrv: HeaderService,
    private router: Router,
    public activeRoute: ActivatedRoute,
    public pluginService: JsonMethodPluginService,
    public dialog: MatDialog,
    private notificationsService: NotificationsService,
    private deviceService: DeviceDetectorService,
    private imgService: ImagePreloaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: BsModalService,
    private $gaService: GoogleAnalyticsService
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (this.goBackToOriginalParent) {
          const urlParams = new URLSearchParams(e.url);
          if (urlParams && urlParams.has("parentUrl")) {
            var decoded = decodeURIComponent(urlParams.get("parentUrl") ?? ""); // 'a space'
            window.open(decoded, "_blank");
          }
          this.goBackToOriginalParent = false;
        }
      }
    });

    // Initialize the facet preview action (opens the preview route)
    this.previewCustomActions = [
      new Action({
        icon: "far fa-file-search",
        title: "msg#facet.preview.expandTitle",
        action: () => {
          if (this.openedDoc) {
            this.commonService.loadingBetweenComponents(false);
            this.previewService.openRoute(
              this.openedDoc,
              this.searchService.query
            );
          }
        },
      }),
    ];

    // Define our custom action's icon, title (which displays on hover), and action (the callback function to be run on click).
    // In this case, the function will toggle the heatmap facet card's fullscreen state
    this.fullscreenFacetAction = new Action({
      icon: "fas fa-expand",
      title: "Fullscreen",
      action: () => {
        this.toggleFullscreen("heatmap");
      },
    });

    // Subscribe to the search service 'events' observable to detect when a heatmap fullscreen state
    // should return to a non-fullscreen state. This currently should only trigger when a user selects
    // an axis label or matrix cell in the heatmap component while in full screen mode
    this._heatmapFullscreenSubscription = this.searchService.events.subscribe(
      (event) => {
        if (["make-query", "update-query"].includes(event.type)) {
          this.tryExitFullscreenHeatmap();
        }
      }
    );
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  /**
   * Initialize the page title
   */
  ngOnInit() {
    this.commonService.loadingBetweenComponents(true);

    this._loginSubscription = this.loginService.events.subscribe((event) => {
      this.getScopeDetailsFromURL();
    });

    this.activeRoute.queryParams.subscribe((params) => {
      if (params.query) {
        const queryObject = JSON.parse(params.query);

        this.searchText = queryObject.text;
        this.commonService.setSearchText(this.searchText);
        if (this.searchText) {
          if (this.commonService.isSDEApp()) {
            this.getPublicationsCount();
          }
          this.setTabData(queryObject.tab);
        }
      }
    });

    if (this.commonService.selectedScientificFocus !== "") {
      this.currentSciFocus = this.commonService.selectedScientificFocus;
      this.setBackgroundAsPerFocusSelection(
        this.commonService.selectedScientificFocus
      );
      this.appService.scope = this.currentSciFocus;
      this.searchService.query.scope = this.currentSciFocus;
    } else {
      this.currentSciFocus = "All";
      this.setBackgroundAsPerFocusSelection("All");
      this.searchService.query.scope = this.currentSciFocus;
    }
    this.commonService.hoverHelpEnabled$.subscribe((enabled) => {
      this.hoverHelpEnabled = enabled;
      if (enabled) {
        this.headerSrv.toggleFilters();
        this.closeDocument(); // Disable clickable items on the page
        document.querySelectorAll(".clickable-item").forEach((item) => {
          item.classList.add("disabled");
        });
      } else {
        document.querySelectorAll(".clickable-item").forEach((item) => {
          item.classList.remove("disabled");
        });
        // Enable clickable items on the page
      }
    });

    this.titleService.setTitle(this.checkSearchQueryTextAndUpdatePageTitle());
    this._principalSubscription = this.principalService.events.subscribe(
      (event) => {
        if (this.principalService.principal?.fullName != undefined)
          this.userFullName =
            environment.title + "-" + this.principalService.principal?.fullName;
        else this.userFullName = "Waiting for Authentication...";
      }
    );
    if (this.principalService.principal?.fullName != undefined)
      this.userFullName = this.principalService.principal?.fullName;
    else this.userFullName = "Waiting for Authentication...";

    // Add listener to detect when user enters/exits fullscreen mode
    document.addEventListener("fullscreenchange", (event) => {
      let elem = this.resultsEl.nativeElement as HTMLElement;
      if (document.fullscreenElement) {
        // Set private state variable
        this._fullscreen = true;

        /* After setting element to full screen, some styles (that were previously
        set by parent) need to be explicitly set */
        elem.style["background-color"] = "white";
        elem.style["overflow"] = "scroll";
      } else {
        // Set private state variable
        this._fullscreen = false;

        // On full-screen exit, revert properties back
        delete elem.style["background-color"];
        elem.style["overflow"] = "visible";
      }
    });

    this._searchServiceSubscription = this.searchService.resultsStream
      .pipe(
        filter((response) => !!response),
        tap((response) => {
          if (
            this.commonService.showDidYouMean !== false &&
            response?.didYouMean &&
            response?.didYouMean?.text?.corrected
          ) {
            this.didYouMeanObj = response;
            this.commonService.showDidYouMean = true;
            this.searchService.query.text = response.didYouMean.text.corrected;
            this.searchService.search();
          }
        })
      )
      .subscribe((results) => {
        this.results = results;
        let aggregations = results?.aggregations;
        aggregations?.forEach((aggregation) => {
          this.currentfilters.push(aggregation.name);
        });
        this.titleService.setTitle(
          this.checkSearchQueryTextAndUpdatePageTitle()
        );
        this.openedDoc = undefined;
        this.commonService.loadingBetweenComponents(false);

        if (results?.didYouMean) {
          if (this.commonService.showDidYouMean == true) {
            this.isDidYouMean = true;
          }
          if (this.commonService.showDidYouMean == null) {
            this.commonService.showDidYouMean = true;
            this.isDidYouMean = true;
          }
          if (this.commonService.showDidYouMean == false) {
            this.isDidYouMean = false;
            this.didYouMeanObj = undefined;
          }
        } else {
          if (this.commonService.showDidYouMean == true) {
            this.isDidYouMean = true;
          } else {
            this.isDidYouMean = false;
            this.didYouMeanObj = undefined;
          }
        }

        if (this.oldQueryText !== results?.queryAnalysis?.text) {
          this.closeDocument();
          this.closeScixMetadataViewer();
        }
        if (this.searchService.query.tab !== "Publications") {
          this.commonService.resultsCardLoader = false;
          this.headerSrv.showFilters = true;
          this.setFiltersContainerStyle();
        }
        if (!this.showSciX) {
          this.headerSrv.showFilters = true;
          this.setFiltersContainerStyle();
        }

        this.titleService.setTitle(
          this.checkSearchQueryTextAndUpdatePageTitle()
        );
        this.openedDoc = undefined;
        this.oldQueryText = results?.queryAnalysis?.text
          ? results?.queryAnalysis?.text
          : "";
        this.setResultsContainerStyle();
        this.updateFilterCount();
      });

    // Get filter Count

    this._closeMetadataSubscription =
      this.commonService.closeMetadataViewer.subscribe((flag) => {
        this.closeDocument();
      });
    /// subscribing the event when we search a new thing and filter is already applied .
    this._clearFilterSubscription =
      this.commonService.clearFilterEvent.subscribe((flag) => {
        this.clearFilters();
      });
  }

  updateFilterCount() {
    this.filterCount = 0;
    this.searchService.query.forEachFilter((f) => {
      if (isFieldFilter(f)) {
        this.filterCount++;
      }
    });
  }

  setResultsContainerStyle() {
    this.changeDetectorRef.detectChanges();
    let top = this.resultsEl.nativeElement.getBoundingClientRect().top;
    this.resultsEl.nativeElement.style.height = window.innerHeight - top + "px";
  }

  setFiltersContainerStyle() {
    this.changeDetectorRef.detectChanges();
    if (this.resultsEl && this.filtersBlock) {
      let top = this.resultsEl.nativeElement.getBoundingClientRect().top;
      this.filtersBlock.nativeElement.style.height =
        window.innerHeight - top + "px";
    }
  }

  /**
   * Unsubscribe from the search service
   */
  ngOnDestroy() {
    if (this._loginSubscription) {
      this._loginSubscription.unsubscribe();
    }
    this._searchServiceSubscription
      ? this._searchServiceSubscription.unsubscribe()
      : null;
    this._heatmapFullscreenSubscription
      ? this._heatmapFullscreenSubscription.unsubscribe()
      : null;
    this._principalSubscription
      ? this._principalSubscription.unsubscribe()
      : null;
    this._closeMetadataSubscription
      ? this._closeMetadataSubscription.unsubscribe()
      : null;
    this._clearFilterSubscription
      ? this._clearFilterSubscription.unsubscribe()
      : null;
  }

  /**
   * Returns the configuration of the facets displayed in the facet-multi component.
   * The configuration from the config.ts file can be overriden by configuration from
   * the app configuration on the server
   */
  public get facets(): FacetConfig<{}>[] {
    if (
      this.appService.app &&
      this.appService.app.data &&
      this.appService.app.data.facets
    ) {
      return <FacetConfig<{}>[]>(<any>this.appService.app.data.facets);
    }
    return FACETS;
  }

  /**
   * Returns the configuration of the metadata displayed in the facet-preview component.
   * The configuration from the config.ts file can be overriden by configuration from
   * the app configuration on the server
   */
  public get metadata(): string[] {
    if (
      this.appService.app &&
      this.appService.app.data &&
      this.appService.app.data.metadata
    ) {
      return <string[]>(<any>this.appService.app.data.metadata);
    }
    return METADATA;
  }

  /**
   * Returns the private fullscreen state variable, indicating if the page is in fullscreen mode on
   * one of it's elements
   */
  public get isFullScreen(): boolean {
    return this._fullscreen;
  }

  public get showCurator(): boolean {
    this._shouldShowCuratorMenu = this.headerSrv.showCuratorsMenu;
    return this._shouldShowCuratorMenu;
  }

  public get previewHighlights(): PreviewHighlightColors[] {
    return (
      (this.appService.app?.data?.previewHighlights as any) ||
      PREVIEW_HIGHLIGHTS
    );
  }

  /**
   * Responds to a change of facet in the multi facet
   * @param facet
   */

  /**
   * Responds to a click on a document (setting openedDoc will open the preview facet)
   * @param record
   * @param event
   */
  onDocumentClicked(record: SdeRecord, event: Event) {
    if (record.flags.length >= 1) {
      this.headerSrv.showFilters = false;
      let previewLoad = this.appService.app?.data?.previewLoad;
      let browser = this.detectBrowserName();
      let extensionsArray: Array<string> =
        previewLoad?.["extensions"].split(";");
      let lowExtensionsArray: Array<string> =
        previewLoad?.["lowExtensions"].split(";");
      if (
        (extensionsArray.indexOf(record.fileext) >= 0 &&
          record.size > previewLoad?.[browser].limit) ||
        (lowExtensionsArray.indexOf(record.fileext) >= 0 &&
          record.size > previewLoad?.[browser].lowLimit)
      ) {
        this.openedDoc = undefined;
        this.dialog.open(ModalPreviewComponent, {
          width: "35vw",
        });
      } else {
        this.openedDoc = record;
        this.setPreviewHeight();
      }

      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.card,
        googleAnalyticsConstants.label.clickResultsDocument,
        0,
        true,
        {
          app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
          debug_mode: environment.googleAnalyticsDebugMode,
          page: this.commonService.isInTdammApp()
            ? googleAnalyticsConstants.currentPage.tdammResultsPage
            : googleAnalyticsConstants.currentPage.sdeResultsPage,
          url: this.router.url,
          ...this.commonService.createDocumentEventDetailsObject(
            record.title,
            record.collection,
            record.treepath,
            record.id
          ),
        }
      );
    }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edg") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  /**
   * Open the preview when this record has no url1
   * @param record
   * @param isLink
   */
  openPreviewIfNoUrl(record: Record, isLink: boolean) {
    if (!isLink) {
      this.previewService.openRoute(record, this.searchService.query);
    }
  }

  handleTabChange(tabChangeEvent: any) {
    this.closeDocument();
    this.setTabData(tabChangeEvent.name);
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template);
    this.commonService.modalRef = this.modalRef;
  }

  openAstroTagsModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template);
  }

  groupItems(sourcetree: string[]): { [key: string]: any[] } {
    return sourcetree.reduce((groups, item) => {
      const parts = item.split("/");
      if (parts.length === 3) {
        const parent = parts[1];
        if (!groups[parent]) {
          groups[parent] = [];
        }
        groups[parent].push(parts[2]);
      } else if (parts.length === 2) {
        const parent = parts[1];
        if (!groups[parent]) {
          groups[parent] = [];
        }
      }

      return groups;
    }, {} as { [key: string]: any[] });
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getAstroTags(parent: string, tag?: string | null): string {
    if (tag) {
      return `<span class="font-family-inter-semi-bold">${parent}</span> <img src="assets/img/astro-chip-icon.svg" /> <span class="font-family-inter-regular">${tag}</span>`;
    } else {
      return parent;
    }
  }

  getAstroTitleTags(tag: string): string {
    const parts = tag.split("/");

    if (parts.length === 3) {
      return `<span class="font-family-inter-semi-bold">${parts[1]}</span> <img src="assets/img/astro-chip-icon.svg" /> <span class="font-family-public-sans-regular">${parts[2]}</span>`;
    } else if (parts.length === 2) {
      return parts[1];
    }

    return tag;
  }

  setTabData(tabName: string) {
    this.commonService.resultsCardLoader = true;
    this.tabName = tabName;
    this.commonService.setIsInScixValue(tabName);

    if (tabName === "Publications") {
      this.headerSrv.showFilters = false;
      this.showSciX = true;
      if (this.commonService.isSDEApp()) {
        this.getSciXResponse();
      }
      
    } else {
      this.showSciX = false;
      this.updateUIElementsAfterSciX();
    }
  }

  /**
   * Responds to the preview facet being closed by a user action
   */
  closeDocument() {
    if (this.openedDoc) {
      this.openedDoc = undefined;
      this.headerSrv.showFilters = true;
      this.setFiltersContainerStyle();
    }
  }

  /**
   * Responds to the preview facet being expanded by a user action
   */
  expandDocument() {
    this.commonService.loadingBetweenComponents(false);
    this.previewService.openRoute(
      this.openedDoc as Record,
      this.searchService.query
    );
    // Added to keep filters open when coming back from full page preview
    this.headerSrv.showFilters = true;
    this.setFiltersContainerStyle();
  }

  /**
   * Open Full Page Document/Metadata Preview from result card
   */
  expandDocumentPreview(record: SdeRecord, event: any) {
    this.commonService.loadingBetweenComponents(false);
    this.openedDoc = record;
    this.previewService.openRoute(
      this.openedDoc as Record,
      this.searchService.query
    );
    // Added to keep filters open when coming back from full page preview
    this.headerSrv.showFilters = true;
    this.setFiltersContainerStyle();
    event.stopPropagation();

    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.openDocumentNavigator,
      0,
      true,
      {
        app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
        debug_mode: environment.googleAnalyticsDebugMode,
        page: this.commonService.isInTdammApp()
          ? googleAnalyticsConstants.currentPage.tdammResultsPage
          : googleAnalyticsConstants.currentPage.sdeResultsPage,
        url: this.router.url,
        ...this.commonService.createDocumentEventDetailsObject(
          record.title,
          record.collection,
          record.treepath,
          record.id
        ),
      }
    );
  }

  /**
   * Copy URL of Full Page Document/Metadata Preview from result card
   */
  copyURLDocumentPreview(record: Record, event: any, fullPagePreview = false) {
    if (!this.showShortPreview) {
      this.headerSrv.showFilters = true;
      this.setFiltersContainerStyle(); // Added to keep filters open in case closed
    }
    this.commonService.copyURL(record as Record, this.searchService.query);
    this.notificationsService.notify(
      NotificationType.Success,
      "msg#actionMenu.urlCopiedToClipboard",
      {},
      undefined,
      true
    );

    if (fullPagePreview) {
      this.headerSrv.showFilters = false;
    }
    event.stopPropagation();
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.copyDocumenLink,
      0,
      true,
      {
        app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
        debug_mode: environment.googleAnalyticsDebugMode,
        page: this.commonService.isInTdammApp()
          ? googleAnalyticsConstants.currentPage.tdammResultsPage
          : googleAnalyticsConstants.currentPage.sdeResultsPage,

        url: this.router.url,
        ...this.commonService.createDocumentEventDetailsObject(
          record.title,
          record.collection,
          record.treepath,
          record.id
        ),
      }
    );
  }

  /** Share Document Preview Link via Email
   */
  shareDocument(record: Record) {
    this.dialog.open(ShareDocumentPopupComponent, {
      width: "45vw",
      closeOnNavigation: true,
      hasBackdrop: true,
      disableClose: true,
      data: {
        ...record,
      },
    });
  }

  /**
   * TODO: Discuss where this function belongs (a utilties.ts file?)
   * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
   * images to fit into a certain area.
   *
   * https://stackoverflow.com/a/14731922
   *
   * @param srcWidth width of source image
   * @param srcHeight height of source image
   * @param maxWidth maximum available width
   * @param maxHeight maximum available height
   * @returns An object with a width property and a height property.
   */
  calculateAspectRatioFit(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
  ): { width: number; height: number } {
    let ratio = Math.min(1, maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  /**
   * Launches the thumbnail popover
   * @param thumbnailPopover The thumbnail popover.
   * @param thumbnailPopoverDiv The enclosing record div.
   */
  onMouseEnterResultThumbnail(
    thumbnailPopover: PopoverDirective,
    thumbnailPopoverDiv: HTMLDivElement
  ) {
    const resultThumbnail = thumbnailPopoverDiv?.parentElement?.querySelector(
      "sq-result-thumbnail img"
    ) as HTMLImageElement;

    if (resultThumbnail) {
      // A hack to convert "50vh" to pixels.
      const maxWidth = document.documentElement.clientHeight * 0.5;
      const maxHeight = maxWidth;

      // Calculate a proportionally shrunk size for the thumbnail popover's img tag.
      // Attempts to prevent janky reflow caused by the image loading in and ensures proper dynamic placement of the popover.
      const shrunkSize = this.calculateAspectRatioFit(
        resultThumbnail.naturalWidth,
        resultThumbnail.naturalHeight,
        maxWidth,
        maxHeight
      );

      // Compose "style" attribute for img tag.
      const style = `
        width: ${shrunkSize.width}px;
        height: ${shrunkSize.height}px;
      `;

      // Pass it to the popover template.
      thumbnailPopover.popoverContext = { style };

      // Handle unclosed popovers in case there was a glitch.
      this.thumbnailPopovers.forEach((popover) => {
        popover.hide();
      });

      thumbnailPopover.show();
    }
  }

  getScopeDetailsFromURL() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params?.query) {
        let item = this.sciFocusList.find(
          (element) => element.name === JSON.parse(params.query).scope
        );
        if (item) {
          this.currentSciFocus = item.name;
        } else {
          this.currentSciFocus = "All";
        }

        this.commonService.selectedScientificFocus = this.currentSciFocus;
        this.appService.scope = this.currentSciFocus;
      }
    });
  }

  getWalkthroughLabel(): string {
    if (
      (this.showSciX && this.ui.screenSizeIsGreater("sm")) ||
      (this.searchService.query.name === "tdamm_query_service" &&
        this.ui.screenSizeIsGreater("sm")) ||
      (this.ui.screenSizeIsLessOrEqual("xs") &&
        this.searchService.query.name !== "tdamm_query_service")
    ) {
      return "5 - DATA TYPE TABS";
    } else if (
      (this.showSciX && this.ui.screenSizeIsLessOrEqual("xs")) ||
      (this.ui.screenSizeIsLessOrEqual("xs") &&
        this.searchService.query.name === "tdamm_query_service")
    ) {
      return "4 - DATA TYPE TABS";
    } else {
      return "6 - DATA TYPE TABS";
    }
  }

  getWalkthroughLabelForFilter(): string {
    if (this.searchService.query.name === "tdamm_query_service") {
      return "4 - FILTERS";
    } else {
      return "5 - FILTERS";
    }
  }

  getWalkthroughLabelForFilterMobile(): string {
    if (
      this.searchService.query.name === "tdamm_query_service" &&
      this.ui.screenSizeIsLessOrEqual("xs")
    ) {
      return "3 - FILTERS";
    } else {
      return "4 - FILTERS";
    }
  }

  getWalkthroughLabelForResultCard(): string {
    if (
      (this.showSciX && this.ui.screenSizeIsGreater("sm")) ||
      (this.searchService.query.name === "tdamm_query_service" &&
        this.ui.screenSizeIsGreater("sm")) ||
      (this.ui.screenSizeIsLessOrEqual("xs") &&
        this.searchService.query.name !== "tdamm_query_service")
    ) {
      return "6 - RESULT CARD";
    } else if (
      (this.showSciX && this.ui.screenSizeIsLessOrEqual("xs")) ||
      (this.searchService.query.name === "tdamm_query_service" &&
        this.ui.screenSizeIsLessOrEqual("xs"))
    ) {
      return "5 - RESULT CARD";
    } else {
      return "7 - RESULT CARD";
    }
  }

  /**
   * Animates the thumbnail popover's entry
   *
   * https://felixblaschke.medium.com/dynamisch-erstellte-html-elemente-animieren-6d165a37f685
   *
   * @param thumbnailPopoverDiv The enclosing record div.
   */
  onThumbnailPopoverShown(thumbnailPopoverDiv: HTMLDivElement) {
    const popoverContainer = thumbnailPopoverDiv?.parentElement?.querySelector(
      ".result-thumbnail-popover-container.faded-out"
    );

    if (popoverContainer) {
      requestAnimationFrame(() => {
        popoverContainer.classList.remove("faded-out");
      });
    }
  }

  // focus select dropdown functions
  dropdownToggled(isDropdownActive: any): void {
    this.isSciFocusDropdownActive = isDropdownActive;
    this.commonService.setDropdownValue(this.isSciFocusDropdownActive);
  }

  handleOutsideClick() {
    if (this.isSciFocusDropdownActive) {
      this.dropdown.hide();
    }
    if (this.acronymSearchText !== "" && this.acronymData?.length > 0) {
      this.acronymSearchText = "";
      this.acronymData = [];
    }
  }
  getIconForScientificFocus(currentSciFocus: string) {
    let item;
    if (this.isSciFocusDropdownActive) {
      item = this.sciFocusListWhite.find(
        (element) => element.name === currentSciFocus
      );
    } else {
      item = this.sciFocusList.find(
        (element) => element.name === currentSciFocus
      );
    }

    return item["icon"];
  }

  getSciFocusList(): any[] {
    return this.isSciFocusDropdownActive
      ? this.sciFocusListWhite
      : this.sciFocusList;
  }

  selectScientificFocus(sciFocusName: string, dropdown: BsDropdownDirective) {
    this.setBackgroundAsPerFocusSelection(sciFocusName);
    this.currentSciFocus = sciFocusName;
    this.searchService.selectScope(this.currentSciFocus);
    dropdown.hide();
  }
  isHome(): boolean {
    if (
      this.router.url.startsWith("/home") ||
      this.router.url.startsWith("/ej-home")
    )
      return true;
    else return false;
  }
  setBackgroundAsPerFocusSelection(focus: string = "All") {
    let img: HTMLImageElement | undefined;
    if (!this.isHome() && focus === "All") {
      img = this.imgService.getImage("assets/img/navbar-all.png");
    } else if (!this.isHome() && focus.includes("Earth Science")) {
      img = this.imgService.getImage("assets/img/navbar-earth-science.png");
    } else if (!this.isHome() && focus.includes("Planetary Science")) {
      img = this.imgService.getImage("assets/img/navbar-planetary.png");
    } else if (!this.isHome() && focus.includes("Astrophysics")) {
      img = this.imgService.getImage("assets/img/navbar-astrophysics.png");
    } else if (
      !this.isHome() &&
      focus.includes("Biological & Physical Sciences")
    ) {
      img = this.imgService.getImage("assets/img/navbar-bio-physical.png");
    } else if (!this.isHome() && focus.includes("Heliophysics")) {
      img = this.imgService.getImage("assets/img/navbar-heliophysics.png");
    }

    if (img && img?.src) {
      this.sdeHeaderStyle = {
        "background-image": `url(${img?.src})`,
        "background-size": "cover",
        height: "137px",
      };
    } else {
      this.sdeHeaderStyle = {
        "background-image": "inherit",
        "background-size": "cover",
      };
    }
  }

  /**
   * Dismisses the thumbnail popover and animates its exit
   * @param thumbnailPopover The thumbnail popover.
   * @param thumbnailPopoverDiv The enclosing record div.
   */
  onMouseLeaveResultThumbnail(
    thumbnailPopover: PopoverDirective,
    thumbnailPopoverDiv: HTMLDivElement
  ) {
    const popoverContainer = thumbnailPopoverDiv?.parentElement?.querySelector(
      ".result-thumbnail-popover-container:not(.faded-out)"
    ) as HTMLElement;

    if (popoverContainer) {
      popoverContainer.classList.add("faded-out");
      popoverContainer.ontransitionend = () => {
        thumbnailPopover.hide();
      };
    } else {
      // Gracefully fail by hiding the thumbnail popover without any animation.
      thumbnailPopover.hide();
    }
  }

  /**
   * Determine the type icon
   */
  fileType(record): string {
    let recordType: string = record.docformat;
    switch (recordType) {
      case "pdf":
        return "far fa-file-pdf iconPdf fa-2x";
        break;
      case "doc":
      case "docx":
        return "far fa-file-word iconDocx fa-2x";
        break;
      case "xlsx":
      case "xlsb":
      case "xlsm":
      case "xls":
        return "far  fa-file-excel iconExcel fa-2x";
        break;
      case "pptx":
      case "ppt":
        return "far fa-file-powerpoint iconPwt fa-2x";
        break;
      case "log":
      case "out":
      case "txt":
        return "far fa-file-edit fa-2x";
        break;
      case "html":
      case "htm":
        return "fas fa-globe-americas fa-2x";
        break;
      case "dwg":
        return "fas fa-draw-polygon fa-2x";
        break;
      case "csv":
        return "fas fa-file-csv fa-2x";
        break;
      case "gz":
      case "zip":
        return "fas fa-file-archive fa-2x";
        break;
      case "svg":
      case "jpg":
      case "png":
      case "gif":
      case "bmp":
      case "gimage":
      case "jpeg":
      case "igs":
      case "images":
        return "fas fa-file-image fa-2x";
        break;
      case "bin":
      case "dat":
      case "obj":
      case "dll":
      case "exe":
        return "fas fa-file-minus fa-2x";
        break;
      case "xml":
      case "js":
      case "cpp":
      case "c":
      case "java":
      case "ts":
      case "asm":
      case "xml":
        return "fas fa-file-code fa-2x";
        break;
      case "msg":
        return "fas fa-envelope fa-2x";
        break;
      default:
        return "far fa-file fa-2x";
    }
  }

  /**
   * Open Windchill 360 view
   */
  openWC360(id: string): void {
    //this.router.navigate(['/WC360',id]);
    window.open(
      "/i.aspx?page=plugin&plugin=WindchillView&WindchillDoc=" + id,
      "_blank"
    );
  }

  /**
   * Controls visibility of filters (small screen sizes)
   */
  get showFilters(): boolean {
    const flag =
      (!this.headerSrv.hasFilterEnabled &&
        this.ui.screenSizeIsGreaterOrEqual("md")) ||
      this.headerSrv.showFilters;
    return flag;
  }

  /**
   * On small screens only show the search form when the facets are displayed
   */
  get showShortPreview(): boolean {
    if (this.openedDoc === undefined) {
      this._showShortPreview = false;
    } else {
      this._showShortPreview = true;
    }
    return this._showShortPreview;
  }

  set showShortPreview(value: boolean) {
    this._showShortPreview = value;
  }

  /**
   * On small screens only show the search form when the facets are displayed
   */
  get showForm(): boolean {
    return (
      this.ui.screenSizeIsGreaterOrEqual("sm") || this.headerSrv.showFilters
    );
  }

  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }

  /**
   * Toggle fullscreen results mode based on the selected_elem input string. Currently
   * the only two relevant input option are 'results' and 'heatmap'.
   */
  toggleFullscreen(selected_elem: string) {
    let elem: HTMLElement;

    if (selected_elem === "results")
      elem = this.resultsEl.nativeElement as HTMLElement;
    else if (selected_elem === "heatmap")
      elem = this.heatmapEl.nativeElement as HTMLElement;
    else return;

    // If fullscreen state variable is true, exit fullscreen mode. Otherwise, enter fullscreen mode
    this._fullscreen === true
      ? this.exitFullscreen(elem)
      : this.enterFullscreen(elem);
  }

  /**
   * Dedicated function for exiting a fullscreen mode heatmap element
   */
  tryExitFullscreenHeatmap() {
    if (this.heatmapEl !== undefined) {
      let elem = this.heatmapEl.nativeElement as HTMLElement;
      if (this._fullscreen) {
        this.exitFullscreen(elem);
      }
    }
  }

  /**
   * Trigger fullscreen mode on input template element
   */
  enterFullscreen(elem: HTMLElement) {
    let methodToInvoke =
      elem.requestFullscreen ||
      elem["mozRequestFullScreen"] /* Firefox */ ||
      elem["webkitRequestFullscreen"] /* Chrome, Safari and Opera */ ||
      elem["msRequestFullscreen"]; /* IE/Edge */
    if (methodToInvoke) methodToInvoke.call(elem);
  }

  /**
   * Exit fullscreen mode on input template element
   */
  exitFullscreen(elem: HTMLElement) {
    let doc = document as Document;

    // Check that current fullscreen element matches the target fullscreen element
    var check_elem = document.fullscreenElement;
    if (elem === check_elem) {
      let methodToInvoke =
        doc.exitFullscreen ||
        doc["mozCancelFullScreen"] /* Firefox */ ||
        doc["webkitExitFullscreen"] /* Chrome, Safari and Opera */ ||
        doc["msExitFullscreen"]; /* IE/Edge */
      if (methodToInvoke) methodToInvoke.call(doc);
    }
  }

  /**
   * Set scrollable div vieww to top. This will only have an effect when
   * in fullscreen results mode.
   */
  onPaginate() {
    // this.resultsEl.nativeElement.scrollTop = 0;
    this.closeDocument();
  }

  getHeight(): string {
    return window?.innerHeight + "px";
  }

  getMidHeight(): string {
    return window?.innerHeight / 2 + "px";
  }

  getHeightNoMeasure(): string {
    return (window?.innerHeight - 160).toString(); //TODO - Take the 200 hardcode and put a dynamic size
  }

  DoNotPropagateClickEvent(event): void {
    // event.stopPropagation();
  }

  /**
   * Returns the list of features activated in the top right menus.
   * The configuration from the config.ts file can be overriden by configuration from
   * the app configuration on the server
   */
  public get features(): string[] {
    return (this.appService.app?.data?.features as string[]) || FEATURES;
  }

  /**
   * Show or hide the left facet bar (small screen sizes)
   */
  toggleFilters() {
    this.headerSrv.toggleFilters();
    this.closeDocument();
    this.showSciXMetadata = false;
  }

  toggleFiltersForMobile() {
    this.hideFilterTab = !this.hideFilterTab;
  }

  /**
   * Controls visibility of menu (small screen sizes)
   */
  get showMenu(): boolean {
    return (
      this.ui.screenSizeIsGreaterOrEqual("sm") ||
      (this._showMenu && !this.headerSrv.showFilters)
    );
  }

  /**
   * Show or hide the user menus (small screen sizes)
   */
  toggleMenu() {
    this._showMenu = !this._showMenu;
  }

  nthIndexOf(str: string, character: string, n: number) {
    return str.split(character, n).join(character).length;
  }

  /* Go to the Document Navigator */
  openNavigator(record: Record, event: Event) {
    if (record.flags.length > 1) {
      if (
        ((record.fileext == "txt" ||
          record.fileext == "csv" ||
          record.fileext == "log") &&
          record.size > 50000000) ||
        ((record.fileext == "xls" || record.fileext == "xlsx") &&
          record.size > 1000000)
      ) {
        this.dialog.open(ModalPreviewComponent, {
          width: "35vw",
        });
      } else {
        this.previewService.openRoute(record, this.searchService.query);
        //this.loading = false;
        this.commonService.loadingBetweenComponents(false);
        this.DoNotPropagateClickEvent(event);
      }
    } else this.DoNotPropagateClickEvent(event);
  }

  goBack(e: Event) {
    this.goBackToOriginalParent = true;
    if (window.history.length > 0) {
      history.back();
    }
  }

  goToSource(url1: string, event: Event) {
    window.open(url1, "_blank");
    this.DoNotPropagateClickEvent(event);
  }

  setLoadingFromChild(loading: boolean) {
    // this.loading = loading;
    this.commonService.loadingBetweenComponents(loading);
  }

  toggleHideBadges(event: Event) {
    this.hasBadges = !this.hasBadges;
    this.DoNotPropagateClickEvent(event);
  }

  noCachedFlag(record: Record, title: string) {
    if (record.flags?.length == 1) {
      return "There is no preview available for this document.";
    }
    return title;
  }

  modifiedToISO(dateString: string) {
    return dateString.replace(/\s/g, "T");
  }

  getDocumentPreviewStyles(): any {
    let style: string = "";
    //Preview width in different scenarios
    // if (this.openedDoc != undefined && this.headerSrv._showFilters) {
    //   style = "col-md-4 col-lg-4 col-lx-4";
    // } else if (this.openedDoc != undefined && !this.headerSrv._showFilters) {
    //   style = "col-md-6 col-lg-6 col-lx-6";
    // }

    //Adjust width for preview actions based on viewer type
    if (this.openedDoc && this.openedDoc.isMetadataViewer) {
      style += " metdata-viewer-actions";
    } else {
      style += " default-viewer-actions";
    }

    return style;
  }

  checkSearchQueryTextAndUpdatePageTitle() {
    return this.searchService.query.text
      ? this.intlService.formatMessage(
          googleAnalyticsConstants.currentPage.sdeResultsPage,
          {
            search: this.searchService.query.text + " - ",
          }
        )
      : this.intlService.formatMessage(
          googleAnalyticsConstants.currentPage.sdeResultsPage,
          { search: "" }
        );
  }

  toggleEntityHighlighting(flag: boolean) {
    this.entityHighlightFlag = flag;
  }

  toggleExtractsHighlighting(flag: boolean) {
    this.extractsHighlightFlag = flag;
  }

  downloadDocument(record: SdeRecord) {
    this.commonService.downloadDocument(<SdeRecord>record);
  }

  preventClick(event: Event) {
    // event.preventDefault();
    // event.stopPropagation();
  }
  stopHoverHelpFeature() {
    this.commonService.toggleHoverHelp(false);
  }

  navigateToContactPage(source: any): void {
    this.router.navigate(["/contact-us"], { queryParams: source });
  }

  getSciXResponse() {
    // Set a default event with page number 1
    const defaultEvent = {
      page: 1,
      itemsPerPage: 50 /* set your default itemsPerPage value here */,
    };

    // Call pageChanged with the default event
    this.pageChangedForScix(defaultEvent);
  }

  // gets called when we are on sciX tab and now click on the other tabs
  updateUIElementsAfterSciX() {
    if (this.showSciXMetadata) {
      this.closeScixMetadataViewer();
    }
  }

  openScixMetadataViewer(doc: any, index: number) {
    // assigning the data when click on each card of sciX data
    this.selectedSciXMetadata = doc;

    // flag for showing the sciX Metadata Preview
    this.showSciXMetadata = true;
    // closing the filters panel when sciX Metadata Preview  is opened
    this.headerSrv.showFilters = false;

    // Set the index of the clicked document .. setting background on click of sciX card
    this.selectedScixIndex = index;

    // call the google analytics event when the document clicked
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.clickResultsDocument,
      0,
      true,
      {
        app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
        debug_mode: environment.googleAnalyticsDebugMode,
        page: this.commonService.isInTdammApp()
          ? googleAnalyticsConstants.currentPage.tdammResultsPage
          : googleAnalyticsConstants.currentPage.sdeResultsPage,

        url: this.router.url,
        ...this.commonService.createScixDocumentClickedObject(
          this.selectedSciXMetadata.bibcode,
          this.selectedSciXMetadata.abstract,
          this.selectedSciXMetadata.author,
          this.selectedSciXMetadata.bibstem,
          this.selectedSciXMetadata.publication,
          this.selectedSciXMetadata.pubdate,
          this.selectedSciXMetadata.title
        ),
      }
    );
  }

  pageChangedForScix(event: any): void {
    if (this.resultsEl) this.resultsEl.nativeElement.scrollTop = 0;
    this.commonService.resultsCardLoader = true; // Show the loader
    this.sciXData = [];
    this.page = event.page;
    this.startItem = (this.page - 1) * this.itemsPerPage;

    this.commonService
      .getPublicationsSciX(this.startItem, this.itemsPerPage)
      .subscribe(
        (response) => {
          // Convert the response to JSON
          const jsonData = response as any; // Assuming the response is of type 'any'

          // Access the keys from the JSON data here
          // You can now use dot notation or bracket notation to access the keys
          const key = jsonData.response.docs;
          this.totalData = jsonData.response.numFound;
          this.sciXData = key;
          this.commonService.resultsCardLoader = false;
        },
        (error) => {
          // Handle error here
          this.commonService.resultsCardLoader = false;
          this.sciXCount = 0;
        }
      );
  }

  /// closing the sciX metadata viewer
  closeScixMetadataViewer() {
    this.showSciXMetadata = false;
    this.selectedScixIndex = null;
  }

  getPublicationsCount() {
    const start = 0; // You can adjust the start index as per your requirement
    const rows = 1; // You can adjust the number of rows as per your requirement
    this.commonService.getPublicationsSciX(start, rows).subscribe(
      (data: any) => {
        this.sciXCount = data.response.numFound; // Store the response in the variable
        this.commonService.setSciXCount(this.sciXCount);
      },
      (error: any) => {
        this.sciXCount = 0;
      }
    );
  }

  setPreviewHeight() {
    this.changeDetectorRef.detectChanges();
    let top = this.resultsEl.nativeElement.getBoundingClientRect().top;
    this.previewStyle = {
      height: `calc(100vh - ${top}px)`,
    };
  }

  clearFilters() {
    delete this.searchService.query.filters;
    this.searchService.search();
    this.modalRef?.hide();
  }
}
