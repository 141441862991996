<div class="list-group list-group-flush">
    <a *ngFor="let basket of basketsService.baskets | slice:startIndex:endIndex"
        class="basket-item list-group-item list-group-item-action d-flex align-items-center"
        [routerLink]="[searchRoute]" [queryParams]="getQueryParams(basket)" [state]="getRouterState(basket)"
        (click)="openBasket(basket)">
        <span class="basket-name text-truncate me-auto">{{ basket.name }}</span>
        <span *ngIf="basket.ids" class="basket-count ms-2 text-muted small">{{ basket.ids.length }}</span>
        <i *ngIf="enableDelete" class="basket-delete ms-2 fas fa-times" [title]="'msg#baskets.delete' | sqMessage" (click)="deleteBasket(basket, $event)"></i>
    </a>
    <div *ngIf="basketsService.baskets.length === 0" class="list-group-item text-center text-muted fst-italic small unstriped py-5">
        {{ 'msg#baskets.noBasket' | sqMessage }}
    </div>
</div>
