<div class="home-page-background event-based-landing-page-bg-sde home">
  <div>
    <app-header
      [readMoreMode]="readMoreMode"
      [showCuratorMenu]="showCurator"
      [welcomeUserText]="userFullName"
    ></app-header>
  </div>

  <div class="home-page-content d-flex flex-row justify-content-center">
    <div
      [ngClass]="{
        'animate-move-to-top': readMoreMode === 'enabled',
        'animate-move-to-bottom': readMoreMode === 'disabled'
      }"
      class="home-page-positioning"
    >
      <div class="beta-container">
        <div
          tabindex="0"
          role="button"
          aria-label="Go back to the Science Discovery Engine homepage"
          *ngIf="readMoreMode === 'enabled'"
          (click)="toggleReadMoreMode('disabled')"
          (keyup.enter)="toggleReadMoreMode('disabled')"
          class="back-to-home position-absolute back-animate-show-fade"
        >
          <img src="assets/img/back-arrow.svg" alt="" />
          <span>{{ "back" | uppercase }}</span>
        </div>
      </div>
      <div class="width-div-sde-help-text">
        <div
          role="heading"
          aria-level="1"
          class="sde-text font-family-rajdhani-regular event-based-font-color-sde-landing-page"
        >
          Science Discovery Engine
        </div>

        <div
          joyrideStep="firstStep"
          [prevTemplate]="prevButton"
          [nextTemplate]="nextButton"
          [stepContent]="customContentOne"
          [stepPosition]="ui.screenSizeIsLessOrEqual('sm') ? 'bottom' : ''"
        ></div>
        <ng-template #customContentOne>
          <div class="joyride-step-header">
            <div
              class="joyride-step-title"
              style="color: rgb(2, 61, 139)"
              [ngClass]="{
                'w-75': ui.screenSizeIsLessOrEqual('sm'),
                'w-100': ui.screenSizeIsGreater('sm')
              }"
            >
              SCIENCE DISCOVERY ENGINE WALKTHROUGH
            </div>
          </div>
          <p class="joyride-content">
            Welcome to the Science Discovery Engine! This walkthrough will
            highlight and explain features of the platform in detail.
          </p>
          <p class="joyride-content" *ngIf="ui.screenSizeIsGreater('sm')">
            A walkthrough is available for each page of the interface. You can
            access the walkthroughs by clicking the Help button in the top right
            area of the pages.
          </p>
          <p class="joyride-content">
            You can progress through the features with the right arrow button or
            go back to a previous feature with the left arrow button.
          </p>
          <p class="joyride-content">
            You can also skip the tutorial by closing this window.
          </p>
        </ng-template>
        <ng-template #prevButton>
          <img
            id="close-icon"
            style="cursor: pointer"
            width="45"
            height="45"
            src="assets/img/walkthrough-previous.svg"
          />
        </ng-template>
        <ng-template #nextButton>
          <img
            id="close-icon"
            width="45"
            height="45"
            style="cursor: pointer"
            src="assets/img/walkthrough-next.svg"
          />
        </ng-template>

        <div
          class="text-empowering event-based-font-color-sde-landing-page"
          [ngClass]="{
            'transparent-font-color': ui.screenSizeIsLessOrEqual('sm')
          }"
        >
          <div
            *ngIf="readMoreMode !== 'enabled'"
            class="font-family-public-sans-regular"
          >
            Empowering open science, the Science Discovery Engine allows you to
            explore the universe, from the tiniest of cells to the vastness of
            space, through discovery of NASA's science data, documentation, and
            code.
            <div
              class="read-more-box"
              role="button"
              aria-label="Learn more about the purpose and scope of the Science Discovery Engine."
              tabindex="0"
              (keydown)="detectReadMoreEvent($event)"
              (click)="toggleReadMoreMode('enabled')"
            >
              <p
                *ngIf="
                  readMoreMode === 'initial' || readMoreMode === 'disabled'
                "
                class="animate-fade-in read-more-link event-based-read-more-font-color-sde-landing-page"
              >
                <u
                  [ngClass]="{ show: hoverHelpEnabled }"
                  [hoverHelpEnabled]="hoverHelpEnabled"
                  appTooltip="Click here to learn more about the purpose and scope of the Science Discovery Engine."
                  joyrideStep="fourthStep"
                  [stepContent]="customContentFour"
                >
                </u>
                <ng-template #customContentFour>
                  <div class="joyride-step-header">
                    <div
                      class="joyride-step-title"
                      style="color: rgb(2, 61, 139)"
                      [ngClass]="{
                        'w-75': ui.screenSizeIsLessOrEqual('sm'),
                        'w-100': ui.screenSizeIsGreater('sm')
                      }"
                    >
                      3 - ABOUT SDE
                    </div>
                  </div>
                  <p class="joyride-content">
                    Click here to learn more about the purpose and scope of the
                    Science Discovery Engine.
                  </p>
                </ng-template>
              </p>
            </div>
          </div>
        </div>
        <div
          *ngIf="readMoreMode === 'enabled'"
          class="animate-fade-in para-2 text-empowering-mobile-view event-based-font-color-sde-landing-page font-family-public-sans-regular"
        >
          NASA’s Science Mission Directorate (SMD) is building an open-source
          science infrastructure to enable collaborative and interdisciplinary
          science. The infrastructure is designed to improve discovery and
          access for all SMD data, providing immediate benefits to science data
          users and improving the overall user experience.
        </div>
        <div
          *ngIf="readMoreMode === 'enabled'"
          class="animate-fade-in para-3 text-empowering-mobile-view event-based-font-color-sde-landing-page font-family-public-sans-regular"
        >
          The Science Discovery Engine (SDE) is an integrated SMD search
          capability that enables discovery of open data and information across
          SMD’s five divisions: Astrophysics, Biological and Physical Sciences,
          Earth Science, Heliophysics and Planetary Science. SDE search results
          include a vast collection of NASA’s science data, documentation, and
          code. Search results may also include links to datasets, models,
          images, videos, software, or data analysis tools. The results can be
          filtered by associated missions, instruments, or branches of science.
        </div>
        <div
          *ngIf="readMoreMode === 'enabled'"
          class="animate-fade-in para-3 text-empowering-mobile-view event-based-font-color-sde-landing-page font-family-public-sans-regular"
        >
          The SDE makes NASA’s open science data, software and information more
          discoverable and accessible to a wider audience. This openness fosters
          collaboration within and across disciplines, promotes
          interdisciplinary science and encourages innovation.
        </div>
      </div>
      <div
        *ngIf="readMoreMode === 'initial' || readMoreMode === 'disabled'"
        class="search-bar"
        [ngClass]="{
          'animate-fade-in': readMoreMode === 'disabled',
          'my-3': ui.screenSizeIsLessOrEqual('sm')
        }"
      >
        <div
          [ngClass]="{ show: hoverHelpEnabled }"
          [hoverHelpEnabled]="hoverHelpEnabled"
          appTooltip="Select the scientific focus area of your search.
          "
          dropdown
          [dropup]="false"
          [placement]="'bottom'"
          [stepContent]="customContentThree"
          joyrideStep="thirdStep"
        >
          <button
            aria-label="This is a dropdown to select different scientific focus area's of your search."
            id="button-basic"
            dropdownToggle
            type="button"
            aria-controls="dropdown-basic"
            class="my-2 dropdown-toggle flex-container dropdown-button overflow-hidden"
            [ngClass]="{
              'infinite-animate':
                selectedOption === 'Biological & Physical Sciences' ||
                selectedOption === 'Planetary Science'
            }"
            type="button"
            data-bs-toggle="dropdown"
            [attr.aria-expanded]="isScientificFocusOpen ? 'true' : 'false'"
            (click)="toggleScientificFocus()"
          >
            <div
              class="sci-img-container inherit-borders position-absolute d-flex flex-column justify-content-center"
            >
              <img
                [src]="selectedOptionImage"
                alt="{{ selectedOption }}"
                class="image-width"
              />
            </div>
            <span class="flex-item-start selected font-family-inter-regular">{{
              selectedOption || scientificFocusValues[0]
            }}</span>
            <div
              class="flex-item-end arrow d-flex flex-column justify-content-center"
            >
              <i
                class="fa-solid fa-chevron-down"
                [ngClass]="{ 'rotate-icon': isScientificFocusOpen }"
              ></i>
            </div>
          </button>
          <ul class="dropdown-menu" ng-click="$event.stopPropagation()">
            <ng-container *ngFor="let item of scientificFocusValues">
              <li
                *ngIf="selectedOption !== item"
                class="d-flex align-items-center scifocus position-relative"
                [ngClass]="{
                  'overflow-hidden infinite-animate':
                    item === 'Biological & Physical Sciences'
                }"
              >
                <div
                  class="sci-img-container position-absolute d-flex flex-column justify-content-center"
                >
                  <img
                    [src]="getIconSrc(item)"
                    alt="{{ item }}"
                    class="image- 1"
                  />
                </div>
                <a
                  role="presentation"
                  class="dropdown-item"
                  [ngClass]="{
                    'selected font-family-inter-regular':
                      item === selectedOption
                  }"
                  (click)="changeBackgroundOfScientificFocus(item)"
                  href="#"
                  [attr.aria-label]="getAriaLabel(item)"
                >
                  {{ item }}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
        <ng-template #customContentThree>
          <div class="joyride-step-header">
            <div
              class="joyride-step-title"
              style="color: rgb(2, 61, 139)"
              [ngClass]="{
                'w-75': ui.screenSizeIsLessOrEqual('sm'),
                'w-100': ui.screenSizeIsGreater('sm')
              }"
            >
              2 - SEARCH FOCUS
            </div>
          </div>
          <p class="joyride-content">
            This dropdown menu includes options to filter results by Scientific
            Focus Area (Earth Science, Planetary Science, Astrophysics,
            Biological and Physical Sciences, Heliophysics, or All).
          </p>
        </ng-template>

        <div
          [ngClass]="{
            'ms-3': ui.screenSizeIsGreaterOrEqual('md'),
            'ms-1':
              ui.screenSizeIsGreaterOrEqual('sm') && ui.screenSizeIsLess('md'),
            'ms-0': ui.screenSizeIsGreater('xs') && ui.screenSizeIsLess('sm')
          }"
          class="search-form"
          [stepContent]="customContentTwo"
          joyrideStep="secondStep"
        >
          <app-search-form
            [ngClass]="{ show: hoverHelpEnabled }"
            [hoverHelpEnabled]="hoverHelpEnabled"
            appTooltip="The search bar displays the search query text. Click on the search bar to edit the query or begin a new search.
            "
          ></app-search-form>
        </div>
        <ng-template #customContentTwo>
          <div class="joyride-step-header">
            <div
              class="joyride-step-title"
              style="color: rgb(2, 61, 139)"
              [ngClass]="{
                'w-75': ui.screenSizeIsLessOrEqual('sm'),
                'w-100': ui.screenSizeIsGreater('sm')
              }"
            >
              1 - SEARCH BAR
            </div>
          </div>
          <p class="joyride-content">
            Type your query here, then either click the magnifying glass icon or
            press enter on your keyboard to search.
          </p>
          <p class="joyride-content">
            To conduct a speech-activated search, click the microphone icon. You
            will need to allow your web browser to access your microphone
          </p>
        </ng-template>
      </div>
      <div
        *ngIf="
          (readMoreMode === 'initial' || readMoreMode === 'disabled') &&
          homeFacets.length
        "
        class="sq-home-facet-bar-sde home-container animate-fade-in mt-5"
        [ngClass]="{ 'animate-fade-in': readMoreMode === 'disabled' }"
      >
        <div class="row width-100">
          <!-- DO NOT DELETE : Advanced search will be implemented later on.. -->
          <!-- <h5 class="advanced-search font-family-rajdhani-regular">
            <a target="_blank" class="advanced-search-link"> Advanced Search!</a>
          </h5> -->
          <!-- For each activated facet -->
          <!-- <ng-container *ngFor="let facet of homeFacets; let i = index" [ngSwitch]="facet"> -->

          <!-- Announcements -->
          <!-- <div class="card card-width">
            <div class="card-body"> -->
          <!-- <h5 style="margin-left: -10px; color: #f2f2f2">
            The SDE is frequently updated. We welcome your
            <a
              target="_blank"
              href="//science.data.nasa.gov/contact/"
              style="color: #f2f2f2; text-decoration: underline"
              >feedback</a
            >!
          </h5> -->
          <div
            #cardContainer
            class="card-container"
            [ngClass]="{ 'p-0': ui.screenSizeIsLessOrEqual('sm') }"
          >
            <!-- <a
              tabindex="0"
              aria-label="Click this card to leave feedback
              about adding data sources, features you’d like to see or
              anything else."
              (click)="navigateToContactPage({ source: 'SDE' })"
            >
              <div
                class="col-md-6"
                #leftCard
                [ngClass]="{ show: hoverHelpEnabled }"
                [hoverHelpEnabled]="hoverHelpEnabled"
                appTooltip="Click this card to leave feedback about the SDE or ask a question.
              "
                joyrideStep="sixthStep"
                [stepContent]="customContentSixth"
                
                (done)="endWalkthroughTourForHomePage()"
                class="mb-3 single-card-1 cursor-pointer"
                [ngStyle]="{
                  'background-image': selectedBackground1
                    ? 'url(' + selectedBackground1 + ')'
                    : 'url(assets/img/Background/all-image1.webp)',
                  'background-size': 'cover'
                }"
              >
                <div class="my-2 card-body">
                  <div class="first-layer">
                    <p class="card-title font-family-rajdhani-semibold">
                      Share your SDE experience!
                    </p>
                    <div class="show-more-image">
                      <img
                        class="position-absolute highlight-icon"
                        src="assets/img/show-more-highlighted.svg"
                        alt="help"
                        height="40"
                        width="40"
                      />
                      <img
                        src="assets/img/show-more-highlighted.svg"
                        alt="help"
                        height="40"
                        width="40"
                      />
                    </div>
                  </div>
                  <p class="card-text font-family-public-sans-regular">
                    This is an iterative application and the Science Discovery
                    Engine team welcomes feedback on both SDE content and search
                    engine functionality. Click this card to leave feedback
                    about adding data sources, features you’d like to see or
                    anything else.
                  </p>
                </div>
              </div>
            </a> -->
            <a
              tabindex="0"
              aria-label="Click this card to read interesting stories of what people search for
              on the SDE"
              [href]="getSearchStoryURL()"
              (click)="navigateToSearchStory()"
            >
              <div
                class="col-md-6"
                #leftCard
                [ngClass]="{ show: hoverHelpEnabled }"
                [hoverHelpEnabled]="hoverHelpEnabled"
                appTooltip="Click this card to see how users are discovering ideas and resources in the SDE."
                joyrideStep="sixthStep"
                [stepContent]="customContentSixth"
                (done)="endWalkthroughTourForHomePage()"
                class="mb-3 single-card-1 cursor-pointer"
                [ngStyle]="{
                  'background-image': selectedBackground1
                    ? 'url(' + selectedBackground1 + ')'
                    : 'url(assets/img/Background/all-image1.webp)',
                  'background-size': 'cover'
                }"
              >
                <div class="my-2 card-body">
                  <div class="first-layer">
                    <p class="card-title font-family-rajdhani-semibold">
                      What will your search reveal?
                    </p>
                    <div class="show-more-image">
                      <img
                        class="position-absolute highlight-icon"
                        src="assets/img/show-more-highlighted.svg"
                        alt="help"
                        height="40"
                        width="40"
                      />
                      <img
                        src="assets/img/show-more-highlighted.svg"
                        alt="help"
                        height="40"
                        width="40"
                      />
                    </div>
                  </div>
                  <p class="card-text font-family-public-sans-regular">
                    SDE users are making interesting, unexpected discoveries
                    every day. Click here to read their search stories and share
                    your own!
                  </p>
                </div>
              </div>
            </a>
            <a
              (click)="navigateToSciXLink()"
              aria-label="Click to learn more about SciX"
              href="https://scixplorer.org/"
              target="_blank"
              *ngIf="selectedOption !== 'Earth Science'"
            >
              <div
                #rightCard
                *ngIf="selectedOption !== 'Earth Science'"
                class="mb-3 single-card-2 cursor-pointer"
                [ngStyle]="{
                  'background-image': selectedBackground2
                    ? 'url(' + selectedBackground2 + ')'
                    : 'url(assets/img/Background/all-image2.webp)',
                  'background-size': 'cover'
                }"
              >
                <div class="my-2 card-body">
                  <div class="first-layer">
                    <p class="card-title font-family-rajdhani-semibold">
                      EXPLORE SciX
                    </p>
                    <div class="show-more-image">
                      <img
                        src="assets/img/show-more-highlighted.svg"
                        alt="help"
                        height="40"
                        width="40"
                      />
                    </div>
                  </div>
                  <p class="card-text font-family-public-sans-regular">
                    The NASA Science Explorer (SciX) is a digital library portal
                    for researchers in Astronomy, Earth Science, Heliophysics,
                    Physics, and Planetary Science. It contains over 20 million
                    records covering refereed publications plus all arXiv, ESS
                    Open Archive, and EarthArXiv preprints.
                  </p>
                </div>
              </div>
            </a>
            <a
              aria-label="Click this card to visit the Environmental Justice Data Search Interface (Powered by the Science Discovery Engine)"
              (click)="navigateToEjApp()"
              target="_blank"
              *ngIf="selectedOption === 'Earth Science'"
            >
              <div
                #ejCard
                class="mb-3 single-card-3 cursor-pointer"
                [ngStyle]="{
                  'background-image': selectedBackground2
                    ? 'url(' + selectedBackground2 + ')'
                    : 'url(assets/img/Background/all-image2.svg)',
                  'background-size': 'cover'
                }"
              >
                <div class="my-2 card-body">
                  <div class="first-layer">
                    <div class="img-first-layer">
                      <img
                        src="assets/img/ej-logo.svg"
                        class="ej-card-logo"
                        alt="Environmental Justice Data Search Interface"
                      />
                      <p class="card-title font-family-rajdhani-semibold">
                        ENVIRONMENTAL JUSTICE
                      </p>
                    </div>

                    <div class="show-more-image">
                      <img
                        src="assets/img/show-more-highlighted.svg"
                        alt="help"
                        height="40"
                        width="40"
                      />
                    </div>
                  </div>
                  <p class="card-text font-family-public-sans-regular">
                    The environmental justice data search interface enables
                    users to explore NASA Earth science datasets related to
                    disasters, climate change, urban flooding, extreme heat,
                    food availability, water availability, health and air
                    quality, and human dimensions.
                  </p>
                </div>
              </div>
            </a>
          </div>
          <ng-template #customContentSixth>
            <div class="joyride-step-header">
              <div class="joyride-step-title" style="color: rgb(2, 61, 139)">
                5 - SHARE YOUR SDE SEARCH EXPERIENCE!
              </div>
            </div>

            <p class="joyride-content">
              Click this card to see how users are discovering ideas and
              resources in the SDE.
            </p>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Search Form (app/search-form) -->
  </div>
</div>
<div
  style="text-align: end; position: absolute; bottom: 0px; right: 0px"
  *ngIf="hoverHelpEnabled"
>
  <button
    class="font-family-rajdhani-medium"
    style="
      background: #fff7ad;
      border: none;
      text-transform: uppercase;
      padding: 8px;
    "
    (click)="stopHoverHelpFeature()"
  >
    <img
      id="close-icon"
      class="close-popup"
      src="assets/img/cross-hover-help.svg"
    />
    CLICK HERE TO EXIT HOVER HELP
  </button>
</div>
