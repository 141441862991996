<svg [attr.viewBox]="'0 0 '+width+' '+height">

    <!-- Legend -->
    <g *ngIf="data.length" [attr.transform]="'translate('+this.margin.left+', 10)'" class="legend">
        <g *ngFor="let item of getLegend()">
            <rect [attr.x]="item.x" [attr.width]="item.width" height="15" [attr.fill]="item.color"></rect>
            <line [attr.x1]="item.x" [attr.x2]="item.x" [attr.y2]="27" stroke="lightgrey"></line>
            <text [attr.x]="item.x + 3" y="27">{{ item.start | number : '1.0-0'}}</text>
        </g>
    </g>

    <g [attr.transform]="'translate('+this.margin.left+','+ margin.top +')'">
        <!-- X Axis (built in the controler) -->
        <g #xAxis [ngClass]="{clickable: axisClickable}" class="axis"></g>

        <!-- Y Axis (built in the controler) -->
        <g #yAxis [ngClass]="{clickable: axisClickable}" class="axis"></g>

        <!-- Heatmap tiles -->
        <rect *ngFor="let item of dataFiltered" class="tile"
            [ngClass]="{clickable: itemsClickable, selected: item.selected}"
            [attr.x]="x(item.x.value)"
            [attr.y]="y(item.y.value)"
            [attr.width]="x.bandwidth()"
            [attr.height]="y.bandwidth()"
            [attr.fill]="color(item.count)"
            (mouseover)="onMouseOver(item, $event)"
            (mouseout)="onMouseOut()"
            (click)="onItemClicked(item)">
        </rect>

    </g>

    <text class="bottom-legend" [attr.transform]="'translate('+(width-margin.right)+', '+(height-5)+')'">
        {{'msg#heatmap.showing' | sqMessage:{values: {shown: dataFiltered.length, total: data.length} } }}
    </text>

</svg>

<!-- Tooltip -->
<sq-tooltip class="text-center" [manager]="tooltipManager" [theme]="theme">
  <ng-template let-data>
    <strong>{{data.y.display}} - {{data.x.display}}</strong>
    <div>{{'msg#heatmap.tooltip' | sqMessage:{values: {count: data.count} } }}</div>
  </ng-template>
</sq-tooltip>
