import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FacetService } from "@sinequa/components/facet";
// import { SearchService } from "@sinequa/components/search";
import {
  Aggregation,
  AggregationItem,
  Results,
  TreeAggregationNode,
} from "@sinequa/core/web-services";
import { Subject } from "rxjs";

@Component({
  selector: "app-search-badges",
  templateUrl: "./search-badges.component.html",
  styleUrls: ["./search-badges.component.scss"],
})
export class SearchBadgesComponent {
  @Input() record: any;
  @Input() results: Results;

  // Click on badge
  // Badges click event
  selected: AggregationItem[] = [];

  /*   badgeValue$ = new Subject<any>(); */

  destroy$ = new Subject();

  doSearchHandler: any;

  @Output() onLoading = new EventEmitter<any>();
  constructor(
    // private searchService: SearchService,
    private facetService: FacetService
  ) {}

  DoNotProgagateClickEvent(event: { stopPropagation: () => void }): void {
    event.stopPropagation();
  }

  /*
    property = true -> proprty : = false -> source or collection
    (if property = false) collection = undefined -> source : true -> collection
  */
  doSearch(
    aggregate: string,
    value: string,
    event: Event,
    propertyType: boolean = true,
    collection: string = ""
  ) {
    var items = <Aggregation>this.facetService.getAggregation(aggregate);
    if (items && items.items) {
      var facetPosition;
      // var facetPosition =
      //   this.searchService.breadcrumbs?.activeSelects.findIndex(
      //     (select) =>
      //       select.facet === aggregate && select.expr?.getValues(value)
      //   );
      if (facetPosition === -1) {
        this.selected.length = 0;
        if (propertyType) {
          //Not source nor collection
          let newItem = {
            ...items.items[0],
          };
          newItem.display = value;
          newItem.value = value.toLowerCase();
          this.selected.push(newItem);
        } else if (collection === "") {
          for (let it of items.items) {
            let compareValue = (<string>it.value).toLowerCase();
            if (compareValue == value.toLowerCase()) {
              this.selected.push(it);
              break;
            }
          }
        } else {
          for (let item of items.items) {
            let compareValue = (<string>item.value).toLowerCase();
            if (compareValue == value.toLowerCase()) {
              collection = collection.toLowerCase();
              var child = <TreeAggregationNode>item;
              for (let item of child.items) {
                if ((<string>item.value).toLowerCase() == collection) {
                  this.selected.push(item);
                  break;
                }
              }
            }
          }
        }
      }
      if (this.selected.length > 0) {
        this.onLoading.emit(true);
        // this.facetService.addFilterSearch(aggregate, items, this.selected);
      }
      event.stopPropagation();
    }
  }
}
