<div class="d-flex flex-column">

    <div class="d-flex flex-wrap mt-1 px-1" *ngIf="allowPredefinedRange && data">
        <button
            *ngFor="let item of items"
            type="button"
            class="btn btn-outline-secondary btn-sm rounded-pill date-item ms-1 mb-1"
            [ngClass]="{active: item.$filtered}"
            [disabled]="!item.count"
            (click)="filterItem(item, $event)">

            {{item | sqValue:item.$column}}

            <span *ngIf="showCount && item.count"
              class="counter rounded-pill bg-light text-black ms-1"
              [title]="item.count + ' occurrences in document'">
              {{item.count}}
            </span>
        </button>
    </div>

    <div class="tab" *ngIf="allowCustomRange && allowPredefinedRange">
        <input type="checkbox" name="accordion-custom-range" id="check-custom-range">
        <label for="check-custom-range" class="tab-label ms-1 text-muted">{{'msg#facet.date.custom' | sqMessage}} :</label>

        <div class="tab-content">
            <form [formGroup]="form">
                <div class="px-2 py-1 small">
                    <sq-date-range-picker
                        [formControlName]="'dateRange'"
                        sqAdvancedFormValidation
                        [size]="'sm'"
                        [field]="'dateRange'"
                        [validationForm]="form"
                        [display]="'column'"
                        [displaySeparator]="false"
                        [displayLabel]="true">
                    </sq-date-range-picker>
                </div>
            </form>

            <sq-timeline
                *ngIf="allowCustomRange"
                [data]="timeSeries"
                [selection]="selection"
                width="{{timelineWidth}}"
                height="{{timelineHeight}}"
                [margin]="timelineMargin"
                [showTooltip]="true"
                [curveType]="'curveLinear'"
                (selectionChange)="updateRange($event)"
                (rangeChange)="cdRef.markForCheck()">
            </sq-timeline>
        </div>
    </div>
</div>
