<ng-container *ngFor="let item of itemsVisible">
    <!-- action with href -->
    <a *ngIf="item && item.href"
        class="btn {{styleClass}} {{sizeClass}}"
        [attr.href]="!item.disabled ? item.href : null"
        (click)="itemClick(item, $event)"
        sqTooltip="{{item.title | sqMessage:item.messageParams}}"
        placement="{{item?.titlePlacement}}"
        [fallbackPlacements]= "item.fallbackPlacements"
        [sq-action-item]="getActionItemOptions(item)">
    </a>
    <ng-container *ngIf="item && !item.href">
        <!-- action without href -->
        <div *ngIf="item.hasChildren" class="btn-group" [sq-action-item]="getActionItemOptions(item)"></div>
        <button *ngIf="!item.hasChildren"
            type="button"
            class="btn {{styleClass}} {{sizeClass}}"
            [ngClass]="{'active': item.selected, 'disabled': item.disabled}"
            [sq-action-item]="getActionItemOptions(item)"
            (click)="itemClick(item, $event)"
            sqTooltip="{{item.title | sqMessage:item.messageParams}}"
            placement="{{item?.titlePlacement}}"
            [fallbackPlacements]= "item.fallbackPlacements"
            [attr.aria-label]="item.title | sqMessage:item.messageParams"></button>
    </ng-container>
</ng-container>
