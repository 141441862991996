<div class="mb-3" [formGroup]="form">
    <label class="form-label" for="{{field}}">{{label | sqMessage}}</label>
    <sq-select
        [items]="items"
        [multiple]="multiple"
        [formControlName]="field"
        [id]="field"
        sqAdvancedFormValidation
        [field]="field"
        [validationForm]="form">
    </sq-select>
</div>
