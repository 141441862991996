<div class="list-group list-group-flush">
    <ng-container *ngFor="let document of recentDocumentsService.recentdocuments | slice:startIndex:endIndex">
        <a *ngIf="openOriginal && document.url1; else internalLink"
            class="recent-document-item list-group-item list-group-item-action d-flex align-items-center"
            [attr.href]="document.url1" target="_blank" rel="noopener"
            (click)="openRecentDocument(document)">
            <span class="document-text me-auto text-truncate" title="{{ document.title }}">{{ document.title }}</span>
            <span *ngIf="document.date" class="document-date ms-2 text-muted small text-right">{{ document.date | sqDate }}</span>
            <i *ngIf="enableDelete" class="document-delete ms-2 fas fa-times" [title]="'msg#recentDocuments.delete' | sqMessage" (click)="deleteDocument(document, $event)"></i>
        </a>
        <ng-template #internalLink>
        <a class="recent-document-item list-group-item list-group-item-action d-flex align-items-center"
            [routerLink]="searchRoute" [queryParams]="getQueryParams(document)"
            (click)="openRecentDocument(document)">
            <span class="document-text me-auto text-truncate" title="{{ document.title }}">{{ document.title }}</span>
            <span *ngIf="document.date" class="document-date ms-2 text-muted small text-right">{{ document.date | sqDate }}</span>
            <i *ngIf="enableDelete" class="document-delete ms-2 fas fa-times" [title]="'msg#recentDocuments.delete' | sqMessage" (click)="deleteDocument(document, $event)"></i>
        </a>
        </ng-template>
    </ng-container>
    <div *ngIf="recentDocumentsService.recentdocuments.length === 0" class="list-group-item text-center text-muted fst-italic small unstriped py-5">
        {{ 'msg#recentDocuments.noRecentDocument' | sqMessage }}
    </div>
</div>
