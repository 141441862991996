export default {
  preview: {
    previous: "Previous",
    next: "Next",
    relevanceSortHighlightButtonText: "Relevance",
    textOrderSortHighlightButtonText: "Text order",
    showAll: "Show all values",
    sortAlphabetically: "Sort alphabetically",
    sortFrequency: "Sort by frequency",
    loading: "Loading...",
    noextract: "No extract",
    search: "Search",
    closeTitle: "Close",
    expandTitle: "Maximize",
    minimize: "Zoom out",
    maximize: "Zoom in",
    toggleHighlight: "Toggle highlighting",
    toggleExtracts: "Toggle relevant extracts highlighting",
    toggleEntities: "Toggle entities highlighting",
    extracts: "Relevant Extracts",
    entities: "Entities",
    noentities: "No Entities",
    norelateddocs: "No Related Documents",
    downloadPdf: "Download PDF version",
    viewPreview: "Preview",
    viewPassages: "Snippets",
    viewDuplicates: "Duplicates",
    documentPreview: "Document preview",
    documentPreviewTitle: "This is the document preview",
    documentPreviewDescription:
      "Click on one of your search results to preview the document here.",
  },
};
