<form name="manageBaskets" novalidate>
    <sq-modal [title]="'msg#manageBaskets.title'" [buttons]="buttons">
        <div *ngIf="model.baskets.length" class="mb-3 clearfix">
            <button type="button" class="btn btn-secondary float-right" (click)="reorder()">{{(reordering ? "msg#manageBaskets.save" : "msg#manageBaskets.reorder") | sqMessage}}</button>
        </div>
        <div class="mb-3">
            <div class="list-group" cdkDropList [cdkDropListData]="model.baskets" [cdkDropListDisabled]="!reordering" (cdkDropListDropped)="dropped($event)">
                <div *ngFor="let basket of model.baskets; let $index = index" cdkDrag
                    role="button"
                    class="list-group-item list-group-item-action d-flex"
                    [ngClass]="{'cursor-move': reordering}">
                    <div *ngIf="reordering" class="sq-basket-text">{{basket.name}}</div>
                    <sq-editable *ngIf="!reordering" [value]="basket.name" (valueChange)="setName(basket, $event)" name="msg#manageBaskets.name"
                        [model]="basket" [validators]="nameValidators"></sq-editable>
                    <div class="ms-auto">
                        <i *ngIf="!reordering && appService.app?.queryExport" class="fas fa-download sq-export ms-2" (click)="export(basket)" title="{{ 'msg#manageBaskets.tooltip.export' | sqMessage }}"></i>
                        <i *ngIf="!reordering" class="fas fa-times sq-remove m-2" (click)="remove(basket, $index)" title="{{'msg#manageBaskets.remove' | sqMessage}}"></i>
                        <i *ngIf="reordering" class="ms-2 fas fa-bars sq-move"></i>
                    </div>
                </div>
            </div>
        </div>
    </sq-modal>
</form>
