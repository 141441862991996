import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mobile-view",
  templateUrl: "./mobile-view.component.html",
  styleUrls: ["./mobile-view.component.scss"],
})
export class MobileViewComponent implements OnInit {
  constructor(protected _location: Location) {}

  timer: number = 7;

  ngOnInit(): void {
    let redirectInterval = setInterval(() => {
      this.timer--;
      if (this.timer === 0) {
        clearInterval(redirectInterval);
        this._location.back();
      }
    }, 1000);
  }
}
