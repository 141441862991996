import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Query } from "@sinequa/core/app-utils";

import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AdvancedService } from "@sinequa/components/advanced";
import { FirstPageService, SearchService } from "@sinequa/components/search";
import { SearchFormComponent } from "@sinequa/components/search-form/search-form.component";
import { UserPreferences } from "@sinequa/components/user-settings";
import { AppService } from "@sinequa/core/app-utils";
import { LoginService } from "@sinequa/core/login";
import { NotificationsService } from "@sinequa/core/notification";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { FEATURES } from "../../config";
import { CommonService } from "../services/common.service";

@Component({
  selector: "app-search-form",
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.scss"],
})
export class SdeSearchComponent implements OnInit, DoCheck, OnDestroy {
  searchControl: FormControl;
  form: FormGroup;
  autofocus = 0;
  autoSuggestData: any;

  /** Expression from the query selects, if any ("simple"/"selects" field search mode) */
  fieldSearchExpression?: string;

  /** Result of query parsing ("advanced" field search mode) */
  parseResult?: any;

  /** A reference to the AutocompleteExtended directive, needed to get the field search selections, if any */

  @ViewChild("searchInput") searchInput: ElementRef;
  @Input() searchRoute = "search";

  // Advanced search flags
  showAdvancedSearch: boolean;
  initAdvanced: boolean;
  enableAdvancedForm = false; // Show the advanced form button or not

  /** Define if a filter, NOT belonging to fielded & advanced search, is currently applied to the searchService.query */
  isFiltering = false;

  keepFiltersTitle = "msg#searchForm.notKeepFilters";
  enableKeepFilters = true; // Show the "keep filters" button or not

  /** USED ALONG WITH keepFilters context, to optionally reset the advanced-search or not */
  keepAdvancedSearchFilters = false;

  /** Define if should stay on the same tab even after a new search */
  keepTab = false;
  isDropdownOpen: boolean;

  /** Voice recognition */
  voiceRecognitionState = false;
  enableVoiceRecognition = true; // Show the voice recognition button or not

  hasScroll = false;
  @ViewChild("searchContainer") searchContainer: ElementRef;
  private timeout: any;

  private subscriptions: Subscription[] = [];

  @ViewChild("searchForm") searchForm: SearchFormComponent;

  constructor(
    public searchService: SearchService,
    public loginService: LoginService,
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public appService: AppService,
    public prefs: UserPreferences,
    public firstPageService: FirstPageService,
    public router: Router,
    public advancedService: AdvancedService,
    public route: ActivatedRoute,
    public commonService: CommonService,
    private cdRef: ChangeDetectorRef
  ) {}

  /**
   * Initialization of the form
   */
  ngOnInit() {
    this.searchControl = new FormControl("");
    // this.autoSuggest();
    this.form = this.formBuilder.group({
      search: this.searchControl,
    });

    this.commonService.isDropdownOpen.subscribe((data) => {
      this.isDropdownOpen = data;
    });

    // Every time the query changes, we want to update the search form
    this.subscriptions.push(
      this.searchService.queryStream.subscribe((query) => {
        // Update main search bar
        this.searchControl.setValue(this.searchService.query?.text || "");
        // this.fieldSearchExpression =
        //   query?.findSelect("search-form")?.expression;
        this.autofocus++;
        // Update advanced form
        this.form
          .get("treepath")
          ?.setValue(this.advancedService.getValue("treepath"));
        this.form
          .get("authors")
          ?.setValue(this.advancedService.getValue("authors"));
        this.form
          .get("size")
          ?.setValue(this.advancedService.getRangeValue("size"));
        this.form
          .get("modified")
          ?.setValue(this.advancedService.getRangeValue("modified"));
        //this.form.get('person')?.setValue(this.advancedService.getValue('person'));
        this.form
          .get("docformat")
          ?.setValue(this.advancedService.getValue("docformat"));

        // Update the filtering status
        // this._updateFilteringStatus();
      })
    );

    // Initialize the search form options (either now, or when login is complete)
    if (this.appService.app) {
      this.setOptions();
    } else {
      this.subscriptions.push(
        this.loginService.events.subscribe((event) => {
          if (this.appService.app) {
            this.setOptions();
          }
        })
      );
    }
  }

  ngDoCheck() {
    // Check if the input has a scrollbar
    this.hasScroll =
      this.searchContainer?.nativeElement.scrollWidth >
      this.searchContainer?.nativeElement.clientWidth;
  }

  ngOnDestroy() {
    this.subscriptions.map((item) => item.unsubscribe());
  }

  setOptions() {
    const features =
      (this.appService.app?.data?.features as string[]) || FEATURES;
    features.forEach((feature) => {
      switch (feature) {
        case "advanced-form":
          this.enableAdvancedForm = true;
          break;
        case "keep-advanced-form-filters":
          this.keepAdvancedSearchFilters = true;
          break;
        case "keep-tab":
          this.keepTab = true;
          break;
        case "toggle-keep-filters":
          this.enableKeepFilters = true;
          break;
        case "voice-recognition":
          this.enableVoiceRecognition = true;
          break;
      }
    });
  }

  onAutocompleteSearch(text: string, query: Query) {
    query.text = text;
    this.searchForm.applyFilters(); // Apply the autocomplete query and close the form
  }

  onAutocompleteSelect(text: string, query: Query) {
    query.text = text;
  }

  /**
   * Trigger a search query via the search service
   */
  search() {
    // this.searchService.loadingBetweenComponents(true);
    if (this.loginService.complete && this.form.valid) {
      let currentSciFocus;

      if (this.commonService.selectedScientificFocus !== "") {
        currentSciFocus = this.commonService.selectedScientificFocus;
        this.searchService.query.scope = currentSciFocus;
        this.appService.scope = currentSciFocus;
        this.searchService.query.tab = "Data";
      } else {
        currentSciFocus = "All";
        this.searchService.query.scope = currentSciFocus;
        this.searchService.query.tab = "Data";
      }

      // Check if query tab is 'Publications', if so, do not trigger search
      /** Hide autocomplete suggestions */
      this.searchInput.nativeElement.blur();

      /** Store relevant filters (tab ...)*/
      const queryTab = this.searchService.query.tab;

      /** Close the advanced form */
      this.showAdvancedSearch = false;

      /** Update the new query with entered text */
      this.searchService.query.text = this.searchControl?.value || "";

      /** Update advanced search filters */
      this.advancedService.setSelect(
        "treepath",
        this.form.get("treepath")?.value
      );
      this.advancedService.setSelect(
        "authors",
        this.form.get("authors")?.value
      );
      this.advancedService.setRangeSelect("size", this.form.get("size")?.value);
      this.advancedService.setRangeSelect(
        "modified",
        this.form.get("modified")?.value
      );
      this.advancedService.setSelect(
        "docformat",
        this.form.get("docformat")?.value
      );

      // if this.keepTab, stay on the same tab even after a new search
      if (this.keepTab && !!queryTab) {
        this.searchService.query.tab = queryTab;
      }

      //clearing the current filter when we search through searchbar
      this.commonService.clearFilterEvent.next(true);
      /** Trigger the search with the new criteria */
      this.searchService.searchText("search");
    } else {
      if (this.parseResult && this.parseResult.error) {
        this.notificationsService.error(this.parseResult.error);
      }
    }
  }

  /**
   * Test if the search input is not empty
   */
  hasContent(): boolean {
    return this.searchControl.value || this.fieldSearchExpression;
  }

  /**
   * Clears the search input and the fielded search
   */
  clearForm($event) {
    $event.preventDefault();
    // resetting the search query and results on clear form
    if (this.commonService.isHome()) {
      this.searchService.searchText("home");
    } else {
      this.searchService.searchText("search");
    }

    // this.searchService.loadingBetweenComponents(true);
    this.fieldSearchExpression = "";
    this.searchInput.nativeElement.blur();
    this.parseResult = undefined;
    this.searchControl.setValue("");
    this.fieldSearchExpression = "";
    this.showAdvancedSearch = false;
    this.cdRef.detectChanges();
    this.updateSearchQuery();
  }
  updateSearchQuery() {
    // Update the search query based on the changes made in clearForm()
    this.searchService.query.text = this.searchControl?.value || "";
    // Trigger the search to reflect the updated query
    if (this.commonService.isHome()) {
      this.searchService.searchText("home");
    } else {
      this.searchService.searchText("search");
    }
  }

  /**
   * Test if the advanced form has non-undefined values set
   */
  hasAdvancedContent(): boolean {
    return (
      this.form.get("treepath")?.value ||
      this.form.get("authors")?.value ||
      this.form.get("size")?.value?.find((v) => v) ||
      this.form.get("modified")?.value?.find((v) => v) ||
      //   || this.form.get("person")?.value
      this.form.get("docformat")?.value
    );
  }

  /**
   * Clears the advanced-search form
   */
  clearAdvancedSearch(): void {
    // this.advancedService.resetAdvancedValues();
    /** Close the advanced form */
    this.showAdvancedSearch = false;
  }

  /**
   * Test if the query contains advanced-search related filters
   */
  isAdvancedSearchActive(): any {
    // return this.searchService.query.hasAdvanced();
  }

  /**
   * Clear only the advanced form
   */
  clearAdvancedForm() {
    if (this.initAdvanced) {
      this.advancedService.resetControl(this.form.get("treepath")!);
      this.advancedService.resetControl(this.form.get("authors")!);
      this.advancedService.resetRangeControl(this.form.get("size")!);
      this.advancedService.resetRangeControl(this.form.get("modified")!);
      this.advancedService.resetControl(this.form.get("docformat")!);
    }
  }

  onParse(parseResult: any) {
    this.parseResult = parseResult;
    this.searchControl.setErrors(
      parseResult.error ? { incorrect: true } : null
    );
  }

  /**
   * Autocomplete icon per category
   * @param category
   */
  autocompleteIcon(category): string {
    switch (category) {
      case "recent-document":
        return "far fa-file-alt fa-fw";
      case "recent-query":
        return "fas fa-history fa-fw";
      case "basket":
        return "fas fa-inbox fa-fw";
      case "saved-query":
        return "far fa-save fa-fw";
    }
    return "far fa-lightbulb fa-fw";
  }

  /**
   * Retrieve autocomplete sources, which include the standard
   */
  get autocompleteSources(): string[] {
    return (this.appService.app?.data?.features as string[]) || FEATURES;
  }

  /**
   * Sets the field search mode
   * event.preventDefault() to avoid the label stealing the focus
   * and closing the autocomplete...
   */
  setMode(mode: "off" | "selects" | "text", event?: Event) {
    event?.preventDefault();
    this.prefs.set("field-search-mode", mode);
  }

  /**
   * Returns the field search mode, stored in user
   * preferences
   */
  getMode(): "off" | "selects" | "text" {
    return this.prefs.get("field-search-mode") || "selects";
  }

  /**
   * Programmatically handle opening/closing of the advanced-search form
   */
  toggleAdvancedSearch(): void {
    this.showAdvancedSearch = !this.showAdvancedSearch;
    this._instantiateAdvancedForm();
  }

  scrollRight() {
    this.timeout = setTimeout(() => {
      this._scrollRight();
    }, 100);
  }

  scrollLeft() {
    this.timeout = setTimeout(() => {
      this._scrollLeft();
    }, 100);
  }

  endScroll() {
    clearTimeout(this.timeout);
  }

  private _scrollRight() {
    this.searchContainer!.nativeElement.scrollLeft += 20;
    this.scrollRight();
  }

  private _scrollLeft() {
    this.searchContainer!.nativeElement.scrollLeft -= 20;
    this.scrollLeft();
  }

  /**
   * Close the advanced-search form if the search input is focused
   */
  onMouseDown(): void {
    this.showAdvancedSearch = false;
  }

  /**
   * Instantiation of the advanced search form and its dependencies/configurations
   */
  private _instantiateAdvancedForm(): void {
    if (!this.initAdvanced) {
      this.firstPageService
        .getFirstPage()
        .pipe(take(1))
        .subscribe(
          () => {},
          () => {},
          () => {
            this.form.addControl(
              "treepath",
              this.advancedService.createSelectControl("treepath")
            );
            this.form.addControl(
              "authors",
              this.advancedService.createSelectControl("authors")
            );
            this.form.addControl(
              "size",
              this.advancedService.createRangeControl("size", [
                this.advancedService.validators.range("size"),
              ])
            );
            this.form.addControl(
              "modified",
              this.advancedService.createRangeControl("modified", [
                this.advancedService.validators.range("modified"),
                this.advancedService.validators.date("modified"),
              ])
            );
            //this.form.addControl('person', this.advancedService.createMultiInputControl('person'));
            this.form.addControl(
              "docformat",
              this.advancedService.createInputControl("docformat")
            );

            this.initAdvanced = true;
          }
        );
    }
  }
}
