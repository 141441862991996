<div class="page-header d-flex flex-column flex-lg-row">
  <div
    class="header-first-div d-flex flex-column flex-lg-row align-items-lg-center align-items-start col-12 col-lg-6"
  >
    <div
      role="button"
      tabindex="0"
      class="back-to-home"
      (click)="goBack()"
      (keyup.enter)="goBack()"
    >
      <img src="assets/img/back-arrow.svg" alt="back arrow" />
      <span>{{ "Back" | uppercase }}</span>
    </div>
    <div class="frequently-ask-question-text">
      <span>FREQUENTLY ASKED QUESTIONS</span>
    </div>
  </div>
  <div class="header-second-div d-flex flex-column flex-lg-row col-12 col-lg-6">
    <div class="input-group">
      <span class="input-group-text border-0" id="search-addon">
        <i class="fa-regular fa-magnifying-glass"></i>
      </span>
      <input
        type="search"
        class="form-control search-question"
        placeholder="Search a question..."
        aria-describedby="search-addon"
        (keyup)="handleInputChange($event)"
        (input)="handleInputClearOnCrossIcon()"
        id="search-input"
        #searchInputRef
      />
    </div>
    <a
      class="api-docu-mobile-view"
      href="https://nasa-impact.github.io/sde-docs/index.html"
      target="_blank"
      role="presentation"
    >
      <div class="api-documentation-button" role="button">
        <span>{{ "API DOCUMENTATION" | uppercase }}</span>
      </div>
    </a>
  </div>
</div>
<div class="container-fluid">
  <div class="row min-vh-100 flex-column flex-md-row">
    <aside class="col-12 col-md-2 col-lg-4 p-0 bg-dark flex-shrink-1">
      <nav
        class="navbar navbar-expand flex-md-column flex-row align-items-start py-2"
        role="presentation"
      >
        <ng-container
          *ngIf="
            originalFaqQuestionsDataForLeftSideQuestions &&
            originalFaqQuestionsDataForLeftSideQuestions.FaqIems
          "
        >
          <div class="collapse navbar-collapse w-100">
            <accordion [closeOthers]="true" class="left-parent-accordion">
              <accordion-group
                id="id-for-left"
                class="left-accordion"
                (click)="onClick('id-for-left')"
                *ngFor="
                  let faqItem of originalFaqQuestionsDataForLeftSideQuestions.FaqIems;
                  let i = index
                "
                style="margin-top: 5px"
              >
                <div
                  (click)="toggleAccordionLeftSide(i)"
                  accordion-heading
                  class="header-div font-family-rajdhani-semibold"
                >
                  <span>{{ faqItem.mainHeading }}</span>
                  <div
                    tabindex="0"
                    style="background: transparent; border: none"
                  >
                    <i
                      class="pull-right float-xs-right glyphicon plus-minus-icon"
                      [ngClass]="{
                        'fas fa-light fa-plus fa-sharp': !accordionStates[i],
                        ' fas fa-light fa-minus': accordionStates[i]
                      }"
                    ></i>
                  </div>
                </div>
                <div>
                  <ul
                    class="font-family-inter-semi-bold"
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: start;
                      gap: 11px;
                    "
                  >
                    <li
                      id="id-for-left"
                      *ngFor="let question of faqItem.faqQuestions"
                    >
                      {{ question.question }}
                    </li>
                  </ul>
                </div>
              </accordion-group>
            </accordion>
          </div>
        </ng-container>
      </nav>
    </aside>
    <main class="col bg-faded py-3 flex-grow-1 col-lg-7 col-12">
      <ng-container *ngIf="faqQuestionsData && faqQuestionsData.FaqIems">
        <div
          *ngFor="
            let mainHeading of faqQuestionsData.FaqIems;
            let mainIndex = index
          "
        >
          <h2 class="mainHeading font-family-rajdhani-semibold">
            {{ mainHeading.mainHeading }}
          </h2>
          <accordion
            [closeOthers]="true"
            style="margin-top: 5px; margin-left: 20px"
            id="id-for-right"
          >
            <accordion-group
              [class.accordion-background]="isAccordionOpen(mainIndex, i)"
              (click)="toggleAccordion(mainIndex, i)"
              class="right-accordion"
              *ngFor="let faq of mainHeading.faqQuestions; let i = index"
            >
              <div
                class="accordion-headers d-flex questions-width col-lg-12 justify-content-lg-between justify-content-between align-items-baseline font-family-inter-semi-bold"
                accordion-heading
              >
                <span>{{ faq.question }}</span>
                <div tabindex="0" (keyup.enter)="toggleAccordion(mainIndex, i)">
                  <i
                    class="pull-right float-xs-right glyphicon toggle-icon"
                    [ngClass]="{
                      'fas fa-solid fa-chevron-up': isAccordionOpen(
                        mainIndex,
                        i
                      ),
                      'fas fa-chevron-down': !isAccordionOpen(mainIndex, i)
                    }"
                  ></i>
                </div>

                <!-- {{ faq.isOpen }} -->
              </div>
              <ng-container
                *ngIf="
                  faq.answer &&
                  !faq.answer.options &&
                  !faq.answer.nestedOptions &&
                  !faq.answer.normalList
                "
              >
                <p
                  class="font-family-public-sans-regular"
                  [innerHTML]="faq.answer"
                ></p>
              </ng-container>
              <ng-container *ngIf="faq.answer.options">
                <ul class="font-family-public-sans-regular">
                  <ng-container
                    *ngFor="
                      let option of faq.answer.options;
                      let isFirst = first;
                      let isLast = last
                    "
                  >
                    <ng-container *ngIf="isFirst; else regularOption">
                      {{ option.list_item }}
                    </ng-container>
                    <ng-template #regularOption>
                      <li>{{ option }}</li>
                      <ng-container *ngIf="!isLast">
                        <p class="or-text">OR</p>
                      </ng-container>
                    </ng-template>
                  </ng-container>
                </ul>
              </ng-container>
              <ng-container *ngIf="faq.answer.nestedOptions">
                <ul class="font-family-public-sans-regular">
                  <ng-container
                    *ngFor="let nestedOption of faq.answer.nestedOptions"
                  >
                    <li
                      class="nestedOptionsList"
                      [innerHTML]="nestedOption.list_item1"
                    ></li>
                    <div [innerHTML]="nestedOption.list_item2.title"></div>

                    <div class="spacing"></div>
                    <ul>
                      <li
                        *ngFor="let point of nestedOption.list_item2.points"
                        [innerHTML]="point"
                      ></li>
                    </ul>
                    <img
                      [ngStyle]="{ width: nestedOption.list_item2.imageWidth }"
                      [src]="nestedOption.list_item2.imageUrl"
                      alt="Image"
                    />
                    <br />
                  </ng-container>
                </ul>
              </ng-container>
              <ng-container *ngIf="faq.answer.normalList">
                <ng-container
                  *ngFor="
                    let option of faq.answer.normalList;
                    let isFirst = first;
                    let isLast = last
                  "
                >
                  <ng-container *ngIf="isFirst; else objectOption">
                    <div
                      class="font-family-public-sans-regular"
                      [innerHTML]="option.list_item1"
                    ></div>
                  </ng-container>
                  <ng-template #objectOption>
                    <li
                      class="font-family-public-sans-regular"
                      [innerHTML]="option"
                    ></li>
                  </ng-template>
                </ng-container>
              </ng-container>

              <div *ngIf="faq.images.length > 0">
                <div *ngFor="let imageUrl of faq.images">
                  <img
                    [src]="imageUrl"
                    [ngStyle]="{ width: faq.imageWidth }"
                    alt="Image"
                  />
                </div>
              </div>
            </accordion-group>
          </accordion>
        </div>
      </ng-container>
    </main>
    <ng-container *ngIf="noResultsFoundPage">
      <div class="centered-main-div">
        <img class="no-data-image" id="logo" src="assets/img/bracets.svg" />
        <div class="no-result-text-one font-family-inter-bold">
          <span>No results found</span>
        </div>
        <div class="no-result-text-two font-family-inter-regular">
          <span class="centered-span"
            >Try searching for different search terms or <br />edit your current
            search query</span
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>
