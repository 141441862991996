<div [ngClass]="{ 'hide-mobile-filter-scroll': hideFilterTab }">
  <app-header
    [showCuratorMenu]="showCurator"
    [welcomeUserText]="userFullName"
  ></app-header>
  <ng-container *ngIf="this.commonService._loadingFlag">
    <div style="display: flex; flex-direction: row">
      <div style="width: 20%; height: 100%">
        <div
          *ngFor="
            let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
          "
          class="shimmer-box-filters shimmer-effect"
        ></div>
        <br />
      </div>
      <div style="width: 80%; height: 100%">
        <div
          *ngFor="
            let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
          "
          class="shimmer-box-results-section shimmer-effect"
        ></div>
        <br />
      </div>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="searchService.totalRowCount > 0"> -->
  <!-- Hide the filters, results when no results -->
  <ng-container *ngIf="results">
    <ng-container
      *ngIf="
        !this.commonService._loadingFlag || searchService.totalRowCount == 0
      "
    >
      <!-- CONTAINER FOR FILTERS TOGGLE AND SORTING WIDGET -->
      <div
        class="filters-toggle-container px-3"
        [ngClass]="{ 'filters-publications': showSciX && !isMobile }"
      >
        <div
          *ngIf="showSciX && !isMobile"
          class="filter-panel-scix font-family-inter-regular"
        >
          <p>
            Currently, there are no filtering options available for publications
            within SDE.
          </p>
        </div>

        <div
          [ngClass]="{
            show: hoverHelpEnabled
          }"
          [hoverHelpEnabled]="hoverHelpEnabled"
          appTooltip="Select the scientific focus area of your search. Only results included in that focus area will appear below.
      "
          joyrideStep="ninethStep"
          [stepContent]="customContentNineth"
          *ngIf="isMobile && !commonService.isInTdammApp()"
          class="sci-focus-group btn-group sde-text font-family-rajdhani-regular"
          dropdown
          #dropdown="bs-dropdown"
          [autoClose]="false"
          (isOpenChange)="dropdownToggled($event)"
        >
          <button
            id="button-basic"
            dropdownToggle
            (outsideClick)="handleOutsideClick()"
            type="button"
            class="sci-focus-btn-closed btn btn-primary"
            [ngClass]="
              isSciFocusDropdownActive
                ? 'sci-focus-btn-opened opened-width'
                : 'sci-focus-border-for-mobile'
            "
            aria-controls="dropdown-basic"
          >
            <img
              src="{{ getIconForScientificFocus(currentSciFocus) }}"
              [ngClass]="
                !isSciFocusDropdownActive
                  ? 'isClosed-spacing'
                  : 'isOpened-spacing'
              "
            />
            <div
              *ngIf="isSciFocusDropdownActive"
              class="selected-sci-focus"
              [ngClass]="{
                'infinite-animate-selected':
                  currentSciFocus === 'Biological & Physical Sciences' ||
                  currentSciFocus === 'Planetary Science'
              }"
            >
              <p>{{ currentSciFocus }}</p>
            </div>
            <i
              *ngIf="isSciFocusDropdownActive"
              [ngClass]="{ 'opened-icon-align': isSciFocusDropdownActive }"
              class="fas down-arrow-icon fa-chevron-down"
            ></i>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="sci-focus-dropdown dropdown-menu"
            [ngClass]="{ 'opened-width': isSciFocusDropdownActive }"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem" *ngFor="let sciFocus of getSciFocusList()">
              <div
                *ngIf="sciFocus.name !== currentSciFocus"
                (click)="selectScientificFocus(sciFocus.name, dropdown)"
                class="sci-focus-wrapper"
                [sqTooltip]="sciFocus.name"
              >
                <div class="sci-img-wrapper">
                  <img [src]="sciFocus.icon" alt="{{ sciFocus.name }}" />
                </div>
                <div
                  class="sci-name-wrapper overflow-hidden infinite-animate"
                  [ngClass]="{
                    'infinite-animate':
                      sciFocus.name === 'Biological & Physical Sciences' ||
                      sciFocus.name === 'Planetary Science'
                  }"
                >
                  <a class="sci-focus-item dropdown-item">
                    {{ sciFocus.name }}
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ng-template #customContentNineth>
          <div class="joyride-step-header">
            <div
              class="joyride-step-title"
              style="color: rgb(2, 61, 139)"
              [ngClass]="{
                'w-75': ui.screenSizeIsLessOrEqual('sm'),
                'w-100': ui.screenSizeIsGreater('sm')
              }"
            >
              3 - SEARCH FOCUS
            </div>
          </div>
          <p class="joyride-content">
            This dropdown menu includes options to filter results by Scientific
            Focus Area (Earth Science, Planetary Science, Astrophysics,
            Biological and Physical Sciences, Heliophysics, or All).
          </p>
        </ng-template>
        <!-- Search form -->

        <app-search-form
          appTooltip="The search bar displays the search query text. Click on the search bar to edit the query or begin a new search.
      "
          [ngClass]="{ show: hoverHelpEnabled }"
          [hoverHelpEnabled]="hoverHelpEnabled"
          joyrideStep="seventhStep"
          [prevTemplate]="prevButton"
          [nextTemplate]="nextButton"
          [stepContent]="customContentSeventh"
          class="flex-grow-1 flex-basis-0 sde-text font-family-rajdhani-regular"
          [ngClass]="{ 'mx-3': !commonService.isInTdammApp() }"
          *ngIf="isMobile"
        ></app-search-form>
        <ng-template #customContentSeventh>
          <div class="joyride-step-header">
            <div
              class="joyride-step-title"
              style="color: rgb(2, 61, 139)"
              [ngClass]="{
                'w-75': ui.screenSizeIsLessOrEqual('sm'),
                'w-100': ui.screenSizeIsGreater('sm')
              }"
            >
              1 - SEARCH BAR
            </div>
          </div>

          <p class="joyride-content">
            The search bar displays the search query text. Click on the search
            bar to edit the query or begin a new search.
          </p>
        </ng-template>
        <ng-template #prevButton>
          <img
            style="cursor: pointer"
            id="close-icon"
            width="45"
            height="45"
            src="assets/img/walkthrough-previous.svg"
          />
        </ng-template>

        <ng-container *ngIf="!showSciX && !isMobile">
          <div class="left-group hide-on-mobile" id="filter-panel">
            <button
              [ngClass]="{ 'hide-click-events': hoverHelpEnabled }"
              [hoverHelpEnabled]="hoverHelpEnabled"
              appTooltip="Open the filter panel to refine search results by platforms, missions, instruments, and more.
              "
              class="btn btn-light filters-toggle font-family-rajdhani-bold sde-text"
              [attr.aria-expanded]="headerSrv.showFilters ? 'true' : 'false'"
              aria-label="Use the filter panel to refine search results by platform, missions, instruments and more."
              joyrideStep="eleventhStep"
              [stepContent]="customContentEleventh"
              (click)="toggleFilters()"
            >
              <i
                *ngIf="this.ui.screenSizeIsLessOrEqual('sm')"
                [ngClass]="
                  isDark()
                    ? 'fa fa-chevron-left px-2'
                    : 'fa fa-chevron-left px-2'
                "
                title="{{ 'msg#search.showFilters' | sqMessage }}"
              ></i>
              <i
                *ngIf="showFilters && !this.ui.screenSizeIsLessOrEqual('sm')"
                [ngClass]="
                  isDark()
                    ? 'fa fa-chevron-left px-2'
                    : 'fa fa-chevron-left px-2'
                "
                title="{{ 'msg#search.hideFilters' | sqMessage }}"
              ></i>
              <i
                *ngIf="!showFilters && !this.ui.screenSizeIsLessOrEqual('sm')"
                [ngClass]="
                  isDark()
                    ? 'fa fa-chevron-right px-2'
                    : 'fa fa-chevron-right px-2'
                "
                title="{{ 'msg#search.showFilters' | sqMessage }}"
              ></i>
              {{ "Filters" | uppercase }}
            </button>
            <ng-template #customContentEleventh>
              <div class="joyride-step-header">
                <div
                  class="joyride-step-title"
                  style="color: rgb(2, 61, 139)"
                  [ngClass]="{
                    'w-75': ui.screenSizeIsLessOrEqual('sm'),
                    'w-100': ui.screenSizeIsGreater('sm')
                  }"
                >
                  {{ getWalkthroughLabelForFilter() }}
                </div>
              </div>

              <p class="joyride-content">
                Click the Filters title to open the filters menu and refine your
                search results .
              </p>
            </ng-template>
            <!-- <app-sde-facet-mysearch
              #facet
              [results]="results"
              [displayFieldNames]="true"
              [ignoreText]="true"
            >
            </app-sde-facet-mysearch> -->
            <ng-container *ngIf="searchService.query.filters">
              <div class="border-left"></div>
              <button
                type="button"
                class="sde-filters-list sde-filter-button sde-filter-button-border font-family-inter-semi-bold"
                (click)="openModal(template)"
              >
                View applied filters
                <!-- {{ this.searchService.query.getFilterCount(undefined) }}
                {{
                  this.searchService.query.getFilterCount(undefined) === 1
                    ? "filter applied"
                    : "filters applied"
                }} -->
              </button>
            </ng-container>

            <ng-template #template>
              <div class="modal-container applied-filters">
                <div class="modal-header filter-head">
                  <div class="filter-heading">
                    <h1
                      class="modal-title pull-left filter-label font-family-rajdhani-regular"
                    >
                      FILTERS
                    </h1>
                    <div
                      class="d-flex flex-row align-items-center justify-content-end"
                    >
                      <span
                        class="sde-filters-list sde-filter-button"
                        role="button"
                        [sqTooltip]="'msg#filters.clearTitle' | sqMessage"
                        (click)="clearFilters()"
                      >
                        <span class="font-family-inter-bold"
                          >CLEAR ALL FILTERS</span
                        >
                      </span>
                      <button
                        type="button"
                        class="filter-close-button"
                        aria-label="Close"
                        (click)="modalRef?.hide()"
                      >
                        <img
                          src="assets/img/filters-close-icon.svg"
                          alt="Close filter popup"
                        />
                      </button>
                    </div>
                  </div>

                  <div class="filter-info font-family-public-sans-regular">
                    These filters have been applied to your search results:
                  </div>
                </div>
                <div class="modal-body">
                  <app-sde-applied-filters
                    *ngIf="searchService.query.filters"
                    [query]="searchService.query"
                    [closeOnClickOutside]="true"
                    [advancedModeClass]="
                      'position-absolute card border shadow p-3'
                    "
                    (filterEdit)="searchService.search()"
                  >
                  </app-sde-applied-filters>
                </div>
              </div>
            </ng-template>

            <span
              *ngIf="filterCount !== 0"
              class="sde-filters-list-outer"
              role="button"
              [sqTooltip]="'msg#filters.clearTitle' | sqMessage"
              (click)="clearFilters()"
            >
              <span class="font-family-inter-bold">CLEAR ALL FILTERS</span>
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- Tabs -->
    <div class="container-fluid main">
      <div
        joyrideStep="firstStepForTdammResults"
        [nextTemplate]="nextButton"
        [stepContent]="introTemplateResults"
      ></div>
      <div class="d-flex">
        <div
          #filtersBlock
          scroll-adjust
          id="leftFilter"
          class="left-filter"
          [ngClass]="{ 'filter-width-mobileView': isMobile }"
          *ngIf="this.headerSrv.showFilters && !isMobile"
        >
          <div id="search-results">
            <app-search-facets
              *ngIf="hasFacets"
              [results]="results"
            ></app-search-facets>
          </div>
        </div>
        <div
          scroll-adjust
          id="leftFilter"
          class="left-filter"
          [ngClass]="{ 'filter-width-mobileView': isMobile }"
          *ngIf="isMobile && hideFilterTab"
        >
          <div
            class="filter-label-mobile font-family-rajdhani-bold"
            *ngIf="isMobile"
          >
            <i class="fa-solid fa-filter" style="margin-right: 10px"></i>
            <span>{{ "Filters" | uppercase }}</span>
            <!-- {{ filterCount > 0 }} | {{ isMobile }} -->

            <i
              (click)="toggleFiltersForMobile()"
              class="fa-regular fa-xmark close-filter-icon"
            ></i>
          </div>
          <div
            id="search-results"
            *ngIf="searchService.query.filters && isMobile"
          >
            <app-sde-facet-card
              [title]="'Applied Filters'"
              [type]="'applied-filters'"
              [collapsible]="true"
              [startCollapsed]="true"
              ><div class="applied-filter-open">
                <app-sde-applied-filters
                  *ngIf="searchService.query.filters"
                  [query]="searchService.query"
                  [closeOnClickOutside]="true"
                  [advancedModeClass]="
                    'position-absolute card border shadow p-3'
                  "
                  (filterEdit)="searchService.search()"
                >
                </app-sde-applied-filters>
              </div>
            </app-sde-facet-card>
          </div>

          <div id="search-results">
            <app-search-facets
              *ngIf="hasFacets"
              [results]="results"
            ></app-search-facets>
          </div>
        </div>

        <div
          #resultsEl
          [ngClass]="{
            'result-cards-container-noscroll':
              (openedDoc && !isMobile) || (showSciXMetadata && !isMobile),

            'result-cards-container': ui.screenSizeIsGreater('sm'),
            'result-cards-container-mobile': ui.screenSizeIsLessOrEqual('xs')
          }"
        >
          <div
            class="tabs-container navbar-sde-tabs"
            #tabsEl
            joyrideStep="twelveStep"
            [stepContent]="customContentTwelveth"
          >
            <app-sde-tabs
              [sciXCount]="sciXCount"
              [searchText]="searchText"
              [results]="results"
              (events)="handleTabChange($event)"
            ></app-sde-tabs>
          </div>

          <div class="container-fluid main">
            <div class="d-flex">
              <div
                id="results-page"
                class="result-cards-container"
                [ngClass]="{
                  'result-cards-container':
                    !this.openedDoc && !this.showSciXMetadata,
                  'result-cards-container-noscroll width-30':
                    this.openedDoc || this.showSciXMetadata,
                  'scix-mobile-view': this.showSciXMetadata && this.isMobile
                }"
              >
                <div class="sub-toolbar">
                  <!-- Clear all filters applied -->
                </div>
                <app-search-visualization
                  *ngIf="hasVisualization"
                  [results]="results"
                ></app-search-visualization>

                <ng-container *ngIf="!showSciX && isDidYouMean">
                  <app-sde-did-you-mean
                    [spellingCorrectionMode]="'DYMOnly'"
                    [results]="didYouMeanObj"
                  ></app-sde-did-you-mean>
                </ng-container>

                <ng-template #customContentTwelveth>
                  <div class="joyride-step-header">
                    <div
                      class="joyride-step-title"
                      style="color: rgb(2, 61, 139)"
                      [ngClass]="{
                        'w-75': ui.screenSizeIsLessOrEqual('sm'),
                        'w-100': ui.screenSizeIsGreater('sm')
                      }"
                    >
                      {{ getWalkthroughLabel() }}
                    </div>
                  </div>
                  <p class="joyride-content">
                    Switch among these tabs to view particular types of search
                    results.
                  </p>
                </ng-template>
                <!-- Toolbar -->
                <div
                  class="d-flex flex-row align-items-center justify-content-between"
                >
                  <span
                    *ngIf="hasBadges"
                    class="btn btn-group badge-toggle btn-{{
                      isDark() ? 'dark' : 'light'
                    }} {{ this.hasBadges ? 'fas fa-eye-slash' : 'fas fa-eye' }}"
                    [title]="this.hasBadges ? 'Hide Badges' : 'Show Badges'"
                    (click)="toggleHideBadges($event)"
                  >
                  </span>
                  <span
                    *ngIf="hasRedirect"
                    class=" btn redirect-button btn-{{
                      isDark() ? 'dark' : 'light'
                    }} fas fa-backward"
                    title="{{ 'msg#general.redirectToParent' | sqMessage }}"
                    (click)="goBack($event)"
                  >
                  </span>
                </div>

                <!-- Sponsored links -->
                <div *ngIf="!showSciX && searchService.rowCount > 0">
                  <app-sde-sponsored-links
                    [query]="searchService.query"
                  ></app-sde-sponsored-links>
                </div>

                <ng-container *ngIf="commonService.resultsCardLoader">
                  <div
                    *ngFor="
                      let item of [
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
                      ]
                    "
                    class="shimmer-box-cards shimmer-effect"
                  ></div>
                  <br />
                </ng-container>
                <div style="z-index: 1">
                  <ng-container
                    *ngIf="!commonService.resultsCardLoader && !showSciX"
                  >
                    <div
                      *ngFor="let record of results.records; let index = index"
                      class="record d-flex"
                      [joyrideStep]="index == 0 ? 'thirteenStep' : 'null'"
                      [stepContent]="customContentThirteen"
                      [stepPosition]="'top'"
                      (click)="onDocumentClicked(record, $event)"
                      [ngClass]="{
                        selected: record.$selected || record === openedDoc,
                        'not-selected': openedDoc && record !== openedDoc
                      }"
                      [title]="
                        noCachedFlag(record, 'Open in Document Navigator')
                      "
                      [hoverHelpEnabled]="hoverHelpEnabled"
                      [appTooltip]="
                        index === 0
                          ? 'Search results are displayed as cards with basic metadata. Click on a card title to open a search result in preview mode and view more information.'
                          : ''
                      "
                    >
                      <!-- Hide result card actionable in mobile view using ui.screenSizeIsLessOrEqual('sm') -->
                      <ng-container *ngIf="!ui.screenSizeIsLessOrEqual('sm')">
                        <button
                          *ngIf="record !== openedDoc"
                          id="openOriginalDocument"
                          (click)="expandDocumentPreview(record, $event)"
                          class="position-for-full-page-icon"
                          title="{{
                            'msg#general.openInDocumentNavigator' | sqMessage
                          }}"
                        >
                          <img
                            src="assets/img/expand.svg"
                            class="preview-icon"
                            alt="expand_doc"
                          />
                        </button>
                        <!-- TO DO: Share Functionality to be added -->
                        <button
                          *ngIf="record !== openedDoc"
                          id="openOriginalDocument"
                          class="position-for-send-icon original-doc-btn"
                          title="{{
                            'msg#general.copyLinkToDocument' | sqMessage
                          }}"
                          (click)="copyURLDocumentPreview(record, $event)"
                        >
                          <img
                            src="assets/img/copy.svg"
                            class="preview-icon"
                            alt="send_doc"
                          />
                        </button>
                      </ng-container>

                      <div
                        class="flex-grow-1 w-75 ml-0"
                        tabindex="0"
                        role="region"
                        aria-label="click the card title to open a search results in preview mode and view more information."
                      >
                        <!--  GitHub Issue #875 - Max-Width Removed  -->
                        <div
                          class="d-flex result-title-main-div"
                          [ngClass]="{
                            'result-title-astro align-items-start':
                              record?.sourcetree1 ||
                              record?.sourcetree2 ||
                              record?.sourcetree3
                          }"
                          (click)="onDocumentClicked(record, $event)"
                        >
                          <!-- Title -->
                          <app-sde-result-title
                            [record]="record"
                            class="flex-grow-1 mw-75 result-card-title me-4"
                            (titleClicked)="onDocumentClicked(record, $event)"
                          >
                          </app-sde-result-title>

                          <ng-container
                            *ngIf="
                              record?.sourcetree1 ||
                              record?.sourcetree2 ||
                              record?.sourcetree3
                            "
                          >
                            <div
                              class="astro-chips-container"
                              [ngClass]="{ 'astro-preview-open': openedDoc }"
                              [joyrideStep]="
                                index == 1 ? 'tdammTagStep' : 'null'
                              "
                              [stepContent]="tdammtagStepContent"
                            >
                              <ng-container
                                *ngFor="
                                  let chip of record?.sourcetree1;
                                  let i = index
                                "
                              >
                                <div
                                  *ngIf="i < 1"
                                  class="astro-chips font-family-rajdhani-medium"
                                  [ngClass]="{
                                    'chips-bg-messenger':
                                      record.sourcetree1?.includes(chip)
                                  }"
                                  (click)="
                                    openAstroTagsModal(astroTags);
                                    $event.stopPropagation()
                                  "
                                >
                                  <span class="font-family-inter-extrabold"
                                    >MESSENGERS</span
                                  >
                                  <span
                                    class="messenger-chips-border"
                                    *ngIf="record.sourcetree1?.includes(chip)"
                                    [innerHTML]="getAstroTitleTags(chip)"
                                  ></span>
                                  <span
                                    class="messenger-chips-border"
                                    *ngIf="record.tdammMessenger?.length > 1"
                                    >+{{ record.sourcetree1?.length - 1 }}</span
                                  >
                                </div>
                              </ng-container>
                              <ng-container
                                *ngFor="
                                  let chip of record?.sourcetree2;
                                  let i = index
                                "
                              >
                                <div
                                  *ngIf="i < 1"
                                  class="astro-chips font-family-rajdhani-medium"
                                  [ngClass]="{
                                    'chips-bg-object':
                                      record.sourcetree2?.includes(chip)
                                  }"
                                  (click)="
                                    openAstroTagsModal(astroTags);
                                    $event.stopPropagation()
                                  "
                                >
                                  <span class="font-family-inter-extrabold"
                                    >OBJECT</span
                                  >
                                  <span
                                    class="object-chips-border"
                                    *ngIf="record.sourcetree2?.includes(chip)"
                                    [innerHTML]="getAstroTitleTags(chip)"
                                  ></span>
                                  <span
                                    class="object-chips-border"
                                    *ngIf="record.sourcetree2?.length > 1"
                                    >+{{ record.sourcetree2?.length - 1 }}</span
                                  >
                                </div>
                              </ng-container>
                              <ng-container
                                *ngFor="
                                  let chip of record?.sourcetree3;
                                  let i = index
                                "
                              >
                                <div
                                  *ngIf="i < 1"
                                  class="astro-chips font-family-rajdhani-medium"
                                  [ngClass]="{
                                    'chips-bg-signals':
                                      record.sourcetree3?.includes(chip)
                                  }"
                                  (click)="
                                    openAstroTagsModal(astroTags);
                                    $event.stopPropagation()
                                  "
                                >
                                  <span class="font-family-inter-extrabold"
                                    >SIGNALS</span
                                  >
                                  <span
                                    class="signal-chips-border"
                                    *ngIf="record.sourcetree3?.includes(chip)"
                                    [innerHTML]="getAstroTitleTags(chip)"
                                  ></span>
                                  <span
                                    class="signal-chips-border"
                                    *ngIf="record.sourcetree3?.length > 1"
                                    >+{{ record.sourcetree3?.length - 1 }}</span
                                  >
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>

                          <ng-template #astroTags>
                            <div
                              [ngClass]="{
                                'custom-modal': ui.screenSizeIsGreater('sm'),
                                'custom-modal-mobile':
                                  ui.screenSizeIsLessOrEqual('xs')
                              }"
                            >
                              <div class="modal-header">
                                <button
                                  *ngIf="ui.screenSizeIsGreater('sm')"
                                  type="button"
                                  class="btn-close close pull-right"
                                  aria-label="Close"
                                  (click)="modalRef?.hide()"
                                >
                                  <span
                                    aria-hidden="true"
                                    class="visually-hidden"
                                    >&times;</span
                                  >
                                </button>
                                <img
                                  *ngIf="ui.screenSizeIsLessOrEqual('xs')"
                                  (click)="modalRef?.hide()"
                                  src="assets/img/astro-tag-close.svg"
                                  alt="close-icon"
                                />
                              </div>

                              <div class="modal-body">
                                <ng-container *ngIf="record?.sourcetree1">
                                  <div
                                    class="tdamm-dialog-container chips-bg-messenger font-family-rajdhani-semibold"
                                  >
                                    <h1 class="horizontal-line">MESSENGER</h1>
                                    <div class="astro-tags-dialog-section">
                                      <ng-container
                                        *ngFor="
                                          let parent of getObjectKeys(
                                            groupItems(record?.sourcetree1)
                                          );
                                          let isLastParent = last
                                        "
                                      >
                                        <ng-container
                                          *ngIf="
                                            groupItems(record.sourcetree1)[
                                              parent
                                            ].length === 0
                                          "
                                        >
                                          <div
                                            class="font-family-inter-regular chips-bg-messenger astro-tags-dialog"
                                          >
                                            <div
                                              [innerHTML]="
                                                getAstroTags(parent, null)
                                              "
                                            ></div>
                                          </div>
                                        </ng-container>
                                        <ng-container
                                          *ngFor="
                                            let child of groupItems(
                                              record?.sourcetree1
                                            )[parent];
                                            let isLastChild = last
                                          "
                                        >
                                          <ng-container
                                            *ngIf="
                                              groupItems(record.sourcetree1)[
                                                parent
                                              ].length > 0
                                            "
                                          >
                                            <div
                                              class="font-family-inter-regular chips-bg-messenger astro-tags-dialog"
                                            >
                                              <div
                                                [innerHTML]="
                                                  getAstroTags(parent, child)
                                                "
                                              ></div>
                                            </div>
                                          </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="!isLastParent">
                                          <img
                                            class="astro-dialog-vector"
                                            src="assets/img/astro-tag-pipe.svg"
                                            alt=""
                                          />
                                        </ng-container>
                                      </ng-container>
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="record?.sourcetree2">
                                  <div
                                    class="tdamm-dialog-container chips-bg-object font-family-rajdhani-semibold"
                                  >
                                    <h1 class="horizontal-line">OBJECTS</h1>
                                    <div class="astro-tags-dialog-section">
                                      <ng-container
                                        *ngFor="
                                          let parent of getObjectKeys(
                                            groupItems(record?.sourcetree2)
                                          );
                                          let isLastParent = last
                                        "
                                      >
                                        <ng-container
                                          *ngIf="
                                            groupItems(record.sourcetree2)[
                                              parent
                                            ].length === 0
                                          "
                                        >
                                          <div
                                            class="font-family-inter-regular chips-bg-object astro-tags-dialog"
                                          >
                                            <div
                                              [innerHTML]="
                                                getAstroTags(parent, null)
                                              "
                                            ></div>
                                          </div>
                                        </ng-container>
                                        <ng-container
                                          *ngFor="
                                            let child of groupItems(
                                              record?.sourcetree2
                                            )[parent];
                                            let isLastChild = last
                                          "
                                        >
                                          <ng-container
                                            *ngIf="
                                              groupItems(record.sourcetree2)[
                                                parent
                                              ].length > 0
                                            "
                                          >
                                            <div
                                              class="font-family-inter-regular chips-bg-object astro-tags-dialog"
                                            >
                                              <div
                                                [innerHTML]="
                                                  getAstroTags(parent, child)
                                                "
                                              ></div>
                                            </div>
                                          </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="!isLastParent">
                                          <img
                                            class="astro-dialog-vector"
                                            src="assets/img/astro-tag-pipe.svg"
                                            alt=""
                                          />
                                        </ng-container>
                                      </ng-container>
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="record?.sourcetree3">
                                  <div
                                    class="tdamm-dialog-container chips-bg-signals font-family-rajdhani-semibold"
                                  >
                                    <h1 class="horizontal-line">SIGNALS</h1>
                                    <div class="astro-tags-dialog-section">
                                      <ng-container
                                        *ngFor="
                                          let parent of getObjectKeys(
                                            groupItems(record?.sourcetree3)
                                          );
                                          let isLastParent = last
                                        "
                                      >
                                        <ng-container
                                          *ngIf="
                                            groupItems(record.sourcetree3)[
                                              parent
                                            ].length === 0
                                          "
                                        >
                                          <div
                                            class="font-family-inter-regular chips-bg-signals astro-tags-dialog"
                                          >
                                            <div
                                              [innerHTML]="
                                                getAstroTags(parent, null)
                                              "
                                            ></div>
                                          </div>
                                        </ng-container>
                                        <ng-container
                                          *ngFor="
                                            let child of groupItems(
                                              record?.sourcetree3
                                            )[parent];
                                            let isLastChild = last
                                          "
                                        >
                                          <ng-container
                                            *ngIf="
                                              groupItems(record.sourcetree3)[
                                                parent
                                              ].length > 0
                                            "
                                          >
                                            <div
                                              class="font-family-inter-regular chips-bg-signals astro-tags-dialog"
                                            >
                                              <div
                                                [innerHTML]="
                                                  getAstroTags(parent, child)
                                                "
                                              ></div>
                                            </div>
                                          </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="!isLastParent">
                                          <img
                                            class="astro-dialog-vector"
                                            src="assets/img/astro-tag-pipe.svg"
                                            alt=""
                                          />
                                        </ng-container>
                                      </ng-container>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </ng-template>

                          <app-search-action-menu
                            id="dropdownMenuDiv"
                            class="dropdown action-menu ml-auto"
                            *ngIf="hasActionMenu"
                            [record]="record"
                          ></app-search-action-menu>
                        </div>
                        <!-- Metadata -->
                        <div>
                          <table>
                            <tr>
                              <td>
                                <div class="d-flex" aria-hidden="true">
                                  <app-sde-result-source
                                    class="flex-grow-1 w-75 sq-result-source"
                                    [record]="record"
                                    [displayTreepath]="true"
                                    title="{{ this.record.sourcestr56 }}"
                                  >
                                  </app-sde-result-source>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <sq-result-extracts
                          *ngIf="this.record.extracts"
                          [record]="record"
                        ></sq-result-extracts>
                        <sq-result-missing-terms
                          [record]="record"
                        ></sq-result-missing-terms>
                        <app-search-badges
                          *ngIf="hasBadges"
                          [record]="record"
                          (onLoading)="setLoadingFromChild($event)"
                        ></app-search-badges>
                        <div class="d-inline">
                          <sq-labels
                            [record]="record"
                            [public]="true"
                            class="d-inline"
                            (click)="DoNotPropagateClickEvent($event)"
                          ></sq-labels>
                          <sq-labels
                            [record]="record"
                            [public]="false"
                            class="d-inline"
                            (click)="DoNotPropagateClickEvent($event)"
                          ></sq-labels>
                        </div>
                        <sq-facet-card
                          *ngIf="hasPropertyDetails"
                          title=""
                          [title]="'msg#documentDetails.title'"
                          [icon]="'fas fa-book'"
                          [collapsible]="true"
                          [startCollapsed]="true"
                          class="d-block mt-2"
                          (click)="DoNotPropagateClickEvent($event)"
                        >
                          <sq-facet-list
                            #facet
                            [searchable]="false"
                          ></sq-facet-list>
                          <!-- This is a dummy facet to get rid of a warning from sinequa layer -->
                          <app-search-document-details
                            [record]="record"
                          ></app-search-document-details>
                        </sq-facet-card>
                      </div>
                    </div>

                    <ng-container
                      *ngIf="
                        !hideFilterTab &&
                        searchService.rowCount == 0 &&
                        results.cursorRowCount == 0 &&
                        !didYouMeanObj &&
                        !this.commonService._loadingFlag &&
                        !commonService.resultsCardLoader
                      "
                    >
                      <div class="centered-main-div">
                        <img
                          class="no-data-image"
                          id="logo"
                          src="assets/img/bracets.svg"
                        />
                        <div class="no-result-text-one">
                          <span>No results found</span>
                        </div>
                        <div class="no-result-text-two">
                          <span class="centered-span"
                            >Try searching for different search terms or
                            <br />edit your current search query</span
                          >
                        </div>
                        <div class="did-you-mean-button-container">
                          <button
                            (click)="navigateToContactPage({ source: 'SDE' })"
                            class="did-you-mean-button"
                          >
                            Submit Feedback
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      showSciX &&
                      !commonService.resultsCardLoader &&
                      searchText.trim() !== ''
                    "
                  >
                    <div
                      class="scix-info-container py-3 px-4"
                      *ngIf="sciXData && sciXCount !== 0"
                    >
                      <div
                        class="font-family-rajdhani-bold scix-powered-text pb-3"
                      >
                        Publications on Science Discovery Engine are powered by
                        the NASA Science Explorer (SciX)
                      </div>

                      <div class="scix-link-container">
                        <img
                          src="assets/img/scix-link.svg"
                          alt="Image Alt Text"
                          width="20"
                          height="20"
                        />
                        <a
                          class="font-family-rajdhani-medium scix-link"
                          href="https://scixplorer.org/"
                          target="_blank"
                          >Explore SciX</a
                        >
                      </div>
                    </div>
                    <div
                      class="record d-flex"
                      *ngFor="let doc of sciXData; let i = index"
                      (click)="openScixMetadataViewer(doc, i)"
                      [class.selected]="selectedScixIndex === i"
                      [stepPosition]="'top'"
                      [joyrideStep]="i == 0 ? 'thirteenStep' : 'null'"
                      [stepContent]="customContentThirteen"
                      [hoverHelpEnabled]="hoverHelpEnabled"
                      [appTooltip]="
                        i === 0
                          ? 'Search results are displayed as cards with basic metadata. Click on a card title to open a search result in preview mode and view more information.'
                          : ''
                      "
                    >
                      <div class="flex-grow-1 w-75 ml-0">
                        <!--  GitHub Issue #875 - Max-Width Removed  -->
                        <div
                          class="d-flex align-items-center result-title-main-div"
                        >
                          <p
                            class="scix-title font-family-rajdhani-bold"
                            [appMathJax]="doc.title"
                          ></p>
                        </div>

                        <div
                          class="sq-relevant-extracts sq-text ng-star-inserted font-family-public-sans-regular"
                        >
                          <p
                            class="scix-desc"
                            [appMathJax]="doc.abstract"
                            *ngIf="doc.abstract"
                          ></p>
                          <p class="scix-desc" *ngIf="!doc.abstract">
                            No Abstract
                          </p>
                        </div>
                        <span
                          class="extracts-date-scix font-family-inter-regular"
                          >Published On {{ doc.date | sqDate : dateFormat }}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !this.commonService._loadingFlag &&
                      !commonService.resultsCardLoader &&
                      (sciXCount == 0 || !searchText) &&
                      tabName == 'Publications'
                    "
                  >
                    <div class="centered-main-div">
                      <img
                        class="no-data-image"
                        id="logo"
                        src="assets/img/bracets.svg"
                      />
                      <div class="no-result-text-one" *ngIf="searchText">
                        <span>No results found</span>
                      </div>
                      <div class="no-result-text-two" *ngIf="searchText">
                        <span class="centered-span"
                          >Try searching for different search terms or
                          <br />edit your current search query</span
                        >
                      </div>
                      <div class="no-result-text-two" *ngIf="!searchText">
                        <span class="centered-span">
                          Searching without a term is not valid for
                          publications.
                          <br />
                          Please try again with a search term
                        </span>
                      </div>
                      <div class="did-you-mean-button-container">
                        <button
                          (click)="navigateToContactPage({ source: 'SDE' })"
                          class="did-you-mean-button"
                        >
                          Submit Feedback
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <div
                    class="row d-flex"
                    *ngIf="
                      showSciX && sciXCount !== 0 && searchText.trim() !== ''
                    "
                  >
                    <div class="col-xs-12 col-12">
                      <pagination
                        previousText="&lsaquo;"
                        nextText="&rsaquo;"
                        firstText="&laquo;"
                        lastText="&raquo;"
                        [boundaryLinks]="showBoundaryLinks"
                        [itemsPerPage]="itemsPerPage"
                        [totalItems]="sciXCount"
                        [maxSize]="5"
                        (pageChanged)="pageChangedForScix($event)"
                      ></pagination>
                    </div>
                  </div>
                </div>

                <!-- Pager -->
                <app-sde-pager
                  [results]="results"
                  class="d-flex flex-column align-items-center mt-3"
                  (click)="onPaginate()"
                >
                </app-sde-pager>
              </div>

              <div
                #shortPreview
                id="shortPreview"
                *ngIf="showShortPreview || showSciXMetadata"
                [ngClass]="getDocumentPreviewStyles()"
              >
                <!-- Scaled-down preview -->
                <app-sde-facet-card
                  *ngIf="openedDoc"
                  [type]="
                    openedDoc.isMetadataViewer ? 'metadata-viewer' : 'preview'
                  "
                  [record]="openedDoc"
                  [collapsible]="false"
                  [buttonsStyle]="isDark() ? 'dark' : 'light'"
                  [actionsSize]="''"
                  [ngStyle]="{
                    height: getHeight(),
                    width: 'inherit !important'
                  }"
                  (closeDocument)="closeDocument()"
                  (expandPreview)="expandDocument()"
                  (toggleEntities)="toggleEntityHighlighting($event)"
                  (toggleExtracts)="toggleExtractsHighlighting($event)"
                  (copyLinkToDocument)="copyURLDocumentPreview(openedDoc, true)"
                  (shareDocument)="shareDocument(openedDoc)"
                  (downloadDocument)="downloadDocument(openedDoc)"
                >
                  <app-sde-preview
                    #sdePreview
                    [ngStyle]="previewStyle"
                    [id]="openedDoc.id"
                    [highlightColors]="previewHighlights"
                    [highlightEntities]="entityHighlightFlag"
                    [highlightExtracts]="extractsHighlightFlag"
                    [preferenceName]="'mini-preview'"
                    [scale]="0.7"
                  >
                  </app-sde-preview>
                </app-sde-facet-card>

                <app-scix-metadata-viewer
                  class="scix-container"
                  *ngIf="showSciXMetadata"
                  [scixMetaData]="selectedSciXMetadata"
                  (close)="closeScixMetadataViewer()"
                ></app-scix-metadata-viewer>
              </div>
            </div>
          </div>
        </div>

        <!-- CONTAINER FOR FACETS AND RESULT CARS, TABS  -->
      </div>
    </div>
  </ng-container>
  <!-- </ng-container> -->

  <!-- Filter icon for mobile -->

  <div
    *ngIf="isMobile && !hideFilterTab && !showSciX"
    (click)="toggleFiltersForMobile()"
    class="filter-for-mobile font-family-rajdhani-bold"
    joyrideStep="mobileFilterStep"
    [stepContent]="mobileFilterContent"
  >
    <i class="fa-regular fa-filter mobile-filter-icon"></i>
    <span>FILTERS</span>
  </div>
  <div
    *ngIf="showSciX && isMobile && !showSciXMetadata"
    class="filter-for-mobile-scix font-family-inter-regular"
  >
    <p class="scix-filters-text">
      Currently, there are no filtering options available for publications
      within SDE.
    </p>
  </div>

  <ng-template #mobileFilterContent>
    <div class="joyride-step-header">
      <div
        class="joyride-step-title"
        style="color: rgb(2, 61, 139)"
        [ngClass]="{
          'w-75': ui.screenSizeIsLessOrEqual('sm'),
          'w-100': ui.screenSizeIsGreater('sm')
        }"
      >
        {{ getWalkthroughLabelForFilterMobile() }}
      </div>
    </div>

    <p class="joyride-content">
      Click the Filters title to open the filters menu and refine your search
      results .
    </p>
  </ng-template>
</div>
<div
  style="text-align: end; position: absolute; bottom: 0px; right: 0px"
  *ngIf="hoverHelpEnabled"
>
  <button
    class="font-family-rajdhani-medium"
    style="
      background: #fff7ad;
      border: none;
      text-transform: uppercase;
      padding: 8px;
    "
    (click)="stopHoverHelpFeature()"
  >
    <img
      id="close-icon"
      class="close-popup"
      src="assets/img/cross-hover-help.svg"
    />
    CLICK HERE TO EXIT HOVER HELP
  </button>
</div>

<ng-template #tdammtagStepContent>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title"
      style="color: rgb(2, 61, 139)"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      {{
        ui.screenSizeIsLessOrEqual("xs") ? "6 - TDAMM TAGS" : "7 - TDAMM TAGS"
      }}
    </div>
  </div>

  <p class="joyride-content">
    Tags above each search result identify messenger, object and signal types
    and subtypes that correspond to the result.
  </p>
  <p class="joyride-content">
    Clicking on any TDAMM tag opens a window with more detailed information
    about the tag categories and subcategories.
  </p>
</ng-template>

<ng-template #customContentThirteen>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title"
      style="color: rgb(2, 61, 139)"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      {{ getWalkthroughLabelForResultCard() }}
    </div>
  </div>

  <p class="joyride-content">
    Search results are displayed as cards with basic metadata. Click on a card
    title to open a search result in preview mode.
  </p>
</ng-template>

<ng-template #introTemplateResults>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title walkThrough-title"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      TDAMM RESULTS PAGE WALKTHROUGH
    </div>
  </div>
  <p class="joyride-content">
    Welcome to the NASA Time Domain and Multi Messenger Astronomy Interface
    (powered by the Science Discovery Engine).
  </p>
  <p class="joyride-content">
    This walkthrough will guide you through the features of the interface step
    by step.
  </p>
  <p class="joyride-content">
    A walkthrough is available for each page of the Search interface. You can
    access the walkthroughs by clicking the Help button in the top right area of
    the pages.
  </p>
  <p class="joyride-content">
    You can go forward using the ‘Next’ button or go back to the step before
    using the ‘Previous’ button.
  </p>
  <p class="joyride-content">
    You can skip the tutorial by clicking the X on the top right corner.
  </p>
</ng-template>

<ng-template #nextButton>
  <img
    style="cursor: pointer"
    id="close-icon"
    width="45"
    height="45"
    src="assets/img/walkthrough-next.svg"
  />
</ng-template>
