<div
  #scixPreview
  *ngIf="scixMetaData"
  class="container-fluid"
  id="scixPreview-id"
>
  <!-- Title and download button -->

  <div class="top-section px-4">
    <div
      [appMathJax]="scixMetaData.title"
      class="preview-not-expanded mt-3 mb-3 record-name font-family-rajdhani-bold"
      [sqTooltip]="scixMetaData.title"
    ></div>

    <div class="mt-3 mb-3 record-download-box">
      <div>
        <img
          (click)="openScixSourceLink(scixMetaData.bibcode)"
          [sqTooltip]="'msg#general.openOriginalDocument' | sqMessage"
          src="assets/img/source-doc-1.svg"
          class="preview-icon cursor mx-2"
          alt="go_to_source"
        />
      </div>
      <div>
        <img
          (click)="copyURLSciX()"
          [sqTooltip]="'msg#general.copyLinkToDocument' | sqMessage"
          src="assets/img/copy.svg"
          class="preview-icon cursor mx-2"
          alt="go_to_source"
        />
      </div>
      <div>
        <img
          (click)="closeScixMetaDataViewer()"
          [sqTooltip]="'msg#general.closeDocumentPreview' | sqMessage"
          src="assets/img/close.svg"
          class="preview-icon cursor mx-2"
          alt="go_to_source"
        />
      </div>
    </div>
  </div>

  <!-- Metadata Viewer Tabs-->
  <div class="row scix-content px-4" #scixdataContent>
    <div class="mb-4 first-item">
      <div class="mt-2 px-2 py-2 right-item">
        <div class="title font-family-rajdhani-bold">Publication Date</div>
        <div
          class="content new-line-for-delimiter-items font-family-inter-light"
        >
          {{ scixMetaData.pubdate ? scixMetaData.pubdate : "-" }}
        </div>
      </div>
      <div class="mt-2 px-2 py-2 right-item">
        <div class="title font-family-rajdhani-bold">Publisher</div>
        <div class="content font-family-inter-light">
          {{ scixMetaData.pub ? scixMetaData.pub : "-" }}
        </div>
      </div>
      <div class="mt-2 px-2 py-2 right-item">
        <div class="title font-family-rajdhani-bold">Publication</div>
        <div class="content font-family-inter-light">
          {{ scixMetaData.pub ? scixMetaData.pub : "-" }}
          <ng-container *ngIf="scixMetaData.volume">
            , Volume {{ scixMetaData.volume }}
          </ng-container>
          <ng-container *ngIf="scixMetaData.issue">
            , Issue {{ scixMetaData.issue }}
          </ng-container>
        </div>
      </div>
    </div>

    <div #rightSection class="mx-1 right-section">
      <div class="left-item px-2 py-2" id="rightSection-id" #rightSectionTitle>
        <div class="title font-family-rajdhani-bold">Description</div>

        <div
          *ngIf="scixMetaData.abstract"
          #rightSectionContent
          class="content font-family-public-sans-regular pb-2"
          [appMathJax]="scixMetaData.abstract"
          [ngStyle]="{
            height: isCollapsed ? '224px' : 'auto',
            overflow: isCollapsed ? 'hidden' : 'visible'
          }"
        ></div>
        <div
          *ngIf="!scixMetaData.abstract"
          #rightSectionContent
          class="content font-family-public-sans-regular pb-2"
          [ngStyle]="{
            height: isCollapsed ? '224px' : 'auto',
            overflow: isCollapsed ? 'hidden' : 'visible'
          }"
        >
          No abstract
        </div>

        <!-- Read More/Read Less Button -->
        <div
          *ngIf="showReadMoreButton && scixMetaData.abstract"
          class="read-more-section"
        >
          <p class="read-more" (click)="toggleContent()">
            {{ isCollapsed ? "...Read More" : "Read Less" }}
          </p>
        </div>
      </div>
    </div>
    <div class="mb-4 first-item">
      <div class="px-2 py-2 right-item">
        <div class="title font-family-rajdhani-bold">Author(s)</div>
        <div
          class="author-list-container"
          *ngIf="scixMetaData.author && scixMetaData.author.length > 0"
        >
          <div *ngIf="!isMobile">
            <ul class="author-list">
              <li
                class="content-list font-family-inter-light"
                *ngFor="let item of scixMetaData.author"
              >
                {{ item }}
              </li>
            </ul>
          </div>

          <div *ngIf="isMobile">
            <ul>
              <li
                class="content-list font-family-inter-light"
                *ngFor="
                  let item of showAllAuthors
                    ? scixMetaData.author
                    : scixMetaData.author.slice(0, 2)
                "
              >
                {{ item }}
              </li>
            </ul>
            <button
              *ngIf="scixMetaData.author && scixMetaData.author.length > 2"
              (click)="showAllAuthors = !showAllAuthors"
              class="show-more-button font-family-rajdhani-bold"
            >
              {{ showAllAuthors ? "Show Less" : "Show More" }}
            </button>
          </div>
        </div>

        <ng-template #noAuthors>
          <p>-</p>
        </ng-template>
        <ng-container
          *ngIf="!scixMetaData.author || scixMetaData.author.length === 0"
        >
          <ng-container *ngTemplateOutlet="noAuthors"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
