<table class="sq-results-grid-view">
    <thead>
        <tr>
            <th class="sq-grid-view-checkbox">
                <div class="form-check" title="{{(haveSelectedRecords ? 'msg#results.resultsGridView.unselectDocuments' : 'msg#results.resultsGridView.selectDocuments') | sqMessage}}">
                    <input class="form-check-input" type="checkbox" id="selected_document" [checked]="haveSelectedRecords" (change)="toggleSelectedRecords()">
                    <label role="button" for="selected_documents" class="form-check-label">&#8203;</label>
                </div>            
            </th>
            <th *ngFor="let columnData of columnsData" scope="col" [ngClass]="{'sq-clickable': isSortable(columnData)}" (click)="setSort(columnData)">{{columnData.config.title | sqMessage}}{{columnData.sortIndicator}}<span *ngIf="isSortable(columnData)" class="{{columnData.sortIcon}}"></span></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let record of results.records">
            <td class="sq-grid-view-checkbox">
                <sq-result-selector [record]="record"></sq-result-selector>
            </td>
            <ng-container *ngFor="let columnData of columnsData; let $index = index">
                <td *ngIf="columnData.config.renderAsHtml" [attr.scope]="$index === 0 ? 'row' : null" [innerHTML]="record[columnData.config.field] | sqValue:columnData.column"></td>
                <td *ngIf="!columnData.config.renderAsHtml" [attr.scope]="$index === 0 ? 'row' : null">{{record[columnData.config.field] | sqValue:columnData.column}}</td>
            </ng-container>
        </tr>
    </tbody>
</table>