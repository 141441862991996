import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "['img', 'iframe']",
})
export class LazyImgDirective {
  constructor(private el: ElementRef) {
    this.el.nativeElement.setAttribute("loading", "lazy");
  }
}
