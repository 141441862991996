<ng-content></ng-content>
<sq-facet-card *ngFor="let f of facets"
  #facetCard
  [ngClass]="f.className" class="mb-2"
  [title]="f.title"
  [icon]="f.icon">
    <ng-container
        [sqLoadComponent]="{component: getComponent(f.type), inputs: getFacetInputs(f)}"
        (sqLoadComponent)="onFacetLoad(facetCard, $event)">
    </ng-container>
</sq-facet-card>
<ng-content select="[bottom]"></ng-content>
