export default {
    "help": {
        "title": "Online Help"
    },

    "overrideUser": {
        "title": "Override User",
        "userName": "User name",
        "domain": "Domain"
    },

    "btnClose": {
        "text": "Close"
    }
};
