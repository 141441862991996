import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import {
  NotificationType,
  NotificationsService,
} from "@sinequa/core/notification";
import { Record } from "@sinequa/core/web-services";
import * as _ from "lodash";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { environment } from "sde/src/environments/environment";
import { googleAnalyticsConstants } from "../analytics/google-analytics.constant";
import { SdePreviewData } from "../model/extended.interface";
import { CommonService } from "../services/common.service";

export interface SdeRecord extends Record {
  urls: any;
  repos_links: any;
  related_urls_1: any;
  related_urls_2: any;

  related_urls_3: any;
}

@Component({
  selector: "app-sde-metadata-viewer",
  templateUrl: "./sde-metadata-viewer.component.html",
  styleUrls: ["./sde-metadata-viewer.component.scss"],
})
export class SdeMetadataViewerComponent implements OnInit {
  /**
   * @constructor
   */
  constructor(
    private commonService: CommonService,
    private previewService: PreviewService,
    private searchService: SearchService,
    private notificationsService: NotificationsService,
    public ui: UIService,
    private $gaService: GoogleAnalyticsService,
    private router: Router,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * This input property is used to hold preview item data which can be a document or metadata.
   */
  @Input() previewData: SdePreviewData;
  @ViewChildren("rightSectionTitle", { read: ElementRef })
  sectionElements!: QueryList<ElementRef>;
  observer!: IntersectionObserver;
  @ViewChild("leftSection") leftSection: ElementRef;

  /**
   * This input property is used to determine the type of viewer document/metadata.
   */
  @Input() previewType: string = "default";
  @ViewChild("metadataContent") metadataContent: ElementRef;

  /**
   * This output property is used to send an event to inform other components that data is loaded in metadata viewer.
   */
  @Output() dataLoadedEvent = new EventEmitter();
  activeSection: string = "data-information";
  recordData: any;
  //   Below is the Code for Metadata Overview which we merged here

  /**
   * This property is used as a ngx-bootstrap modal reference.
   */
  modalRef?: BsModalRef;
  /**
   * This property is used to store ngx-bootstrap modal configuration options.
   */
  config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-lg",
  };
  /**
   * This property is used to store 'Access Constraints' data.
   */
  accessConstraintData: any;
  /**
   * This property is used to store 'Use Constraints' data.
   */
  useConstraintData: any;
  /**
   * This property is used to determine whether metadata description box is expanded/collapsed.
   */
  expandedText: boolean;

  /**
   * This ViewChild property is used to store DOM Reference of left overview grid container.
   */
  @ViewChild("leftSection") leftContainer: ElementRef;
  /**
   * This ViewChild property is used to store DOM Reference of right content title.
   */
  @ViewChild("rightSectionTitle") rightTitle: ElementRef;
  /**
   * This ViewChild property is used to store DOM Reference of right content.
   */
  @ViewChild("rightSectionContent") rightContent: ElementRef;
  /**
   * This ViewChild property is used to store DOM Reference of right overview grid container.
   */
  @ViewChild("rightSection") rightContainer: ElementRef;

  showEllipsis = true;

  //   Below is the Code for Metadata Details which we merged here

  /**
   * This property is used to store science keywords.
   */
  scienceKeywordsArr: string[] = [];
  /**
   * This property is used to store phenomena.
   */
  phenomenaArr: string[] = [];
  /**
  
   * This property is used to store parameter data for SPASE data source.
   */
  spaseParameter: any;
  /**
   * This property is used to store date format config options
   */
  dateFormat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  //   Below is the Code for Metadata Links which we merged here

  /**
   * This property is used to store a string which contains the name of data source eg. CMR, SPASE, PDS etc.
   */
  dataSource: any;
  /**
   * This property is used to store an array of parsed links which are grouped by thier content type.
   */
  parsedLinksData: JSON[];
  /**
   * This property is used to store an array of parsed AWS S3 links.
   */
  parsedS3LinksData: JSON[];

  /**
   * This property holds metadata viewer action icons, tooltip text and functionality.
   */
  metadataViewerOptions: any = [
    {
      tooltip: "msg#general.openInPreview",
      function: () => {
        // this.searchService.loadingBetweenComponents(false);
        this.previewService.openRoute(
          this.previewData.record as Record,
          this.searchService.query
        );

        this.$gaService.event(
          googleAnalyticsConstants.action.click,
          googleAnalyticsConstants.category.button,
          googleAnalyticsConstants.label.openDocumentNavigator,
          0,
          true,
          {
            app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
            page: this.commonService.isInTdammApp()
              ? googleAnalyticsConstants.currentPage.tdammResultsPage
              : googleAnalyticsConstants.currentPage.sdeResultsPage,
            url: this.router.url,
            debug_mode: environment.googleAnalyticsDebugMode,

            ...this.commonService.createDocumentEventDetailsObject(
              this.previewData.record.title,
              this.previewData.record.collection,
              this.previewData.record.treepath,
              this.previewData.record.id
            ),
          }
        );
      },
      imageSrc: "assets/img/expand.svg",
      hide: this.ui.screenSizeIsLessOrEqual("sm"),
      alt: "Open document in full screen mode in document navigator",
    },
    {
      tooltip: "msg#general.copyLinkToDocument",
      function: () => {
        this.copyURLDocumentPreview(this.previewData.record);
        this.$gaService.event(
          googleAnalyticsConstants.action.click,
          googleAnalyticsConstants.category.button,
          googleAnalyticsConstants.label.copyDocumenLink,
          0,
          true,
          {
            app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
            page: this.commonService.isInTdammApp()
              ? googleAnalyticsConstants.currentPage.tdammResultsPage
              : googleAnalyticsConstants.currentPage.sdeResultsPage,
            url: this.router.url,
            debug_mode: environment.googleAnalyticsDebugMode,

            ...this.commonService.createDocumentEventDetailsObject(
              this.previewData.record.title,
              this.previewData.record.collection,
              this.previewData.record.treepath,
              this.previewData.record.id
            ),
          }
        );
      },
      imageSrc: "assets/img/copy.svg",
      hide: false,
      alt: "Copy link to the document",
    },
    {
      tooltip: "msg#general.closeDocumentPreview",
      function: () => {
        this.commonService.closeMetadataViewer.next(true);
      },
      imageSrc: "assets/img/close.svg",
      hide: false,
      alt: "Close preview mode",
    },
  ];

  ngOnInit(): void {
    this.highlightContent(this.activeSection);

    if (this.previewData) {
      this.dataLoadedEvent.emit(true);
    }
    this.recordData = this.previewData.record;

    // Below is the Code for Metadata Overview which we merged here
    /**
     * In this method Access Constraints and Use Constraints data is generated upon component initialization
     */
    if (this.recordData.access_constraints) {
      this.accessConstraintData = this.generateAccessConstraints(
        this.recordData.access_constraints
      );
    }
    if (this.recordData.use_constraints) {
      this.useConstraintData = this.generateUseConstraints(
        this.recordData.use_constraints
      );
    }
    // Below is the Code for Metadata Details which we merged here
    /**
     * In this method science keywords, data source name and SPASE parameter details are parsed/generated upon component initialization
     */
    this.scienceKeywordsArr = this.generateScienceKeywordsString(
      this.recordData
    );
    // this.phenomenaArr = this.generatePhenomena(this.detailsData);
    this.dataSource = this.determineDataSource(this.recordData);
    this.spaseParameter = this.generateSpaseParameter(this.recordData);

    // Below is the Code for Metadata Links which we merged here

    /**
     * In this method Links and S3 Links data is parsed and stored upon component initialization
     */

    if (this.recordData && this.recordData.urls) {
      this.parsedLinksData = _.groupBy(
        JSON.parse(this.recordData.urls),
        "URLContentType"
      );
    }

    if (
      this.recordData &&
      this.recordData.repos_links &&
      JSON.parse(this.recordData.repos_links)
    ) {
      this.parsedS3LinksData = JSON.parse(this.recordData.repos_links);
    }
  }

  ngAfterViewInit(): void {
    this.setMetadataContentStyle();

    const options = {
      root: null, // Viewport as root
      rootMargin: "0px 0px 300px 0px",
      threshold: 0.1, // Trigger when 10% of the div is visible
    };

    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      options
    );

    // Observe the "Data" section
    if (this.leftSection) {
      this.observer.observe(this.leftSection.nativeElement);
    }
    // Observe each section for intersection
    this.sectionElements.forEach((section: ElementRef) => {
      this.observer.observe(section.nativeElement);
    });

    // Below is the Code for Metadata Overview which we merged here
    /**
     * When application view is finished initialization, overview grid container heights are adjusted by initialOverviewGridHeight() method.
     */
    setTimeout(() => {
      this.initialOverviewGridHeight();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["previewData"]) {
      // Detect if previewData has changed
      this.setMetadataContentStyle();
    }
  }

  setMetadataContentStyle() {
    this.cdr.detectChanges();
    let top = this.metadataContent?.nativeElement?.getBoundingClientRect().top;
    // Calculate the new height by subtracting the 'top' from the window's inner height
    let newHeight = window.innerHeight - top;
    // Set the new height for the metadataContent element
    this.metadataContent.nativeElement.style.height = `${newHeight}px`;
  }

  /**
   * Copy URL of Metadata Preview from small preview on results page
   */
  copyURLDocumentPreview(record: Record) {
    this.commonService.copyURL(record as Record, this.searchService.query);
    this.notificationsService.notify(
      NotificationType.Success,
      "msg#actionMenu.urlCopiedToClipboard",
      {},
      undefined,
      true
    );
  }
  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  // Below is the Code for Metadata Overview which we merged here

  /**
   * Used by generateAccessConstraints() and generateUseConstraints() method to check if data coming from the backend is in JSON format or not.
   * @param {any} obj - JSON object
   * @returns {boolean} - boolean value representing whether a current object is in JSON format or not
   */
  checkIfJSON(obj: any): boolean {
    {
      try {
        JSON.parse(obj);
        return true;
      } catch (e) {
        return false;
      }
    }
  }

  check($event) {}

  /**
   * Used to generate Access Constraints data to be shown on UI
   * @param {any} ac - Access Constraints JSON object
   * @returns {any} -  value either in JSON format or same as source format. Returns '-' when undefined
   */
  generateAccessConstraints(ac: any) {
    if (this.checkIfJSON(ac)) {
      return JSON.parse(ac)[0];
    } else if (!this.checkIfJSON(ac)) {
      return ac;
    } else {
      return "-";
    }
  }

  /**
   * Used to generate Use Constraints data to be shown on UI
   * @param {any} uc - Use Constraints JSON object
   * @returns {any} -  value either in JSON format or same as source format. Returns '-' when undefined
   */
  generateUseConstraints(uc: any) {
    if (this.checkIfJSON(uc)) {
      return JSON.parse(uc)[0];
    } else if (!this.checkIfJSON(uc)) {
      return uc;
    } else {
      return "-";
    }
  }

  isDescriptionHTML(text) {
    text = text
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&");
    return /<a\b[^>]*>/i.test(text);
  }

  /**
   * Used to change height of description container when 'read more' is clicked
   */
  expandForReadMore() {
    this.expandedText = true;
    this.rightContent.nativeElement.style.height = "fit-content";
    this.rightContainer.nativeElement.style.height = "fit-content";
  }

  /**
   * This method is used to set overview grid container height and keep it consistent.
   */
  initialOverviewGridHeight() {
    this.expandedText = false;
    this.rightContent.nativeElement.style.height = "200px";
  }

  handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.id; // Access the id directly from the target

        if (id) {
          this.activeSection = id; // Update the active section when it's visible
          this.cdr.detectChanges();
        }
      }
    });
  }
  triggerScroll(target: string) {
    const element = document.getElementById(target);

    if (element) {
      this.activeSection = target;
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      // Optionally highlight the content after scrolling
      setTimeout(() => {
        this.highlightContent(target);
      }, 500);
    }
  }

  highlightContent(targetContentId: string) {
    const contentElement = document.getElementById(targetContentId);

    if (contentElement) {
      this.renderer.addClass(contentElement, "highlight");
      setTimeout(() => {
        this.renderer.removeClass(contentElement, "highlight");
      }, 1000);
    }
  }
  // Below is the code for MetaData Details which we merged here

  /**
   * This method is used to generate spatial bounds
   *
   * Conversions are as below:
   * 1. if data are point oriented, this element provides the lat/lon of point extent
   * 2. if data are in a rectangular box, these elements provide the W, N, E, S lat/lon limits of the box
   * 3. if data are in a polygon, these elements provide the lat/lon of each point of the bounding polygon
   * 4. if data lie along a line, these elements provide the lat/lon coords defining the line
   *
   * @param {any} concepts - holds all metadata
   * @returns {string} - spatial bounds string
   */
  generateSpatialBounds(concepts: any) {
    if (this.dataSource === "cmr") {
      if (
        concepts.spatial_resolution_spatial_extent &&
        concepts.spatial_bounds
      ) {
        const spatialExtentData = JSON.parse(
          concepts["spatial_resolution_spatial_extent"]
        );
        let spatialBoundsData = JSON.parse(concepts["spatial_bounds"]);
        let spatialBoundsHorizontal;
        spatialBoundsData.forEach((element) => {
          if (Array.isArray(element)) {
            spatialBoundsHorizontal = element.pop();
          }
        });

        if (
          spatialExtentData &&
          typeof spatialExtentData[0] == "string" &&
          (spatialExtentData[0] === "HORIZONTAL" ||
            spatialExtentData[0] === "HORIZONTAL_VERTICAL")
        ) {
          let spatialBounds = "";

          if (spatialBoundsHorizontal && spatialBoundsHorizontal.Point) {
            spatialBounds =
              "Longitude: " +
              spatialBoundsHorizontal.Point.Longitude +
              " , " +
              "Latitude: " +
              spatialBoundsHorizontal.Point.Latitude;

            return spatialBounds;
          } else if (
            spatialBoundsHorizontal &&
            spatialBoundsHorizontal.EastBoundingCoordinate &&
            spatialBoundsHorizontal.WestBoundingCoordinate &&
            spatialBoundsHorizontal.NorthBoundingCoordinate &&
            spatialBoundsHorizontal.SouthBoundingCoordinate
          ) {
            spatialBounds =
              spatialBoundsHorizontal.NorthBoundingCoordinate +
              " N " +
              spatialBoundsHorizontal.WestBoundingCoordinate +
              " W " +
              spatialBoundsHorizontal.SouthBoundingCoordinate +
              " S " +
              spatialBoundsHorizontal.EastBoundingCoordinate +
              " E ";

            return spatialBounds;
          } else if (
            spatialBoundsHorizontal &&
            spatialBoundsHorizontal.GPolygon &&
            spatialBoundsHorizontal.GPolygon.Boundary &&
            spatialBoundsHorizontal.GPolygon.Boundary.Points
          ) {
            spatialBounds =
              "Longitude: " +
              spatialBoundsHorizontal.GPolygon.Boundary.Points.Longitude +
              " , " +
              "Latitude: " +
              spatialBoundsHorizontal.GPolygon.Boundary.Points.Latitude;
            return spatialBounds;
          }
          // if data lie along a line, these elements provide the lat/lon coords defining the line"
          else if (
            spatialBoundsHorizontal &&
            spatialBoundsHorizontal.Line &&
            spatialBoundsHorizontal.Line.Points
          ) {
            spatialBounds =
              "Longitude: " +
              spatialBoundsHorizontal.Line.Points.Longitude +
              " , " +
              "Latitude: " +
              spatialBoundsHorizontal.Line.Points.Latitude;
            return spatialBounds;
          } else {
            return "-";
          }
        } else if (
          spatialExtentData &&
          typeof spatialExtentData[0] == "string" &&
          (spatialExtentData[0] === "VERTICAL" ||
            spatialExtentData[0] === "ORBITAL_VERTICAL")
        ) {
          const spatialBoundsVerticalKeys = concepts["spatial_bounds_1"];
          const spatialBoundsVerticalValues = concepts["spatial_bounds_2"];
          let spatialBounds = "",
            keys = [],
            values = [];
          keys = spatialBoundsVerticalKeys.split(";");
          values = spatialBoundsVerticalValues.split(";");
          keys.forEach((key, index) => {
            spatialBounds += key + ": " + values[index];
          });
          return spatialBounds;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    } else if (this.dataSource === "spase") {
      if (concepts.spatial_bounds) {
        return concepts.spatial_bounds;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  /**
   * This method is used to generate spatial resolution
   *
   * Conversions are as below:
   * 1. If spatial info is present and spatial coverage is 'VERTICAL' or 'HORIZONTAL_VERTICAL' or 'ORBITAL_VERTICAL' then spatial resolution is generated in terms of Altitude and Depth
   *
   * @param {any} concepts - holds all metadata
   * @returns {string} - spatial resolution string
   */
  generateSpatialResolution(concepts: any) {
    if (this.dataSource === "cmr") {
      if (concepts.spatial_resolution_spatial_info && concepts.spatial_bounds) {
        let spatialResolution;
        let spatialInfo = JSON.parse(
          concepts["spatial_resolution_spatial_info"]
        );
        let spatialCoverage;
        spatialInfo.forEach((element: any) => {
          if (typeof element === "string" && element !== "CARTESIAN") {
            spatialCoverage = element;
          }
        });

        if (
          spatialInfo &&
          spatialCoverage &&
          concepts["spatial_resolution_spatial_info_1"] &&
          concepts["spatial_resolution_spatial_info_2"] &&
          concepts["spatial_resolution_spatial_info_3"] &&
          concepts["spatial_resolution_spatial_info_4"] &&
          concepts["spatial_resolution_spatial_info_5"] &&
          concepts["spatial_resolution_spatial_info_6"] &&
          (spatialCoverage === "VERTICAL" ||
            spatialCoverage === "HORIZONTAL_VERTICAL" ||
            spatialCoverage === "ORBITAL_VERTICAL")
        ) {
          spatialResolution =
            "Altitude" +
            concepts["spatial_resolution_spatial_info_1"] +
            " " +
            +concepts["spatial_resolution_spatial_info_2"] +
            " " +
            +concepts["spatial_resolution_spatial_info_3"] +
            " " +
            "Depth" +
            concepts["spatial_resolution_spatial_info_4"] +
            " " +
            +concepts["spatial_resolution_spatial_info_5"] +
            " " +
            +concepts["spatial_resolution_spatial_info_6"] +
            " ";

          return spatialResolution;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  /**
   * This method is used to generate temporal bounds
   *
   * Conversions are as below:
   * 1. If data are for a single observation/valid time, this element provides the DateTime of the data
   * 2. If data are for a set range of time, these elements provide the start/end bounds of the data
   * 3. If data are for a recurring period of time, these elements provide the start/end bounds of the data
   * 4. If the data are for a continually updated product (eg: a NRT or still-ongoing data collection senario), the EndsAtPresentFlag indicates the last available time of the data should be the present time when the data were accessed.
   *
   * @param {any} concepts - holds all metadata
   * @returns {string} - temporal bounds string
   */
  generateTemporalBounds(concepts: any) {
    if (this.dataSource === "cmr") {
      if (concepts.temporal_bounds) {
        let temporalExtentData = JSON.parse(concepts.temporal_bounds);
        let temporalBounds = "";
        if (
          temporalExtentData &&
          temporalExtentData[0].SingleDateTimes &&
          concepts.temporal_bounds_1
        ) {
          temporalBounds = concepts.temporal_bounds_1;
          if (temporalBounds !== undefined) {
            return temporalBounds;
            // this.datePipe.transform(temporalBounds, "yyyy-MM-dd hh:mm:ss.SSS");
          } else {
            return "-";
          }
        } else if (
          temporalExtentData &&
          temporalExtentData[0] &&
          temporalExtentData[0].RangeDateTimes &&
          temporalExtentData[0].RangeDateTimes[0] &&
          (concepts.temporal_bounds_1 || concepts.temporal_bounds_2)
        ) {
          let startDate = concepts.temporal_bounds_2;
          let endDate = concepts.temporal_bounds_3;
          if (startDate && endDate) {
            // return `From ${this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss.SSS")} to ${this.datePipe.transform(
            //   endDate,
            //   "yyyy-MM-dd hh:mm:ss.SSS"
            // )}`;
            return `From ${startDate} to ${endDate}`;
          } else if (startDate && !endDate) {
            // return `Start Date ${this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss.SSS")}`;
            return `Start Date ${startDate}`;
          } else {
            return `Stop Date ${endDate}`;
            // return `Stop Date ${this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss.SSS")}`;
          }
          return "-";
        }

        // if data are for a recurring period of time, these elements provide the start/end bounds of the data
        else if (
          temporalExtentData &&
          temporalExtentData[0] &&
          temporalExtentData[0].PeriodicDateTimes &&
          temporalExtentData[0].PeriodicDateTimes[0] &&
          concepts.temporal_bounds_1 &&
          concepts.temporal_bounds_2
        ) {
          // temporalBounds = `From ${this.datePipe.transform(
          //   temporalExtentData[0].PeriodicDateTimes[0].StartDate,
          //   "yyyy-MM-dd hh:mm:ss.SSS"
          // )}
          //   to
          //   ${this.datePipe.transform(temporalExtentData[0].PeriodicDateTimes[0].EndDate, "yyyy-MM-dd hh:mm:ss.SSS")}`;
          temporalBounds = `From ${concepts.temporal_bounds_1}
              to
              ${concepts.temporal_bounds_2}`;
          return temporalBounds;
        }

        // if the data are for a continually updated product (eg: a NRT or still-ongoing data collection scenario), the EndsAtPresentFlag indicates the last available time of the data should be the present time when the data were accessed."
        if (
          temporalExtentData &&
          temporalExtentData[0] &&
          temporalExtentData[0].EndsAtPresentFlag
        ) {
          temporalBounds = temporalExtentData[0].EndsAtPresentFlag;
          return temporalBounds;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    } else if (this.dataSource === "spase" || this.dataSource === "pds") {
      if (concepts.temporal_bounds_1 && concepts.temporal_bounds_2) {
        // return `From ${this.datePipe.transform(
        //   concepts.temporal_bounds_1,
        //   "yyyy-MM-dd hh:mm:ss.SSS"
        // )} to ${this.datePipe.transform(concepts.temporal_bounds_2, "yyyy-MM-dd hh:mm:ss.SSS")}`;
        return `From ${concepts.temporal_bounds_1} to ${concepts.temporal_bounds_2}`;
      } else if (concepts.temporal_bounds_1 && !concepts.temporal_bounds_2) {
        // return `Start Date ${this.datePipe.transform(concepts.temporal_bounds_1, "yyyy-MM-dd hh:mm:ss.SSS")}`;
        return `Start Date ${concepts.temporal_bounds_1}`;
      } else if (!concepts.temporal_bounds_1 && concepts.temporal_bounds_2) {
        // return `Stop Date ${this.datePipe.transform(concepts.temporal_bounds_2, "yyyy-MM-dd hh:mm:ss.SSS")}`;
        return `Stop Date ${concepts.temporal_bounds_2}`;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  /**
   * This method is used to generate temporal resolution
   * @param {any} details - holds all metadata
   * @returns {string} - temporal resolution string
   */
  generateTemporalResolution(details: any) {
    if (this.dataSource === "cmr") {
      if (details.temporal_resolution_1 && details.temporal_resolution_2) {
        return `${details.temporal_resolution_1} ${details.temporal_resolution_2}`;
      } else {
        return "-";
      }
    } else if (this.dataSource === "spase") {
      if (details.temporal_resolution_1) {
        return details.temporal_resolution_1;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  /**
   * This method is used to generate spase parameter details. Object is modified for displaying subheading and its contents within a loop
   * @param {any} details - holds all metadata
   * @returns {any} returnArray- modified spase parameter JSON array
   */
  generateSpaseParameter(details: any) {
    if (details.spase_parameter) {
      let parameterData: any = JSON.parse(details.spase_parameter);
      parameterData = parameterData.map((parameter: any) => {
        let returnArray: any[] = [];
        let noHeadingObject: any = {};
        noHeadingObject.value = [];
        noHeadingObject.isSubHeading = false;
        noHeadingObject.subHeading = undefined;
        Object.entries(parameter).forEach((outerPair: any) => {
          if (typeof outerPair[1] === "object") {
            let obj: any = {};
            obj.value = Object.entries(outerPair[1]).map((innerPair) => ({
              key: innerPair[0],
              value: innerPair[1],
            }));
            obj.isSubHeading = true;
            obj.subHeading = outerPair[0];
            returnArray.push(obj);
          } else if (typeof outerPair[1] !== "object") {
            noHeadingObject.value.push({
              key: outerPair[0],
              value: outerPair[1],
            });
          }
        });
        returnArray = [noHeadingObject, ...returnArray];
        return returnArray;
      });
      return parameterData;
    } else {
      return undefined;
    }
  }

  /**
   * This method is used to generate title for either element data or frequency range data of SPASE parameter.
   * @param {any} obj - holds single spase parameter
   * @param {number} index - holds index of nested parameter details.
   * @returns {string} - title for element or frequency range data
   */
  generateCustomTitle(obj: any, index: number) {
    if (obj && obj.key === "Element") {
      return "Element";
    } else if (obj && obj.key === "FrequencyRange") {
      let freqObjArr = Object.keys(obj.value);
      return "FrequencyRange" + "(" + freqObjArr[index] + ")";
    }
    return undefined;
  }

  /**
   * This method is used to generate value for either element data or frequency range data of SPASE parameter.
   * @param {any} obj - holds single spase parameter
   * @param {number} index - holds index of nested parameter details.
   * @returns {string} - value for element or frequency range data
   */
  generateCustomValue(obj: any, index: number) {
    if (obj && obj.key === "Element") {
      let returnString = "";
      Object.entries(obj.value[index]).forEach((entry, index) => {
        let spacedKey = entry[0].replace(/([A-Z])/g, " $&").trim();
        returnString += spacedKey + " : " + entry[1] + "<br>";
      });
      return returnString;
    } else if (obj && obj.key === "FrequencyRange") {
      let freqObjArr = Object.entries(obj.value);
      return freqObjArr[index][1];
    }
    return undefined;
  }

  /**
   * This method is used to generate science keywords string
   * @param {any} details - holds metadata
   * @returns {any[]} - array containing scientific keywords
   */
  generateScienceKeywordsString(details: any) {
    if (details && details.scientific_keywords_1) {
      return [...details.scientific_keywords_1];
    } else {
      return [];
    }
  }

  /**
   * This method is used to generate phenomena data
   * @param {any} details - holds metadata
   * @returns {any[]} - array containing phenomena data
   */
  generatePhenomena(details: any) {
    if (details && details.cmr_phenomena) {
      return [...details.cmr_phenomena];
    } else {
      return [];
    }
  }

  /**
   * This method is used to generate current data source name
   * @param {any} details - holds metadata
   * @returns {string} - current data source name
   */
  determineDataSource(details: any) {
    if (details.treepath && details.treepath.length > 0) {
      let str = details.treepath[0].toString().toLowerCase();
      if (str.includes("spase")) {
        return "spase";
      } else if (str.includes("cmr")) {
        return "cmr";
      } else if (str.includes("pds")) {
        return "pds";
      } else if (str.includes("astrophysics")) {
        return "astrophysics";
      } else if (str.includes("biological and physical sciences")) {
        return "osdr";
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  /**
   * This method is used to determine whether current data type is object or not
   * @param {any} obj - holds a key which can be of any type
   * @returns {boolean} - value is 'true' if data type is object, 'false' otherwise
   */
  isObject(obj: any) {
    return typeof obj === "object";
  }

  /**
   * This method is used in ngFor loop to track every item for changes
   * @param {number} index - index of current item
   * @returns {number} - index of current item
   */
  trackByIndex(index, item) {
    return index;
  }

  // Below is the Code for Metadata Links which we merged here

  MetaDataLinkClick(url: string, link: any) {
    window.open(url, "_blank");
    let linkKey: string;

    // Check if 'link' is an object with a 'key' property
    if (link && typeof link === "object" && "key" in link) {
      linkKey = link.key;
    } else {
      linkKey = link;
    }

    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.link,
      url,
      0,
      true,
      {
        app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
        page: this.commonService.isInTdammApp()
          ? googleAnalyticsConstants.currentPage.tdammResultsPage
          : googleAnalyticsConstants.currentPage.sdeResultsPage,
        url: this.router.url,
        debug_mode: environment.googleAnalyticsDebugMode,

        ...this.commonService.createMetaDataLinkClickedObject(
          this.recordData.title,
          this.recordData.collection,
          this.recordData.treepath,
          this.recordData.id,
          linkKey,
          this.dataSource
        ),
      }
    );
  }
}
