import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { SearchService } from "@sinequa/components/search";
import { AppService } from "@sinequa/core/app-utils";
import {
  AuditWebService,
  SponsoredLinksWebService,
} from "@sinequa/core/web-services";
import { SponsoredResults } from "./../../../../../components/result/sponsored-results/sponsored-results";

@Component({
  selector: "app-sde-sponsored-links",
  templateUrl: "./sde-sponsored-links.component.html",
  styleUrls: ["./sde-sponsored-links.component.scss"],
})
export class SdeSponsoredLinksComponent
  extends SponsoredResults
  implements OnInit
{
  constructor(
    protected override changeDetectorRef: ChangeDetectorRef,
    protected override appService: AppService,
    protected override searchService: SearchService,
    protected override sponsoredResultsService: SponsoredLinksWebService,
    protected override auditService: AuditWebService
  ) // private commonService: CommonService
  {
    super(
      appService,
      searchService,
      sponsoredResultsService,
      auditService,
      changeDetectorRef
    );
  }

  override ngOnInit(): void {}

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed
  }

  // setTabName(tabName: string) {
  //   if (tabName == "Publications") {
  //     this.commonService.updateForSciXSponsoredLinkContainer(true);
  //   }
  // }
}
