<div>
  <!-- Timeline -->

  <sq-facet-card
    #timelineEl
    id="timeline"
    [icon]="'fas fa-calendar-alt'"
    [title]="'msg#facet.timeline.title'"
    [collapsible]="true"
    [startCollapsed]="true"
    [buttonsStyle]="isDark() ? 'dark' : 'light'"
    class="d-block mb-3"
    [ngStyle]="{ 'overflow-y': isFullScreen ? 'scroll' : 'hidden' }"
  >
    <span
      style="position: absolute; top: 12px; left: 115px"
      class="fas fa-expand"
      title="{{ 'msg#general.fullscreen' | sqMessage }}"
      (click)="toggleFullscreen('timeline')"
    >
    </span>
    <sq-facet-timeline
      #facet
      [results]="results"
      [height]="isFullScreen ? '300' : '100'"
    ></sq-facet-timeline>
  </sq-facet-card>
</div>
