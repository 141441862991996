import { Component, Input, OnInit } from "@angular/core";

export type Snack = {
  _id: string;
  message: string;
  duration?: number; //seconds
  dismissible?: boolean;
};

@Component({
  selector: "snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent implements OnInit {
  @Input("snack") snack: Snack;

  snackOpacity: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.snackOpacity = 1.0;
    if (this.snack.duration) {
      setTimeout(
        () => {
          this.snackOpacity = 0.0;
        },
        this.snack.duration * 1000 - 500
      );
    }
  }
}
