import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { RecentDocument } from "@sinequa/components/saved-queries";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { LoginService } from "@sinequa/core/login";
import {
  JsonMethodPluginService,
  PrincipalWebService,
} from "@sinequa/core/web-services";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { FEATURES } from "../../config";
import { environment } from "../../environments/environment";
import googleAnalyticsConstants from "../analytics/google-analytics.constant";
import { CommonService } from "../services/common.service";
import { HeaderService } from "../services/header.service";
import { SdeSearchService } from "../services/sde-search.service";

import { NotificationsComponent } from "./notifications/notifications.component";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  // Whether the left facet bar is shown
  public _showFilters = this.ui.screenSizeIsEqual("md");

  // Whether the menu is shown on small screens
  public _showMenu = false;

  userFullName: string | undefined = "";

  private _shouldShowCuratorMenu: boolean = false;

  notifications: [];
  selectedBackground1: string;
  selectedBackground2: string;
  selectedOption: string;
  dropOptions: any;
  hoverHelpEnabled = false;

  isScientificFocusOpen: boolean = false;
  readMoreMode: string = "initial";
  scientificFocusValues: string[] = [
    "All",
    "Astrophysics",
    "Biological & Physical Sciences",
    "Earth Science",
    "Heliophysics",
    "Planetary Science",
  ];

  selectedOptionImage: string = "assets/img/all-image.svg"; // Replace 'default_image_url' with the URL of your default image

  @ViewChild("leftCard") leftCard: ElementRef;
  @ViewChild("rightCard") rightCard: ElementRef;
  @ViewChild("ejCard") ejCard: ElementRef;
  @ViewChild("cardContainer") cardContainer: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    setTimeout(() => {
      this.setCardHeights();
    }, 100);
  }

  constructor(
    private router: Router,
    public loginService: LoginService,
    public searchService: SearchService,
    // private intlService: IntlService,
    private previewService: PreviewService,
    private appService: AppService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private headerSrv: HeaderService,
    private principalService: PrincipalWebService,
    public pluginService: JsonMethodPluginService,
    private sdeSearchService: SdeSearchService,
    public ui: UIService,
    private renderer: Renderer2,
    private titleService: Title,
    private $gaService: GoogleAnalyticsService
  ) {}

  /**
   * On initialization set the page title
   */
  ngOnInit() {
    this.selectedOption = this.scientificFocusValues[0]; // for setting  the first text color from to dropdown to blue initially
    this.searchService.query.scope = this.selectedOption;
    if (
      this.appService.ccquery?.name === "query_ej_primary" ||
      this.appService.ccquery?.name === "tdamm_query_service"
    ) {
      this.sdeSearchService.revertToDefaultQuery();
    }
    this.scientificFocusValues.map((item) => {
      this.dropOptions = item;
    });
    this.titleService.setTitle(
      googleAnalyticsConstants.currentPage.sdeHomePage
    );

    //  this.titleService.setTitle(this.intlService.formatMessage("msg#app.name"));
    this.principalService.events.subscribe((event) => {
      if (this.principalService.principal?.fullName != undefined) {
        this.userFullName =
          environment.title + "-" + this.principalService.principal?.fullName;
      } else this.userFullName = "Waiting for Authentication...";
    });

    this.loginService.events.subscribe((event) => {
      if (!this.headerSrv.notificationShown && this.appService.app) {
        this.showNotifications();
        this.headerSrv.notificationShown = !this.headerSrv.notificationShown;
      }
    });

    this.commonService.hoverHelpEnabled$.subscribe((enabled) => {
      this.hoverHelpEnabled = enabled;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setCardHeights();
    }, 100);
  }

  setCardHeights() {
    let leftEle = this.leftCard.nativeElement;
    let rightEle = this.rightCard.nativeElement;
    this.renderer.setStyle(rightEle, "height", "fit-content");
    this.renderer.setStyle(leftEle, "height", "fit-content");
    if (leftEle.clientHeight > rightEle.clientHeight) {
      this.renderer.setStyle(rightEle, "height", leftEle.clientHeight + "px");
    } else if (leftEle.clientHeight < rightEle.clientHeight) {
      this.renderer.setStyle(leftEle, "height", rightEle.clientHeight + "px");
    }
  }

  showNotifications() {
    this.notifications = this.appService.app?.data?.notifications as any;
    if (this.notifications != null) {
      this.notifications?.forEach(
        (app: { id: number; title: string; details: string }, index: any) => {
          this.openDialog(app.title, app.details);
        }
      );
    }
  }

  openDialog(title, details) {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      hasBackdrop: true,
      width: "450px",
      height: "200px",
      position: {
        top: "50vh",
        left: "36vw",
      },
      panelClass: "makeItMiddle",
      autoFocus: true,
      disableClose: true,
      backdropClass: "custom-dialog-backdrop-class",
      data: {
        title: title,
        details: details,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public get showCurator(): boolean {
    this._shouldShowCuratorMenu = this.headerSrv.showCuratorsMenu;
    return this._shouldShowCuratorMenu;
  }

  /**
   * Manage login and logout
   */
  /*   toggleLogin() {
      if (!!this.loginService.principal) {
        this.loginService.logout();
      }
      else {
        this.loginService.login();
      }
      return false;
    } */

  /**
   * Open recent documents in the preview
   * @param document
   */
  openRecentDocument(document: RecentDocument) {
    this.previewService.openRoute(
      document as any,
      this.searchService.makeQuery()
    );
  }

  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }

  /**
   * Toggle dark mode
   */
  toggleDark() {
    document.body.classList.toggle("dark");
    localStorage.setItem("sinequa-theme", this.isDark() ? "dark" : "normal");
    return false;
  }

  /**
   * Returns the list of features activated in the top right menus.
   * The configuration from the config.ts file can be overriden by configuration from
   * the app configuration on the server
   */
  public get features(): string[] {
    if (
      this.appService.app &&
      this.appService.app.data &&
      this.appService.app.data.features
    ) {
      return <string[]>(<any>this.appService.app.data.features);
    }
    return FEATURES;
  }

  /**
   * Returns the sublist of features which gets displayed on the home page.
   */
  public get homeFacets(): string[] {
    return this.features.filter(
      (feature) =>
        ["saved-queries", "recent-documents", "recent-queries"].indexOf(
          feature
        ) !== -1
    );
  }

  /**
   * Return bootstrap classes allowing to position and size each facet on the home page.
   * @param i The index of the facet in the list
   * @param n The number of facets
   */
  public facetSizing(i: number, n: number): string {
    switch (n) {
      case 1:
        return "offset-md-3 col-md-6 offset-lg-4 col-lg-4";
      case 2:
        return i === 0
          ? "offset-md-1 col-md-5 offset-lg-3 col-lg-3"
          : "col-md-5 col-lg-3";
      case 3:
        return i === 0
          ? "offset-md-1 col-md-5 offset-lg-0 col-lg-4"
          : i === 1
          ? "col-md-5 col-lg-4"
          : "offset-md-3 col-md-6 offset-lg-0 col-lg-4";
      case 4:
        return i % 2 === 0
          ? "offset-md-1 col-md-5 offset-lg-0 col-lg-3"
          : "col-md-5 col-lg-3";
      case 5:
        return i === 0 || i === 2
          ? "offset-md-1 col-md-5 offset-lg-0 col-lg-4"
          : i === 1
          ? "col-md-5 col-lg-4"
          : i === 3
          ? "offset-md-0 col-md-5 offset-lg-3 col-lg-3"
          : "offset-md-3 col-md-6 offset-lg-0 col-lg-3";
    }
    return i % 2 === 0
      ? "offset-md-1 col-md-5 offset-lg-0 col-lg-3"
      : "col-md-5 col-lg-3";
  }

  /**
   * Controls visibility of menu (small screen sizes)
   */
  get showMenu(): boolean {
    return (
      this.ui.screenSizeIsGreaterOrEqual("sm") ||
      (this._showMenu && !this._showFilters)
    );
  }

  /**
   * Show or hide the user menus (small screen sizes)
   */
  toggleMenu() {
    this._showMenu = !this._showMenu;
  }

  // logic for the changing of the background when we change the dropdown value
  changeBackgroundOfScientificFocus(item) {
    this.selectedOption = item;
    this.commonService.selectedScientificFocus = item;
    this.appService.scope = item;
    this.selectedOptionImage = this.getIconSrc(item);
    this.searchService.query.scope = this.selectedOption;

    switch (item) {
      case "Earth Science":
        this.selectedBackground1 =
          "assets/img/Background/earthScience-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/earthScience-image2.webp";
        break;
      case "Planetary Science":
        this.selectedBackground1 =
          "assets/img/Background/planetary-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/planetary-image2.webp";
        break;
      case "Astrophysics":
        this.selectedBackground1 =
          "assets/img/Background/astrophysics-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/astrophysics-image2.webp";
        break;
      case "Biological & Physical Sciences":
        this.selectedBackground1 =
          "assets/img/Background/biophysical-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/biophysical-image2.webp";
        break;
      case "Heliophysics":
        this.selectedBackground1 =
          "assets/img/Background/heliophysics-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/heliophysics-image2.webp";
        break;
      case "All":
        this.selectedBackground1 = "assets/img/Background/all-image1.webp";
        this.selectedBackground2 = "assets/img/Background/all-image2.webp";
        break;
      // Add more cases as needed
      default:
        // Handle invalid input
        break;
    }
  }

  getIconSrc(item: string): string {
    // You can implement a logic here to map each item to its corresponding icon URL
    // For example, using a switch statement or an object lookup
    switch (item) {
      case "Earth Science":
        return "assets/img/earth-science.svg";
      case "Planetary Science":
        return "assets/img/planetary-image.svg";
      case "Astrophysics":
        return "assets/img/astrophysics-image.svg";
      case "Biological & Physical Sciences":
        return "assets/img/biophysical-image.svg";
      case "Heliophysics":
        return "assets/img/heliophysics-image.svg";
      case "All":
        return "assets/img/all-image.svg";
      // Add more cases as needed
      default:
        return "";
    }
  }

  // logic to rotate dropdown icon when opened
  toggleScientificFocus() {
    this.isScientificFocusOpen = !this.isScientificFocusOpen;
  }

  toggleReadMoreMode(state: string) {
    this.readMoreMode = state;
    if (state === "disabled") {
      setTimeout(() => {
        this.setCardHeights();
      }, 1000);
    }
  }

  detectReadMoreEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.toggleReadMoreMode("enabled");
    }
  }

  navigateToSearchStory() {
    this.commonService.globalNasaLoader.next(true);
    if (window.location.origin.includes("localhost")) {
      this.router.navigate(["/MySearchStory"]);
    }
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.mySearchStory,
      0,
      true,
      {
        app_name: "SDE",
        page: googleAnalyticsConstants.currentPage.sdeHomePage,
        url: this.router.url,
      }
    );
  }

  getSearchStoryURL() {
    // prettier-ignore
    return window.location.origin + "/app/" + environment.app + "/#/MySearchStory";
  }

  navigateToSciXLink() {
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.sciX,
      0,
      true,
      {
        app_name: "SDE",
        page: googleAnalyticsConstants.currentPage.sdeHomePage,
        url: this.router.url,
      }
    );
  }

  getAriaLabel(item: string): string {
    switch (item) {
      case "Earth Science":
        return "Look for search results across Earth Science focus area";
      case "Planetary Science":
        return "Look for search results across Planetary Science focus area";
      case "Astrophysics":
        return "Look for search results across Astrophysics focus area";
      case "Biological & Physical Sciences":
        return "Look for search results across Biological & Physical Sciences focus area";
      case "Heliophysics":
        return "Look for search results across Heliophysics focus area";
      case "All":
        return "Look for search results across all scientific focus areas";
      default:
        return "";
    }
  }

  navigateToDataset() {
    this.commonService.selectedScientificFocus = "Earth Science";
    this.searchService.query.scope = "Earth Science";
    let queryObject = {
      name: "query-smd-primary",
      scope: "Earth Science",
      text: "",
      tab: "all",
      advanced: { collection: "/SDE/CMR_API/" },
    };
    this.router.navigate(["/search"], {
      queryParams: {
        query: JSON.stringify(queryObject),
      },
    });
  }
  // Walkthrough
  endWalkthroughTourForHomePage() {
    this.router.navigate(["/home"]);
  }
  stopHoverHelpFeature() {
    this.commonService.toggleHoverHelp(false);
  }

  navigateToContactPage(source: any): void {
    this.router.navigate(["/contact-us"], { queryParams: source });
  }

  // Navigate to EJ app - SDE/EJ
  navigateToEjApp() {
    this.commonService.navigateToEjApplicationNewTab();
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.environmentalJustice,
      0,
      true,
      {
        app_name: "SDE",
        page: googleAnalyticsConstants.currentPage.sdeHomePage,
        url: this.router.url,
      }
    );
  }
}
