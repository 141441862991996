<ng-container *ngIf="(previewData && !loading) else loadingTpl">

  <div class="text-center text-muted" *ngIf="extracts?.length === 0; else extractsTpl">
    <span>{{ 'msg#preview.noextract' | sqMessage}}</span>
  </div>

  <ng-template #extractsTpl>

    <div class="d-flex flex-row-reverse px-3 py-2">
      <div class="btn-group" [sq-action-buttons]="{items: [sortAction], autoAdjust: true, style: 'link'}"></div>
    </div>

    <sq-virtual-scroller class="flex-grow-1 overflow-auto mb-4 list-group"
      id="extracts-list"
      [list]="extracts"
      [itemsNumber]="extractsNumber"
      [scrollIndex]="scrollIndex"
      (newList)="displayedExtracts = $event">

      <div *ngFor="let extract of displayedExtracts; let i = index"
        class="px-2 px-lg-3"
        [sqScrollIntoView]="{active: i === currentIndex, first: i === 0}"
        [id]="'extract-' + extract.relevanceIndex">
        <a role="button"
          class="my-2 list-group-item list-group-item-action"
          [ngClass]="{'active': currentIndex === i}"
          (click)="scrollExtract(extract)">
          <p class="m-0" [innerHTML]="extract.text"></p>
        </a>
        <hr class="my-0 mx-3">
      </div>

    </sq-virtual-scroller>

    <div class="d-flex align-items-center justify-content-between p-4 pt-0" *ngIf="showPagination">
      <button class="btn btn-primary" (click)="previousExtract()" [disabled]="currentIndex < 1">
        <div class="d-flex align-items-center">
          <i class="fas fa-arrow-left"></i>
          <span class="d-none d-md-block ms-md-2">{{ 'msg#preview.previous' | sqMessage}}</span>
        </div>
      </button>
      <div *ngIf="currentIndex >= 0" class="extracts-counter">
        {{currentIndex+1}}/{{extracts?.length}}
      </div>
      <button class="btn btn-primary" (click)="nextExtract()" [disabled]="currentIndex >= extracts.length-1">
        <div class="d-flex align-items-center">
          <span class="d-none d-md-block me-md-2">{{ 'msg#preview.next' | sqMessage}}</span>
          <i class="fas fa-arrow-right"></i>
        </div>
      </button>
    </div>
  </ng-template>

</ng-container>

<ng-template #loadingTpl>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-grow" role="status"></div>
  </div>
</ng-template>
