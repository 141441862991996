<div class="list-group list-group-flush">
    <a *ngFor="let query of recentQueriesService.recentqueries | slice:startIndex:endIndex"
        class="recent-query-item list-group-item list-group-item-action d-flex align-items-center"
        [routerLink]="[searchRoute]" [queryParams]="getQueryParams(query.query)" [state]="getRouterState(query.query)"
        (click)="openRecentQuery(query)">
        <span class="query-text me-auto text-truncate" title="{{ query.query.text }}">{{ query.query.text }}</span>
        <span *ngIf="query.date" class="query-date ms-2 text-muted small text-right">{{ query.date | sqDate }}</span>
        <i *ngIf="enableSave" class="query-save ms-2 fas fa-save" [title]="'msg#recentQueries.save' | sqMessage" (click)="saveQuery(query, $event)"></i>
        <i *ngIf="enableDelete"class="query-delete ms-2 fas fa-times" [title]="'msg#recentQueries.delete' | sqMessage" (click)="deleteQuery(query, $event)"></i>
    </a>
    <div *ngIf="recentQueriesService.recentqueries.length === 0" class="list-group-item text-center text-muted fst-italic small unstriped py-5">
        {{ 'msg#recentQueries.noRecentQuery' | sqMessage }}
    </div>
</div>
