<div class="parent-class" [ngClass]="getBgClass()">
  <div class="header-nav">
    <app-header></app-header>
  </div>
  <div class="container">
    <div
      joyrideStep="firstStepForTdamm"
      [nextTemplate]="nextButton"
      [stepContent]="introTemplate"
      (next)="onNext(0)"
    ></div>

    <div class="header font-family-rajdhani-medium">
      <div class="beta-container">
        <div class="px-3 py-1 beta-text">BETA</div>
      </div>
      <h1 [ngClass]="{ 'mobile-heading': ui.screenSizeIsLessOrEqual('xs') }">
        TIME DOMAIN AND MULTI-MESSENGER ASTRONOMY SEARCH INTERFACE
      </h1>

      <p class="font-family-rajdhani-regular">
        Powered by Science Discovery Engine
      </p>
    </div>
    <ng-template #introTemplate>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title walkThrough-title"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          TDAMM WALKTHROUGH
        </div>
      </div>
      <p class="joyride-content">
        Welcome to the NASA Time Domain and Multi Messenger Astronomy Interface
        (powered by the Science Discovery Engine).
      </p>
      <p class="joyride-content">
        This walkthrough will guide you through the features of the interface
        step by step.
      </p>
      <p class="joyride-content">
        You can progress through the features with the right arrow button or go
        back to a previous feature with the left arrow button.
      </p>
      <p class="joyride-content">
        You can skip the tutorial by clicking the X on the top right corner.
      </p>
    </ng-template>
    <ng-template #prevButton>
      <img
        id="close-icon"
        style="cursor: pointer"
        width="45"
        height="45"
        src="assets/img/walkthrough-previous.svg"
      />
    </ng-template>
    <ng-template #nextButton>
      <img
        id="close-icon"
        width="45"
        height="45"
        style="cursor: pointer"
        src="assets/img/walkthrough-next.svg"
      />
    </ng-template>
    <div class="tdamm-middle-section">
      <div
        *ngIf="ui.screenSizeIsGreater('sm')"
        class="tabs-content"
        [ngClass]="{
          'tabs-content-messengers':
            this.selectedTab === 'Search by Messengers',
          'tabs-content-objects': this.selectedTab === 'Search by Object',
          'tabs-content-signals': this.selectedTab === 'Search by Signals'
        }"
      >
        <tabset
          #staticTabs
          [stepContent]="secondStepForTdammContent"
          joyrideStep="secondStepForTdamm"
          [prevTemplate]="prevButton"
        >
          <tab
            *ngFor="let astroTab of astroTabs"
            [heading]="astroTab.tabName"
            (selectTab)="selectCurrentTab(astroTab)"
            class="tab-content-web"
            [ngClass]="{
              'tab-messengers': this.selectedTab === 'Search by Messengers',
              'tab-objects': this.selectedTab === 'Search by Object',
              'tab-signals': this.selectedTab === 'Search by Signals'
            }"
          >
            <ng-container *ngIf="astroTab.tabName === 'Search by Messengers'">
              <ng-container *ngTemplateOutlet="tabsContent"></ng-container>
            </ng-container>
            <ng-container *ngIf="astroTab.tabName === 'Search by Object'">
              <ng-container
                *ngTemplateOutlet="tabsContentObject"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="astroTab.tabName === 'Search by Signals'">
              <ng-container *ngTemplateOutlet="tabsContent2"></ng-container>
            </ng-container>
          </tab>
        </tabset>
      </div>

      <div *ngIf="ui.screenSizeIsLessOrEqual('xs')">
        <div class="row justify-content-center">
          <div class="filters-mobile-section">
            <div
              class="filter-tab-mobile"
              [ngClass]="{
                'messengers-border':
                  selectedMobileTab === 'Search by Messengers',
                'objects-border': selectedMobileTab === 'Search by Object',
                'signals-border': selectedMobileTab === 'Search by Signals'
              }"
              [stepContent]="secondStepForTdammContent"
              joyrideStep="secondStepForTdammMobile"
              [prevTemplate]="prevButton"
              [stepPosition]="'bottom'"
              (next)="onNextMobile()"
            >
              <div
                class="dropdown"
                dropdown
                (isOpenChange)="toggleDropdown('mobileTab', $event)"
              >
                <button
                  id="dropdownMessengers"
                  dropdownToggle
                  type="button"
                  class="dropdown-toggle-mobile font-family-inter-regular"
                  [ngClass]="{ 'dropdown-bg': isDropdownOpenMobileTab }"
                >
                  {{ selectedMobileTab }}
                  <!-- prettier-ignore -->
                  <span
                    class="dropdown-icon-mobile"
                    [ngClass]="{ 'rotate': isDropdownOpenMobileTab }"
                    ><img [src]="dropdownIconMobileTab"
                  /></span>
                </button>
                <ul
                  *dropdownMenu
                  class="dropdown-menu dropdown-mobile"
                  role="menu"
                  aria-labelledby="dropdownMessengers"
                >
                  <li role="menuitem" *ngFor="let messenger of astroTabs">
                    <a
                      class="dropdown-item font-family-inter-regular"
                      (click)="onMobileTabChange(messenger.tabName)"
                      >{{ messenger.tabName }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>

            <ng-container *ngIf="selectedMobileTab === 'Search by Messengers'">
              <ng-container *ngTemplateOutlet="messengerTab"></ng-container>
            </ng-container>
            <ng-container *ngIf="selectedMobileTab === 'Search by Object'">
              <ng-container *ngTemplateOutlet="objectTab"></ng-container>
            </ng-container>
            <ng-container *ngIf="selectedMobileTab === 'Search by Signals'">
              <ng-container *ngTemplateOutlet="signalTab"></ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div
        class="footer font-family-rajdhani-regular"
        [ngClass]="{ 'footer-mobile': ui.screenSizeIsLessOrEqual('xs') }"
      >
        <ng-container
          *ngIf="
            selectedTab === 'Search by Messengers' ||
            (selectedMobileTab === 'Search by Messengers' &&
              ui.screenSizeIsLessOrEqual('xs'))
          "
        >
          <ng-container *ngTemplateOutlet="messengerFooter"></ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            selectedTab === 'Search by Object' ||
            (selectedMobileTab === 'Search by Object' &&
              ui.screenSizeIsLessOrEqual('xs'))
          "
        >
          <ng-container *ngTemplateOutlet="objectFooter"></ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            selectedTab === 'Search by Signals' ||
            (selectedMobileTab === 'Search by Signals' &&
              ui.screenSizeIsLessOrEqual('xs'))
          "
        >
          <ng-container *ngTemplateOutlet="signalFooter"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #tabsContent>
  <div class="search-form">
    <div class="dropdown-group">
      <div class="form-group dropdowns">
        <div
          class="dropdown"
          dropdown
          (isOpenChange)="toggleDropdown('main', $event)"
        >
          <button
            [stepContent]="
              selectedTab === 'Search by Messengers'
                ? thirdStepForTdammContent
                : null
            "
            [joyrideStep]="
              selectedTab === 'Search by Messengers'
                ? 'thirdStepForTdamm'
                : null
            "
            [prevTemplate]="prevButton"
            id="dropdownMessengers"
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle font-family-inter-regular"
            [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
          >
            {{ selectedMessenger }}
            <span class="dropdown-icon" [ngClass]="{ rotate: isDropdownOpen }"
              ><img [src]="dropdownIcon" alt="dropdown icon"
            /></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu filter-dropdown-height"
            [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
            role="menu"
            aria-labelledby="dropdownMessengers"
          >
            <ng-container *ngFor="let messenger of filteredArray">
              <li *ngIf="selectedMessenger !== messenger.value" role="menuitem">
                <a
                  class="dropdown-item font-family-inter-regular"
                  (click)="
                    onSelectMessenger(
                      messenger,
                      selectedTab === 'Search by Object'
                        ? 'object'
                        : selectedTab === 'Search by Signal'
                        ? 'signal'
                        : 'messenger'
                    )
                  "
                  >{{ messenger.value }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="form-group dropdowns">
        <div
          class="dropdown"
          dropdown
          (isOpenChange)="toggleDropdown('subtype', $event)"
          [isDisabled]="!showSubtype"
          [stepContent]="fourthStepForTdammContent"
          joyrideStep="fourthStepForTdamm"
          [prevTemplate]="prevButton"
        >
          <button
            id="dropdownSubtypes"
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle"
            [ngClass]="{ 'dropdown-bg': isDropdownOpenSubtype }"
          >
            {{ selectedSubtype }}
            <span
              class="dropdown-icon"
              [ngClass]="{ rotate: isDropdownOpenSubtype }"
              ><img [src]="dropdownIconSubtype" alt="dropdown icon"
            /></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu filter-dropdown-height"
            role="menu"
            aria-labelledby="dropdownSubtypes"
            [ngClass]="{ 'dropdown-bg': isDropdownOpenSubtype }"
          >
            <ng-container *ngFor="let subtype of messengersSubFilterObj">
              <li *ngIf="selectedSubtype !== subtype.value" role="menuitem">
                <a
                  class="dropdown-item font-family-inter-regular"
                  (click)="onSelectSubtype(subtype)"
                  >{{ subtype.value }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="form-group search-bar"
      [stepContent]="fifthStepForTdammContent"
      joyrideStep="fifthStepForTdamm"
    >
      <!-- (keyup.enter)="" -->
      <input
        type="text"
        placeholder="Type your query"
        id="query"
        class="form-control"
        [(ngModel)]="searchText"
        autocomplete="off"
        (keyup.enter)="onInputEnter()"
      />
      <div
        class="search-button font-family-rajdhani-semibold"
        (click)="searchQuery()"
      >
        SEARCH
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tabsContentObject>
  <div class="search-form">
    <div class="dropdown-group">
      <div class="form-group dropdowns">
        <div
          class="dropdown"
          dropdown
          (isOpenChange)="toggleDropdown('main', $event)"
        >
          <button
            id="dropdownMessengers"
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle font-family-inter-regular"
            [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
          >
            {{ selectedMessenger }}
            <span class="dropdown-icon" [ngClass]="{ rotate: isDropdownOpen }"
              ><img [src]="dropdownIcon" alt="dropdown icon"
            /></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu filter-dropdown-height"
            role="menu"
            aria-labelledby="dropdownMessengers"
            [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
          >
            <ng-container *ngFor="let messenger of filteredArray">
              <li *ngIf="selectedMessenger !== messenger.value" role="menuitem">
                <a
                  class="dropdown-item font-family-inter-regular"
                  (click)="
                    onSelectMessenger(
                      messenger,
                      selectedTab === 'Search by Object'
                        ? 'object'
                        : selectedTab === 'Search by Signal'
                        ? 'signal'
                        : 'messenger'
                    )
                  "
                  >{{ messenger.value }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="form-group dropdowns">
        <div
          class="dropdown"
          dropdown
          [isDisabled]="!showSubtype"
          (isOpenChange)="toggleDropdown('subtype', $event)"
        >
          <button
            id="dropdownSubtypes"
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle"
            [ngClass]="{ 'dropdown-bg': isDropdownOpenSubtype }"
          >
            {{ selectedSubtype }}
            <span
              class="dropdown-icon"
              [ngClass]="{ rotate: isDropdownOpenSubtype }"
              ><img [src]="dropdownIconSubtype" alt="dropdown icon"
            /></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu filter-dropdown-height"
            role="menu"
            aria-labelledby="dropdownSubtypes"
            [ngClass]="{ 'dropdown-bg': isDropdownOpenSubtype }"
          >
            <ng-container *ngFor="let subtype of messengersSubFilterObj">
              <li *ngIf="selectedSubtype !== subtype.value" role="menuitem">
                <a
                  class="dropdown-item font-family-inter-regular"
                  (click)="onSelectSubtype(subtype)"
                  >{{ subtype.value }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="form-group search-bar">
      <!-- (keyup.enter)="" -->
      <input
        type="text"
        placeholder="Type your query"
        id="query"
        class="form-control"
        [(ngModel)]="searchText"
        autocomplete="off"
        (keyup.enter)="onInputEnter()"
      />
      <div
        class="search-button font-family-rajdhani-semibold"
        (click)="searchQuery()"
      >
        SEARCH
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tabsContent2>
  <div class="search-form">
    <div class="dropdown-group-2">
      <div class="form-group dropdowns-2">
        <div
          class="dropdown"
          dropdown
          (isOpenChange)="toggleDropdown('main', $event)"
        >
          <button
            id="dropdownMessengers"
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle font-family-inter-regular"
            [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
          >
            {{ selectedSignal }}
            <span class="dropdown-icon" [ngClass]="{ rotate: isDropdownOpen }"
              ><img [src]="dropdownIcon" alt="dropdown icon"
            /></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu filter-dropdown-height"
            role="menu"
            aria-labelledby="dropdownMessengers"
            [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
          >
            <ng-container *ngFor="let messenger of signalsFilterObj">
              <li *ngIf="selectedSignal !== messenger.value" role="menuitem">
                <a
                  class="dropdown-item font-family-inter-regular"
                  (click)="onSelectMessenger(messenger, 'signal')"
                  >{{ messenger.value }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <div class="form-group search-bar dropdowns-2">
        <input
          type="text"
          placeholder="Type your query"
          id="query"
          class="form-control"
          [(ngModel)]="searchText"
          autocomplete="off"
          (keyup.enter)="onInputEnter()"
        />
        <div
          class="search-button-3 font-family-rajdhani-semibold"
          (click)="searchQuery()"
        >
          SEARCH
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messengerTab>
  <div class="filter-tab-mobile messengers-border">
    <div
      class="dropdown dropdown-mobile-section"
      dropdown
      [stepContent]="thirdStepForTdammContent"
      joyrideStep="thirdStepForTdammMobile"
      [prevTemplate]="prevButton"
      (isOpenChange)="toggleDropdown('main', $event)"
    >
      <button
        id="dropdownMessengers"
        dropdownToggle
        type="button"
        class="dropdown-toggle-mobile font-family-inter-regular"
        [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
      >
        {{ selectedMessenger }}
        <span class="dropdown-icon-mobile"
          ><img [src]="dropdownIcon" alt=""
        /></span>
      </button>
      <ul
        *dropdownMenu
        class="dropdown-menu dropdown-mobile filter-dropdown-height"
        role="menu"
        aria-labelledby="dropdownMessengers"
      >
        <li role="menuitem" *ngFor="let messenger of filteredArray">
          <a
            class="dropdown-item font-family-inter-regular"
            (click)="
              onSelectMessenger(
                messenger,
                selectedMobileTab === 'Search by Object'
                  ? 'object'
                  : selectedMobileTab === 'Search by Signal'
                  ? 'signal'
                  : 'messenger'
              )
            "
            >{{ messenger.value }}</a
          >
        </li>
      </ul>
    </div>
    <div
      class="dropdown dropdown-mobile-section"
      dropdown
      [isDisabled]="!showSubtype"
      [stepContent]="fourthStepForTdammContent"
      joyrideStep="fourthStepForTdammMobile"
      [prevTemplate]="prevButton"
      (isOpenChange)="toggleDropdown('subtype', $event)"
    >
      <button
        id="dropdownSubtypes"
        dropdownToggle
        type="button"
        class="btn dropdown-toggle-mobile font-family-inter-regular"
        [ngClass]="{ 'dropdown-bg': isDropdownOpenSubtype }"
      >
        {{ selectedSubtype }}
        <span class="dropdown-icon-mobile"
          ><img [src]="dropdownIconSubtype" alt=""
        /></span>
      </button>
      <ul
        *dropdownMenu
        class="dropdown-menu dropdown-mobile filter-dropdown-height"
        role="menu"
        aria-labelledby="dropdownSubtypes"
      >
        <li role="menuitem" *ngFor="let messenger of messengersSubFilterObj">
          <a
            class="dropdown-item font-family-inter-regular"
            (click)="onSelectSubtype(messenger)"
            >{{ messenger.value }}</a
          >
        </li>
      </ul>
    </div>
    <ng-container *ngTemplateOutlet="searchBarMobile"></ng-container>
  </div>
</ng-template>

<ng-template #objectTab>
  <div class="filter-tab-mobile objects-border">
    <div
      class="dropdown dropdown-mobile-section"
      dropdown
      (isOpenChange)="toggleDropdown('main', $event)"
    >
      <button
        id="dropdownMessengers"
        dropdownToggle
        type="button"
        class="dropdown-toggle-mobile font-family-inter-regular"
        [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
      >
        {{ selectedMessenger }}
        <span class="dropdown-icon-mobile"
          ><img [src]="dropdownIcon" alt=""
        /></span>
      </button>
      <ul
        *dropdownMenu
        class="dropdown-menu dropdown-mobile filter-dropdown-height"
        role="menu"
        aria-labelledby="dropdownMessengers"
      >
        <li role="menuitem" *ngFor="let messenger of filteredArray">
          <a
            class="dropdown-item font-family-inter-regular"
            (click)="
              onSelectMessenger(
                messenger,
                selectedMobileTab === 'Search by Object'
                  ? 'object'
                  : selectedMobileTab === 'Search by Signal'
                  ? 'signal'
                  : 'messenger'
              )
            "
            >{{ messenger.value }}</a
          >
        </li>
      </ul>
    </div>
    <div
      class="dropdown dropdown-mobile-section"
      dropdown
      [isDisabled]="!showSubtype"
      (isOpenChange)="toggleDropdown('subtype', $event)"
    >
      <button
        id="dropdownMessengers"
        dropdownToggle
        type="button"
        class="btn dropdown-toggle-mobile font-family-inter-regular"
        [ngClass]="{ 'dropdown-bg': isDropdownOpenSubtype }"
      >
        {{ selectedSubtype }}
        <span class="dropdown-icon-mobile"
          ><img [src]="dropdownIconSubtype" alt=""
        /></span>
      </button>
      <ul
        *dropdownMenu
        class="dropdown-menu dropdown-mobile filter-dropdown-height"
        role="menu"
        aria-labelledby="dropdownMessengers"
      >
        <li role="menuitem" *ngFor="let messenger of messengersSubFilterObj">
          <a
            class="dropdown-item font-family-inter-regular"
            (click)="onSelectSubtype(messenger)"
            >{{ messenger.value }}</a
          >
        </li>
      </ul>
    </div>
    <ng-container *ngTemplateOutlet="searchBarMobile"></ng-container>
  </div>
</ng-template>

<ng-template #signalTab>
  <div class="filter-tab-mobile signals-border">
    <div
      class="dropdown dropdown-mobile-section"
      dropdown
      (isOpenChange)="toggleDropdown('main', $event)"
    >
      <button
        id="dropdownMessengers"
        dropdownToggle
        type="button"
        class="dropdown-toggle-mobile font-family-inter-regular"
        [ngClass]="{ 'dropdown-bg': isDropdownOpen }"
      >
        {{ selectedSignal }}
        <span class="dropdown-icon-mobile"
          ><img [src]="dropdownIcon" alt=""
        /></span>
      </button>
      <ul
        *dropdownMenu
        class="dropdown-menu dropdown-mobile filter-dropdown-height"
        role="menu"
        aria-labelledby="dropdownMessengers"
      >
        <li role="menuitem" *ngFor="let messenger of signalsFilterObj">
          <a
            class="dropdown-item font-family-inter-regular"
            (click)="
              onSelectMessenger(
                messenger,
                selectedMobileTab === 'Search by Object'
                  ? 'object'
                  : selectedMobileTab === 'Search by Signal'
                  ? 'signal'
                  : 'messenger'
              )
            "
            >{{ messenger.value }}</a
          >
        </li>
      </ul>
    </div>
    <ng-container *ngTemplateOutlet="searchBarMobile"></ng-container>
  </div>
</ng-template>

<ng-template #searchBarMobile>
  <div
    class="form-group search-bar-mobile"
    [stepContent]="fifthStepForTdammContent"
    joyrideStep="fifthStepForTdammMobile"
    [prevTemplate]="prevButton"
  >
    <input
      type="text"
      placeholder="Type your query"
      id="query"
      class="form-control"
      autocomplete="off"
      [(ngModel)]="searchText"
      (keyup.enter)="onInputEnter()"
    />
    <div
      class="search-button-mobile font-family-rajdhani-semibold"
      (click)="searchQuery()"
    >
      SEARCH
    </div>
  </div>
</ng-template>

<ng-template #messengerFooter>
  <p>
    <span class="font-family-rajdhani-bold">Messengers</span> can be used to
    study the acceleration and production processes in the astrophysical sources
    they come from, which are the most powerful particle accelerators in the
    Universe.
  </p>
</ng-template>

<ng-template #objectFooter>
  <p>
    An astronomical, celestial or stellar
    <span class="font-family-rajdhani-bold">object</span> is a naturally
    occurring physical entity, association, or structure that exists within the
    observable universe.
  </p>
</ng-template>

<ng-template #signalFooter>
  <p>
    <span class="font-family-rajdhani-bold">Signals,</span> the Star formation,
    Ionized Gas, and Nebular Abundances Legacy Survey, is a large observing
    programme designed to investigate massive star formation and H II regions in
    a sample of local extended galaxies.
  </p>
</ng-template>

<ng-template #secondStepForTdammContent>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title walkThrough-title"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      1 - SEARCH BY MESSENGER, OBJECT OR SIGNAL
    </div>
  </div>
  <p class="joyride-content">
    The default tab allows you to search by messenger. Clicking on the other
    tabs allows you to search by object or signal. The same steps apply to all
    tabs.
  </p>
</ng-template>

<ng-template #thirdStepForTdammContent>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title walkThrough-title"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      2 - SELECT MESSENGERS
    </div>
  </div>
  <p class="joyride-content">
    Use the dropdown menu to choose from a variety of messenger types.
  </p>
</ng-template>

<ng-template #fourthStepForTdammContent>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title walkThrough-title"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      3 - SELECT SUBTYPES
    </div>
  </div>
  <p class="joyride-content">
    Use the dropdown menu to choose from a variety of messenger subtypes.
  </p>
</ng-template>

<ng-template #fifthStepForTdammContent>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title walkThrough-title"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      4 - SEARCH BAR
    </div>
  </div>
  <p class="joyride-content">
    Type your query here, then press enter on your keyboard or click on the
    search button to search.
  </p>
</ng-template>
