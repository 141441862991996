import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ImagePreloaderService } from "./services/image-preloader.service";

@Injectable({
  providedIn: "root",
})
export class AppResolver implements Resolve<any[]> {
  constructor(private imgPreloadService: ImagePreloaderService) {}

  preloadSciFocusImages: string[] = [
    "assets/img/Background/all-image1.webp",
    "assets/img/Background/astrophysics-image1.webp",
    "assets/img/Background/biophysical-image1.webp",
    "assets/img/Background/earthScience-image1.webp",
    "assets/img/Background/heliophysics-image1.webp",
    "assets/img/Background/planetary-image1.webp",
    "assets/img/Background/all-image2.webp",
    "assets/img/Background/astrophysics-image2.webp",
    "assets/img/Background/biophysical-image2.webp",
    "assets/img/Background/earthScience-image2.webp",
    "assets/img/Background/heliophysics-image2.webp",
    "assets/img/Background/planetary-image2.webp",
    "assets/img/navbar-all.webp",
    "assets/img/navbar-earth-science.webp",
    "assets/img/navbar-planetary.webp",
    "assets/img/navbar-astrophysics.webp",
    "assets/img/navbar-bio-physical.webp",
    "assets/img/navbar-heliophysics.webp",
    "assets/img/messengers-web-bg.png",
    "assets/img/messengers-bg.png",
    "assets/img/objects-bg.png",
    "assets/img/objects-web-bg.png",
    "assets/img/signals-bg.png",
    "assets/img/signals-web-bg.png",
  ];

  preloadSearchStoryImages: string[] = [
    "assets/img/search-story/blue_yellow_gradiant.png",
    "assets/img/search-story/red_gradiant.png",
    "assets/img/search-story/mobile_red_gradiant.png",
    "assets/img/search-story/brown-gradiant.png",
    "assets/img/search-story/Ashish_Acharya.png",
    "assets/img/search-story/BC_RAY.png",
    "assets/img/search-story/Eli_Walker.png",
    "assets/img/search-story/Kaylin_bugbee.png",
    "assets/img/search-story/blue_gradiant.png",
    "assets/img/search-story/Emily_Foshee.png",
    "assets/img/search-story/mobile_blue_gradiant.png",
    "assets/img/search-story/mobile_brown_gradiant.png",
    "assets/img/search-story/mobile_blue_yellow_gradiant.png",
  ];

  resolve(route: ActivatedRouteSnapshot): Promise<any[]> {
    if (route.routeConfig?.path === "MySearchStory") {
      return this.imgPreloadService.preload(this.preloadSearchStoryImages);
    } else {
      return this.imgPreloadService.preload(this.preloadSciFocusImages);
    }
  }
}
