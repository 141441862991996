<div *ngIf="true">
  <app-sde-facet-card
    *ngIf="includesAstroFilters()"
    [type]="'main-filter'"
    [title]="'Messengers'"
    [icon]="'fas fa-sitemap'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [searchable]="false"
      [results]="results"
      [aggregation]="'Messengers_h'"
      [showCount]="true"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
  <app-sde-facet-card
    *ngIf="includesAstroFilters()"
    [type]="'main-filter'"
    [title]="'Objects'"
    [icon]="'fas fa-sitemap'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [searchable]="false"
      [results]="results"
      [aggregation]="'Objects_h'"
      [showCount]="true"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
  <app-sde-facet-card
    *ngIf="includesAstroFilters()"
    [type]="'main-filter'"
    [title]="'Signals'"
    [icon]="'fas fa-sitemap'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [searchable]="false"
      [results]="results"
      [aggregation]="'Signals_h'"
      [showCount]="true"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
  <app-sde-facet-card
    [type]="'main-filter'"
    [title]="'Science Knowledge Sources'"
    [icon]="'fas fa-sitemap'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [searchable]="false"
      [results]="results"
      [aggregation]="'Treepath'"
      [showCount]="true"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
  <app-sde-facet-card
    [type]="'main-filter'"
    [title]="'Science Data Repositories'"
    [icon]="'fas fa-book'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [searchable]="false"
      [showCount]="true"
      [results]="results"
      [aggregation]="'SMDDataRepositories'"
      [showCount]="false"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
  <!--SMD Facets-->
  <app-sde-facet-card
    [type]="'main-filter'"
    [title]="'Platforms'"
    [icon]="'fas fa-layer-group'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [results]="results"
      [aggregation]="'Platforms'"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
  <app-sde-facet-card
    [type]="'main-filter'"
    [title]="'Instruments'"
    [icon]="'fas fa-toolbox'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [results]="results"
      [aggregation]="'Instruments'"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
  <app-sde-facet-card
    [type]="'main-filter'"
    [title]="'Missions'"
    [icon]="'far fa-address-card'"
    [collapsible]="true"
    [startCollapsed]="true"
    class="d-block mt-2"
  >
    <app-sde-facet-list
      #facet
      [results]="results"
      [aggregation]="'Missions'"
      [allowAnd]="false"
      [allowOr]="true"
      [allowExclude]="false"
    ></app-sde-facet-list>
  </app-sde-facet-card>
</div>
