import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "linkDetector",
})
export class LinkDetectorPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    // Regular expression to match URLs
    const urlRegex =
      /((https?|ftp)\:\/\/)?([a-z0-9+!*(),;?&=\$_.-]+(\:[a-z0-9+!*(),;?&=\$_.-]+)?@)?([a-z0-9-.]*)\.([a-z]{2,4})(\:[0-9]{2,5})?(\/([a-z0-9+\$_-]\.?)+)*\/?(\?[a-z+&\$_.-][a-z0-9;:@&%=+\/\$_.-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?/gi;

    const replacedText = value.replace(urlRegex, (url) => {
      if (!url.startsWith("http")) {
        url = "http://" + url;
      }

      let linkText = url.replace(urlRegex, url);

      if (linkText.charAt(linkText.length - 1) === ".") {
        linkText = linkText.slice(0, -1);
      }

      return `<a class="uc-link" href="${linkText}" target="_blank">${linkText}</a>`;
    });

    return replacedText;
  }
}
