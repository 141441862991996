import { DOCUMENT } from "@angular/common";
import {
  Directive,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  Inject,
  Output,
  EventEmitter,
} from "@angular/core";
import { filter, fromEvent, Subscription } from "rxjs";

@Directive({
  selector: "[outsideClick]",
})
export class OutsideClickDirective implements AfterViewInit, OnDestroy {
  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @Output() outsideClick = new EventEmitter<void>();

  documentClickSubscription: Subscription | undefined;

  ngAfterViewInit(): void {
    this.documentClickSubscription = fromEvent(this.document, "click")
      .pipe(
        filter((event) => {
          return !this.isInside(event.target as HTMLElement);
        })
      )
      .subscribe(() => {
        this.outsideClick.emit();
      });
  }

  ngOnDestroy(): void {
    this.documentClickSubscription?.unsubscribe();
  }

  isInside(elementToCheck: HTMLElement): boolean {
    return (
      elementToCheck === this.element.nativeElement ||
      this.element.nativeElement.contains(elementToCheck)
    );
  }
}
