<div class="container">
  <div class="top-half-mobile-container">
    <img
      id="logo"
      src="assets/img/nasa-meatball.svg"
      alt="nasa meatball"
      width="80px"
    />
    <div class="sde-text-mobile font-family-rajdhani-regular">
      Science Discovery Engine
    </div>
    <div class="beta-container">
      <div class="beta-text">BETA</div>
    </div>
  </div>
  <div class="bottom-half-mobile-container">
    <div class="px-2 inside-div">
      <img
        id="logo"
        src="assets/img/desktop-image.svg"
        alt="nasa meatball"
        width="80px"
      />
      <div class="mobile-view-text font-family-source-sans-pro-regular">
        <span>
          Science Discovery Engine works best on desktop. Select features are
          available on mobile.
        </span>
      </div>
      <div class="my-4 redirect-message font-family-rajdhani-semibold">
        Redirecting In {{ timer }}s...
      </div>
    </div>
  </div>
</div>
