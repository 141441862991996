<sq-loading-bar [active]="loading"></sq-loading-bar>
<!-- METADATA VIEWER -->
<ng-container
  *ngIf="!loading && data && data.record.isMetadataViewer; else docViewer"
>
  <app-sde-metadata-viewer
    [previewData]="data"
    (dataLoadedEvent)="stopLoader($event)"
  ></app-sde-metadata-viewer>
</ng-container>

<ng-template #docViewer>
  <iframe
    class="doc-preview"
    *ngIf="safeUrl"
    #preview
    [src]="safeUrl"
    sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
    [style.--sq-scale]="scale"
    (load)="onLoad()"
  >
  </iframe>
</ng-template>

<ng-content></ng-content>
