<div class="pt-2 pb-3 modal-header">
  <div class="pull-left" style="display: flex">
    <img class="pull-left" src="assets/img/share-doc.png" alt="send_doc" />
    <div class="font-family-rajdhani-regular share-title ml-2 pull-left">
      SHARE DOCUMENT LINK
    </div>
  </div>

  <img
    src="assets/img/close-transparent.png"
    class="pull-right modal-close"
    (click)="closeShareDocument()"
    alt="close_modal"
  />
</div>
<div class="font-family-source-sans-pro-regular py-2 sharing-doc">
  You're sharing the link to the following document.
</div>

<div class="document-details">
  <div class="document-title py-1 font-family-rajdhani-bold">
    {{ recordData.title }}
  </div>
  <div *ngIf="recordData.modified" class="font-family-source-sans-pro-regular">
    Modified on {{ recordData.modified }}
  </div>
</div>

<div class="d-flex justify-content-center button-section">
  <button
    (click)="openEmailDraftWithLink()"
    class="my-3 share-button font-family-rajdhani-regular"
  >
    SHARE VIA EMAIL
  </button>
  <button
    (click)="copyURL(true)"
    class="my-3 copy-button font-family-rajdhani-regular"
  >
    COPY LINK
  </button>
</div>
