import { enHeatmap } from "@sinequa/analytics/heatmap";
import { enAdvanced } from "@sinequa/components/advanced";
import { enAlerts } from "@sinequa/components/alerts";
import { enBaskets } from "@sinequa/components/baskets";
import { enFacet } from "@sinequa/components/facet";
import { enFeedback } from "@sinequa/components/feedback";
import { enFilters } from "@sinequa/components/filters";
import { enLabels } from "@sinequa/components/labels";
import { enMetadata } from "@sinequa/components/metadata";
import { enNotification } from "@sinequa/components/notification";
import { enPreview } from "@sinequa/components/preview";
import { enResult } from "@sinequa/components/result";
import { enResultsView } from "@sinequa/components/results-view";
import { enSavedQueries } from "@sinequa/components/saved-queries";
import { enSearch } from "@sinequa/components/search";
import { enSearchForm } from "@sinequa/components/search-form";
import { enSelection } from "@sinequa/components/selection";
import { enStatusBar } from "@sinequa/components/status-bar";
import { enUserSettings } from "@sinequa/components/user-settings";
import { enCore } from "@sinequa/core";
import { Utils } from "@sinequa/core/base";
import { LocaleData } from "@sinequa/core/intl";
import "intl/locale-data/jsonp/en-US"; // Safari
import d3Format from "../../../../node_modules/d3-format/locale/de-DE.json";
import d3Time from "../../../../node_modules/d3-time-format/locale/de-DE.json";
import appMessages from "./messages/en.json";

const messages = Utils.merge(
  {},
  enCore,
  enAdvanced,
  enAlerts,
  enBaskets,
  enFacet,
  enFeedback,
  enHeatmap,
  enLabels,
  enMetadata,
  enNotification,
  enPreview,
  enResult,
  enResultsView,
  enSavedQueries,
  enSelection,
  enSearch,
  enStatusBar,
  enUserSettings,
  appMessages,
  enFilters,
  enSearchForm
);

export default <LocaleData>{
  intl: {
    locale: "en-US",
  },
  d3: {
    locale: "en-US",
    format: d3Format,
    time: d3Time,
  },
  messages: messages,
};
