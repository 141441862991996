import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

declare const MathJax: any;

@Directive({
  selector: "[appMathJax]",
})
export class MathJaxDirective implements OnChanges {
  @Input() appMathJax: string | SafeHtml;

  constructor(private el: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnChanges() {
    if (typeof this.appMathJax === "string") {
      this.el.nativeElement.innerHTML = this.appMathJax;
    } else {
      this.el.nativeElement.innerHTML = this.sanitizer.sanitize(
        1,
        this.appMathJax
      ); // 1 stands for SecurityContext.HTML
    }
    MathJax.typesetPromise([this.el.nativeElement]);
  }
}
