<table
  class="table table-responsive"
  [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
>
  <tbody>
    <tr *ngIf="record.authors">
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 10%"
      >
        {{ "msg#documentDetails.authors" | sqMessage }}
      </td>
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 90%"
      >
        {{ record.authors }}
      </td>
    </tr>
    <tr *ngIf="record.docformat">
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 10%"
      >
        {{ "msg#documentDetails.docformat" | sqMessage }}
      </td>
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 90%"
      >
        {{ record.docformat }}
      </td>
    </tr>
    <tr *ngIf="record.modified">
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 10%"
      >
        {{ "msg#documentDetails.modified" | sqMessage }}
      </td>
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 90%"
      >
        {{ record.modified }}
      </td>
    </tr>
    <tr *ngIf="record.size">
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 10%"
      >
        {{ "msg#documentDetails.size" | sqMessage }}
      </td>
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 90%"
      >
        {{ record.size | sqNumber }} bytes
      </td>
    </tr>
    <tr *ngIf="record.treepath">
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 10%"
      >
        {{ "msg#documentDetails.location" | sqMessage }}
      </td>
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 90%"
      >
        {{ record.treepath }}
      </td>
    </tr>
    <tr *ngIf="record.filename">
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 10%"
      >
        {{ "msg#documentDetails.filename" | sqMessage }}
      </td>
      <td
        [ngStyle]="{ color: isDark() ? 'white' : 'black' }"
        style="width: 90%"
      >
        {{ record.filename }}
      </td>
    </tr>
  </tbody>
</table>
