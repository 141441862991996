import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { Filter } from "@sinequa/core/web-services";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import googleAnalyticsConstants from "sde/src/app/analytics/google-analytics.constant";

@Component({
  selector: "app-multi-messenger-astronomy",
  templateUrl: "./multi-messenger-astronomy.component.html",
  styleUrls: ["./multi-messenger-astronomy.component.scss"],
})
export class MultiMessengerAstronomyComponent implements OnInit {
  constructor(
    public ui: UIService,
    public searchService: SearchService,
    private $gaService: GoogleAnalyticsService,
    private router: Router
  ) {}

  @ViewChild("staticTabs", { static: false }) staticTabs?: TabsetComponent;

  searchText: string = "";
  isDropdownOpen: boolean = false;
  isDropdownOpenSubtype: boolean = false;
  isDropdownOpenMobileTab: boolean = false;

  dropdownIcon: string = "assets/img/down-icon.svg";
  dropdownIconSubtype: string = "assets/img/down-icon.svg";
  dropdownIconMobileTab: string = "assets/img/down-icon.svg";

  selectMessengersOptions: string[] = [
    "Messenger 1",
    "Messenger 2",
    "Messenger 3",
  ];
  messenegersFilterObj: any = [];
  objectsFilterObj: any = [];
  signalsFilterObj: any = [];
  messengersSubFilterObj: any = [];
  selectedParentFilter: any = [];
  selectedSubTypeFilter: any = [];
  filterField: any;
  filterFieldMessenger: any;
  filterFieldObject: any;
  filterFieldSignals: any;

  showSubtype: boolean = false;
  filterSelected: boolean = false;

  astroTabs: any[] = [
    {
      tabName: "Search by Messengers",
    },
    {
      tabName: "Search by Object",
    },
    {
      tabName: "Search by Signals",
    },
  ];

  selectedMessenger = "Select Messengers";
  selectedSignal = "Select Signals";
  selectedSubtype = "Select Subtypes";
  selectedTab = "Search by Messengers";
  selectedMobileTab = "Search by Messengers";
  filteredArray = [];

  ngOnInit(): void {
    if (this.ui.screenSizeIsLessOrEqual("xs")) {
      this.selectedTab = "";
    }
    if (this.selectedMessenger === "Select Messengers") {
      this.filterSelected = false;
    }
    const query = this.searchService.query;
    query.name = "tdamm_query_service";
    query.scope = "All";
    // query.tab = "Documentation";
    this.searchService.getResults(query).subscribe((results) => {
      results.aggregations.forEach((res) => {
        switch (res.name) {
          case "Messengers_h":
            this.filterFieldMessenger = res.column;
            break;
          case "Objects_h":
            this.filterFieldObject = res.column;
            break;
          case "Signals_h":
            this.filterFieldSignals = res.column;
            break;
          default:
            // Handle other cases if necessary
            break;
        }

        if (res?.items) {
          res?.items.forEach((element: any) => {
            if (element.value === "Messengers") {
              this.messenegersFilterObj = element?.items;
            } else if (element.value === "Objects") {
              this.objectsFilterObj = element?.items;
            } else if (element.value === "Signals") {
              this.signalsFilterObj = element?.items;
            }
          });
        }
      });
      this.updateFilteredArray();
    });
  }

  toggleDropdown(type: "main" | "subtype" | "mobileTab", isOpen: boolean) {
    if (type === "main") {
      this.isDropdownOpen = isOpen;
      this.dropdownIcon = isOpen
        ? "assets/img/drop-down-icon.svg"
        : "assets/img/down-icon.svg";
    } else if (type === "subtype") {
      this.isDropdownOpenSubtype = isOpen;
      this.dropdownIconSubtype = isOpen
        ? "assets/img/drop-down-icon.svg"
        : "assets/img/down-icon.svg";
    } else if (type === "mobileTab") {
      this.isDropdownOpenMobileTab = isOpen;
      this.dropdownIconMobileTab = isOpen
        ? "assets/img/drop-down-icon.svg"
        : "assets/img/down-icon.svg";
    }
  }

  updateFilteredArray() {
    switch (this.selectedTab || this.selectedMobileTab) {
      case "Search by Messengers":
        this.filteredArray = this.messenegersFilterObj;
        this.filterField = this.filterFieldMessenger;
        this.selectedMessenger = "Select Messengers";
        this.selectedSubtype = "Select Subtypes";
        break;
      case "Search by Object":
        this.filteredArray = this.objectsFilterObj;
        this.filterField = this.filterFieldObject;
        this.selectedMessenger = "Select Objects";
        this.selectedSubtype = "Select Subtypes";
        break;
      case "Search by Signals":
        this.filterField = this.filterFieldSignals;
        this.selectedSignal = "Select Signals";
        break;
      default:
        this.filteredArray = [];
        break;
    }
  }

  onSelectMessenger(messenger: any, type) {
    if (type === "messenger") {
      this.selectedMessenger = messenger.value;
    } else if (type === "object") {
      this.selectedMessenger = messenger.value;
    } else if (type === "signal") {
      this.selectedSignal = messenger.value;
    }

    this.selectedParentFilter = messenger;

    if (messenger && messenger?.items) {
      this.showSubtype = true;
      this.messengersSubFilterObj = messenger?.items;

      this.selectedSubtype = "Select Subtypes";
    } else {
      this.showSubtype = false;
      this.messengersSubFilterObj = [];
      this.selectedSubTypeFilter = [];

      this.selectedSubtype = "Select Subtypes";
    }

    this.updateFilterSelectedState();
  }

  selectedFilters() {
    let filterReq: Filter = {
      field: this.filterField,
      value: this.selectedParentFilter.$path,
      display: this.selectedParentFilter.value,
    };

    let filtersArray = [filterReq];

    if (this.selectedParentFilter?.items) {
      if (
        this.selectedParentFilter &&
        this.selectedSubTypeFilter.length === 0
      ) {
        if (this.messengersSubFilterObj) {
          this.messengersSubFilterObj.forEach((filter) => {
            filtersArray.push({
              field: this.filterField,
              value: filter.$path,
              display: filter.value,
            });
          });
        }
      } else {
        filtersArray.push({
          field: this.filterField,
          value: this.selectedSubTypeFilter.$path,
          display: this.selectedSubTypeFilter.value,
        });
      }
      filterReq = {
        operator: "or",
        filters: filtersArray,
      };
    } else {
      filterReq;
    }

    this.searchService.query.addFilter(filterReq);
  }

  updateFilterSelectedState() {
    if (this.selectedParentFilter) {
      this.filterSelected = true;
    } else {
      this.filterSelected = false;
    }
  }

  onSelectSubtype(subtype) {
    this.selectedSubtype = subtype.value;
    this.selectedSubTypeFilter = subtype;
    this.updateFilterSelectedState();
  }

  selectCurrentTab(tab) {
    this.selectedTab = tab.tabName;
    if (tab) {
      this.resetFilters();
    }

    this.updateFilteredArray();
  }

  onMobileTabChange(tab) {
    this.selectedMobileTab = tab;
    if (tab) {
      this.resetFilters();
    }
    this.updateFilteredArray();
  }

  resetFilters() {
    this.filterSelected = false;
    this.showSubtype = false;
  }

  onNext(tabId) {
    if (this.staticTabs && this.staticTabs.tabs[tabId]) {
      this.staticTabs.tabs[tabId].active = true;
    }
  }

  onNextMobile() {
    this.selectedMobileTab = "Search by Messengers";
  }

  getBgClass(): any {
    return {
      "messengers-bg":
        this.selectedMobileTab === "Search by Messengers" &&
        this.ui.screenSizeIsLessOrEqual("xs"),
      "messengers-bg-web":
        this.selectedTab === "Search by Messengers" &&
        this.ui.screenSizeIsGreater("sm"),
      "objects-bg": this.selectedMobileTab === "Search by Object",
      "objects-bg-web":
        this.selectedTab === "Search by Object" &&
        this.ui.screenSizeIsGreater("sm"),
      "signals-bg": this.selectedMobileTab === "Search by Signals",
      "signals-bg-web":
        this.selectedTab === "Search by Signals" &&
        this.ui.screenSizeIsGreater("sm"),
    };
  }

  onInputEnter() {
    this.searchQuery();
  }

  currentTabName(): string {
    switch (this.selectedTab) {
      case "Search by Messengers":
        return "Messengers";
      case "Search by Object":
        return "Object";
      case "Search by Signals":
        return "Signals";
      default:
        return ""; // Return a default value if no case matches
    }
  }

  searchQuery() {
    // NB: tdamm query service should also come from environment
    this.searchService.query.name = "tdamm_query_service";
    this.searchService.query.scope = "Astrophysics";
    this.searchService.query.tab = "Data";
    this.searchService.query.text = this.searchText;
    if (this.filterSelected === true) {
      this.selectedFilters();
    }

    this.searchService.searchText("search");
    this.$gaService.event(
      googleAnalyticsConstants.action.search,
      googleAnalyticsConstants.category.search,
      this.searchText ? this.searchText : "EMPTY SEARCH",
      0,
      true,
      {
        app_name: "TDAMM",
        page: googleAnalyticsConstants.currentPage.tdammHomepPage,
        url: this.router.url,
        search_term: this.searchText ? this.searchText : "EMPTY SEARCH",
        tabName: this.currentTabName(),
      }
    );
  }
}
