<div class="content-page-background home">
  <div>
    <app-header></app-header>
  </div>
  <div class="d-flex flex-column justify-content-center">
    <div
      *ngIf="!contentCurationFormSubmitted"
      class="content-curation-content d-flex flex-column justify-content-center align-items-start"
    >
      <div class="content-curation-text font-family-rajdhani-regular">
        CONTENT CURATION REQUEST
      </div>
      <div class="req-inf-text">* = required information</div>
      <form
        [formGroup]="contentCurationForm"
        (ngSubmit)="onSubmitContentCurationForm()"
        class="d-flex flex-column"
      >
        <div class="form-data">
          <div class="form-group">
            <div class="label-container">
              <p class="font-family-rajdhani-medium field-name">
                Name&nbsp;(First and Last)*
              </p>
              <div
                *ngIf="f.firstName.touched && f.firstName.errors"
                class="validation-message"
              >
                <div *ngIf="f.firstName.errors.required">
                  (This is a mandatory field)
                </div>
              </div>
            </div>
            <div>
              <input
                placeholder="Name"
                type="text"
                formControlName="firstName"
                class="form-control input-field"
                [ngClass]="{
                  'is-invalid': f.firstName.touched && f.firstName.errors
                }"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="label-container">
              <p class="font-family-rajdhani-medium field-name">Email*</p>

              <div
                *ngIf="f.email.touched && f.email.errors"
                class="validation-message"
              >
                <div *ngIf="f.email.errors.required">
                  (This is a mandatory field)
                </div>
                <div *ngIf="f.email.errors.email">
                  (Enter a valid email address)
                </div>
              </div>
            </div>
            <div>
              <input
                placeholder="Email"
                type="text"
                formControlName="email"
                class="form-control input-field"
                [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"
              />
            </div>
          </div>
        </div>

        <div class="dropdown-items">
          <div class="scientific-toggle">
            <div class="header-div">
              <label class="font-family-rajdhani-medium field-name"
                >Scientific Focus*</label
              >
              <div
                *ngIf="showScientificDropdownError"
                class="validation-message"
              >
                (Please select at least one scientific focus)
              </div>
            </div>
            <div class="dropdown-wrapper">
              <button
                type="button"
                class="toggle-button"
                (click)="ScientificToggleDropdown()"
                [ngClass]="{
                  'dropdown-bottom-border': isScientificDropdownOpen
                }"
              >
                <div class="selected-chips">
                  <ng-container
                    *ngFor="
                      let selectedItem of selectedItemsInScientificDropdown;
                      let i = index
                    "
                  >
                    <div *ngIf="i < 2" class="chip">
                      {{ selectedItem }}
                      <span
                        class="close-icon"
                        (click)="removeItemScientificDropdown(selectedItem)"
                      >
                        <img
                          src="assets/img/small-cross-icon.svg"
                          alt="Close Icon"
                        />
                      </span>
                    </div>

                    <div
                      *ngIf="
                        i === 2 && selectedItemsInScientificDropdown.length > 2
                      "
                      class="additional-selected-values"
                    >
                      +{{ selectedItemsInScientificDropdown.length - 2 }}
                    </div>
                  </ng-container>
                  <div
                    *ngIf="selectedItemsInScientificDropdown.length !== 0"
                    class="arrow d-flex flex-column justify-content-center"
                  >
                    <i
                      class="fa-solid fa-chevron-down"
                      [ngClass]="{ 'rotate-icon': isScientificDropdownOpen }"
                    ></i>
                  </div>
                </div>
                <div
                  *ngIf="selectedItemsInScientificDropdown.length === 0"
                  class="flex-container"
                >
                  <span class="justify-content-center placeholder-text">
                    Select from science divisions
                  </span>
                  <div class="arrow d-flex flex-column justify-content-center">
                    <i
                      class="fa-solid fa-chevron-down"
                      [ngClass]="{ 'rotate-icon': isScientificDropdownOpen }"
                    ></i>
                  </div>
                </div>
              </button>
              <div
                *ngIf="isScientificDropdownOpen"
                style="position: absolute; width: 500px"
                class="dropdown"
              >
                <div
                  *ngFor="let item of scientificDropdownItems"
                  class="checkbox-container"
                >
                  <div class="sci-img-wrapper">
                    <img [src]="item.icon" />
                  </div>

                  <label class="label">
                    {{ item.name }}
                  </label>
                  <div class="checkbox-wrapper">
                    <input
                      class="facet-tree-checkbox mx-2 form-control"
                      type="checkbox"
                      [value]="item.name"
                      (change)="toggleItemScientificDropdown(item.name)"
                      [checked]="
                        selectedItemsInScientificDropdown.includes(item.name)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scientific-toggle">
            <div class="header-div">
              <label class="font-family-rajdhani-medium field-name"
                >Source Type*</label
              >
              <div
                *ngIf="showSourceTypeDropdownError"
                class="validation-message"
              >
                (Please select at least one source type)
              </div>
            </div>

            <div class="dropdown-wrapper">
              <button
                type="button"
                class="toggle-button"
                (click)="SourceTypeToggleDropdown()"
                [ngClass]="{
                  'dropdown-bottom-border': isSourceTypeDropdownOpen
                }"
              >
                <div class="selected-chips">
                  <ng-container
                    *ngFor="
                      let selectedItem of selectedItemsInSourceTypeDropdown;
                      let i = index
                    "
                  >
                    <div *ngIf="i < 2" class="chip">
                      <span> {{ selectedItem }}</span>

                      <span
                        class="close-icon"
                        (click)="removeItemSourceTypeDropdown(selectedItem)"
                      >
                        <img
                          src="assets/img/small-cross-icon.svg"
                          alt="Close Icon"
                      /></span>
                    </div>

                    <div
                      *ngIf="
                        i === 2 && selectedItemsInSourceTypeDropdown.length > 2
                      "
                      class="additional-selected-values"
                    >
                      +{{ selectedItemsInSourceTypeDropdown.length - 2 }}
                    </div>
                  </ng-container>
                  <div
                    class="arrow d-flex flex-column justify-content-center"
                    *ngIf="selectedItemsInSourceTypeDropdown.length !== 0"
                  >
                    <i
                      class="fa-solid fa-chevron-down"
                      [ngClass]="{ 'rotate-icon': isSourceTypeDropdownOpen }"
                    ></i>
                  </div>
                </div>
                <div
                  *ngIf="selectedItemsInSourceTypeDropdown.length === 0"
                  class="flex-container"
                >
                  <span class="justify-content-center placeholder-text">
                    Select from source types
                  </span>
                  <div class="arrow d-flex flex-column justify-content-center">
                    <i
                      class="fa-solid fa-chevron-down"
                      [ngClass]="{ 'rotate-icon': isSourceTypeDropdownOpen }"
                    ></i>
                  </div>
                </div>
              </button>
              <div
                *ngIf="isSourceTypeDropdownOpen"
                style="position: absolute; width: 500px"
                class="dropdown"
              >
                <div
                  *ngFor="let item of dataValuesItems"
                  class="checkbox-container"
                >
                  <label class="label">{{ item }}</label>
                  <div class="checkbox-wrapper">
                    <input
                      class="facet-tree-checkbox mx-2 form-control"
                      type="checkbox"
                      [value]="item"
                      (change)="toggleItemSourceTypeDropdown(item)"
                      [checked]="
                        selectedItemsInSourceTypeDropdown.includes(item)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sourcelink-top-div">
          <div class="sourcelink-main-div">
            <div class="label-container">
              <p class="font-family-rajdhani-medium field-name">Source Link*</p>
              <div
                *ngIf="f.sourceLink.touched && f.sourceLink.errors"
                class="validation-message"
              >
                <div *ngIf="f.sourceLink.errors.required">
                  (This is a mandatory field)
                </div>
              </div>
            </div>
            <div>
              <input
                placeholder="Add your source link"
                type="text"
                formControlName="sourceLink"
                class="form-control input-field-sourcelink"
                [ngClass]="{
                  'is-invalid': f.sourceLink.touched && f.sourceLink.errors
                }"
              />
            </div>
          </div>

          <div class="addinfo-main-div">
            <div class="label-container">
              <p class="font-family-rajdhani-medium field-name">
                Additional Information
              </p>
            </div>
            <div>
              <input
                placeholder="Any additional comments?"
                type="text"
                formControlName="additionalInfo"
                class="form-control input-field-sourcelink"
              />
            </div>
          </div>
        </div>
        <div class="submit-button-div">
          <button class="submit-button">Submit</button>
        </div>
      </form>
    </div>
  </div>

  <div class="thank-you-container" *ngIf="contentCurationFormSubmitted">
    <div class="thankyou-text font-family-rajdhani-regular">Thank You</div>
    <div class="thankyou-textcontent font-family-rajdhani-regular">
      We have received your content curation request, and we will be in touch
      shortly. We thank you for your patience.
    </div>
  </div>
</div>
