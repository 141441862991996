import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-search-document-details",
  templateUrl: "./search-document-details.component.html",
  styleUrls: ["./search-document-details.component.scss"],
})
export class SearchDocumentDetailsComponent implements OnInit {
  @Input() record: any;

  constructor() {}

  ngOnInit(): void {}
  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }
}
