<ul *ngIf="!!searchtabs || !!customtabs" class="nav nav-tabs">
    <ng-container *ngIf="!!searchtabs">
        <li *ngFor="let tab of searchtabs" class="nav-item">
            <a href="#" class="nav-link {{tabsClass}}" [ngClass]="{'active' : tab === currentTab, 'disabled': tab.count == 0}" (click)="selectTab(tab)">
                <i [ngClass]="iconMap[tab.name]"></i>
                {{(tab.display || tab.name) | sqMessage}}
                <span class="count" *ngIf="showCounts">({{tab.count | sqNumber}})</span>
            </a>
        </li>
    </ng-container>
    <ng-container *ngIf="!!customtabs">
        <li *ngFor="let tab of customtabs" class="nav-item">
            <a href="#" class="nav-link {{tabsClass}}" [ngClass]="{'active' : tab === currentTab, 'disabled': tab.count == 0}" (click)="selectTab(tab, false)">
                <i [ngClass]="iconMap[tab.name]"></i>
                {{(tab.display || tab.name) | sqMessage}}
                <span class="count" *ngIf="showCounts && tab.count >= 0">({{tab.count | sqNumber}})</span>
            </a>
        </li>
    </ng-container>
</ul>
