<h1 mat-dialog-title>Warning large document</h1>
<div mat-dialog-content>
  The document you are trying to open is too large to be loaded by your browser.
</div>
<div mat-dialog-content>
  <span>We recommend you open the document in its original source.</span>
</div>
<div mat-dialog-actions>
  <button class="btn btn-primary" mat-dialog-close>Ok</button>
</div>
