import {NgModule} from "@angular/core";
import "./polyfills";

/**
 * This module contains a variety of base types and utility classes including the {@link Utils} utilility class
 */
@NgModule({
    imports: [
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class BaseModule {
}