
<sq-loading-bar [active]="loading"></sq-loading-bar>

<iframe *ngIf="safeUrl && !isExcelDocFormat; else noSandbox"
  #preview
  [src]="safeUrl"
  sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
  [style.--sq-scale]="scale"
  (load)="onLoad()">
</iframe>
<ng-template #noSandbox>
  <iframe *ngIf="safeUrl"
    #preview
    [src]="safeUrl"
    [style.--sq-scale]="scale"
    (load)="onLoad()">
  </iframe>
</ng-template>

<ng-content></ng-content>
