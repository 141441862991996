import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { JoyrideService } from "ngx-joyride";
import { Subscription } from "rxjs";
import { environment } from "sde/src/environments/environment";
import { googleAnalyticsConstants } from "../../analytics/google-analytics.constant";
import { CommonService } from "./../../services/common.service";

@Component({
  selector: "app-help-support-modal",
  templateUrl: "./help-support-modal.component.html",
  styleUrls: ["./help-support-modal.component.scss"],
})
export class HelpSupportModalComponent implements OnInit, OnDestroy {
  incomingValue: Subscription;
  homePageValue: string = "";
  receivedData: string;
  isInScix: any;
  public subscription: Subscription;

  constructor(
    public modalRef: BsModalRef,
    private readonly joyrideService: JoyrideService,
    public commonService: CommonService,
    protected ui: UIService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private searchService: SearchService,
    private $gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.incomingValue = this.commonService.currentPageSource.subscribe(
      (currentPage: any) => {
        this.homePageValue = currentPage;
      }
    );
    this.commonService.isInScix.subscribe((data) => {
      this.isInScix = data;
    });
  }

  ngOnDestroy(): void {
    this.incomingValue.unsubscribe();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  activeImage: string = "";

  clickFeedback(newImage: string, source: any) {
    this.router.navigate(["/contact-us"], { queryParams: source });
    this.modalRef.hide();
    this.activeImage = newImage;
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.feedback,
      0,
      true,
      {
        app_name: this.commonService.isTdammHome() ? "TDAMM" : "SDE",
        page: this.commonService.isTdammHome()
          ? googleAnalyticsConstants.currentPage.tdammHomepPage
          : googleAnalyticsConstants.currentPage.sdeHomePage,

        url: this.router.url,
        debug_mode: environment.googleAnalyticsDebugMode,
      }
    );
  }

  hoverFeedback(newImage: string) {
    this.activeImage = newImage;
  }
  unhoverFeedback(newImage: string) {
    this.activeImage = newImage;
  }

  clickFaq(newImage: string) {
    this.router.navigate(["/HelpPage"]);
    this.activeImage = newImage;
    this.modalRef.hide();
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.faqPage,
      0,
      true,
      {
        app_name: "SDE",
        page: googleAnalyticsConstants.currentPage.sdeHomePage,
        url: this.router.url,
        debug_mode: environment.googleAnalyticsDebugMode,
      }
    );
  }
  hoverFaq(newImage: string) {
    this.activeImage = newImage;
  }

  unhoverFaq(originalImage: string) {
    this.activeImage = originalImage;
  }

  clickHoverHelp(newImage: string) {
    this.activeImage = newImage;
  }

  hoverkHoverHelp(newImage: string) {
    this.activeImage = newImage;
  }

  unhoverkHoverHelp(originalImage: string) {
    this.activeImage = originalImage;
  }

  clickWalkthrough(newImage: string) {
    if (this.homePageValue === "home") {
      this.activeImage = newImage;
      this.joyrideService.startTour(
        {
          logsEnabled: false,
          steps: [
            "firstStep",
            "secondStep@home",
            "thirdStep@home",
            "fourthStep@home",
            "fifthStep@home",
            "sixthStep@home",
          ],
          themeColor: "#023D8B",
          showCounter: false,
        } // Your steps order
      );
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.walkthrough,
        0,
        true,
        {
          app_name: this.commonService.isTdammHome() ? "TDAMM" : "SDE",
          page: this.commonService.isTdammHome()
            ? googleAnalyticsConstants.currentPage.tdammHomepPage
            : googleAnalyticsConstants.currentPage.sdeHomePage,

          url: this.router.url,
          debug_mode: environment.googleAnalyticsDebugMode,
        }
      );
    } else if (this.homePageValue === "results") {
      let filterStep;
      let stepsForWalkthrough;
      if (this.isMobile) {
        filterStep = "mobileFilterStep";
      } else {
        filterStep = "eleventhStep";
      }

      if (this.isInScix === "Publications") {
        stepsForWalkthrough = [
          "seventhStep",
          "eightStep",
          "ninethStep",
          "tenthStep",
          "twelveStep",
          "thirteenStep",
        ];
      }
      if (this.searchService.rowCount == 0) {
        stepsForWalkthrough = [
          "seventhStep",
          "eightStep",
          "ninethStep",
          "tenthStep",
          filterStep,
          "twelveStep",
        ];
      } else if (this.searchService.query.name === "tdamm_query_service") {
        stepsForWalkthrough = [
          "firstStepForTdammResults",
          "seventhStep",
          "eightStep",
          "tenthStep",
          filterStep,
          "twelveStep",
          "thirteenStep",
          "tdammTagStep",
        ];
      } else {
        stepsForWalkthrough = [
          "seventhStep",
          "eightStep",
          "ninethStep",
          "tenthStep",
          filterStep,
          "twelveStep",
          "thirteenStep",
        ];
      }

      this.activeImage = newImage;
      this.joyrideService.startTour(
        {
          logsEnabled: false,
          steps: stepsForWalkthrough,
          themeColor: "#023D8B",
          showCounter: false,
        } // Your steps order
      );
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.walkthrough,
        0,
        true,
        {
          app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
          page: this.commonService.isInTdammApp()
            ? googleAnalyticsConstants.currentPage.tdammResultsPage
            : googleAnalyticsConstants.currentPage.sdeResultsPage,

          url: this.router.url,
          debug_mode: environment.googleAnalyticsDebugMode,
        }
      );
    } else if (this.homePageValue === "tdamm") {
      let stepsForTdamm;
      if (this.ui.screenSizeIsLessOrEqual("xs")) {
        stepsForTdamm = [
          "firstStepForTdamm",
          "secondStepForTdammMobile",
          "thirdStepForTdammMobile",
          "fourthStepForTdammMobile",
          "fifthStepForTdammMobile",
        ];
      } else {
        stepsForTdamm = [
          "firstStepForTdamm",
          "secondStepForTdamm",
          "thirdStepForTdamm",
          "fourthStepForTdamm",
          "fifthStepForTdamm",
        ];
      }
      this.joyrideService.startTour(
        {
          logsEnabled: false,
          steps: stepsForTdamm,
          themeColor: "#023D8B",
          showCounter: false,
        } // Your steps order
      );
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.walkthrough,
        0,
        true,
        {
          app_name: "TDAMM",
          page: googleAnalyticsConstants.currentPage.tdammHomepPage,
          url: this.router.url,
          debug_mode: environment.googleAnalyticsDebugMode,
        }
      );
    }
    this.modalRef.hide();
  }
  hoverWalkthrough(newImage: string) {
    this.activeImage = newImage;
  }

  unhoverWalkthrough(originalImage: string) {
    this.activeImage = originalImage;
  }

  toggleHoverHelp(newImage: string): void {
    this.commonService.toggleHoverHelp(true);
    this.activeImage = newImage;
    this.modalRef.hide();

    if (this.homePageValue === "home") {
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.hoverHelp,
        0,
        true,
        {
          app_name: "SDE",
          page: googleAnalyticsConstants.currentPage.sdeHomePage,
          url: this.router.url,
          debug_mode: environment.googleAnalyticsDebugMode,
        }
      );
    } else if (this.homePageValue === "results") {
      // Add your new statement here

      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.hoverHelp, // Change this to the appropriate label
        0,
        true,
        {
          app_name: "SDE",
          page: googleAnalyticsConstants.currentPage.sdeResultsPage, // Change this to the appropriate page
          url: this.router.url,
          debug_mode: environment.googleAnalyticsDebugMode,
        }
      );
    }
  }

  clickOnApiDocumentationLinkOnWeb() {}
  clickOnApiDocumentationLinkOnMob() {}
}
