import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { SearchService } from "@sinequa/components/search";
import { Results } from "@sinequa/core/web-services";

@Component({
  selector: "app-search-visualization",
  templateUrl: "./search-visualization.component.html",
  styleUrls: ["./search-visualization.component.scss"],
})
export class SearchVisualizationComponent implements OnInit {
  // Boolean indicating if search is in full-screen mode
  public _fullscreen: boolean = false;

  // Reference that can be used to set heatmap facet card to fullscreen
  @ViewChild("timelineEl", { read: ElementRef }) timelineEl: ElementRef;

  constructor(public searchService: SearchService) {
    // Subscribe to the search service 'events' observable to detect when a heatmap fullscreen state
    // should return to a non-fullscreen state. This currently should only trigger when a user selects
    // an axis label or matrix cell in the heatmap component while in full screen mode

    this.searchService.events.subscribe((event) => {
      if (["make-query", "update-query"].includes(event.type)) {
        this.tryExitFullscreenHeatmap();
      }
    });
  }

  @Input() results: Results;

  ngOnInit(): void {
    document.addEventListener("fullscreenchange", (event) => {
      let elem = this.timelineEl.nativeElement as HTMLElement;
      if (document.fullscreenElement) {
        // Set private state variable
        this._fullscreen = true;

        /* After setting element to full screen, some styles (that were previously 
        set by parent) need to be explicitly set */
        elem.style["background-color"] = "white";
        elem.style["overflow"] = "scroll";
      } else {
        // Set private state variable
        this._fullscreen = false;
        // On full-screen exit, revert properties back
        delete elem.style["background-color"];
        elem.style["overflow"] = "visible";
      }
    });
  }
  /**
   * Toggle fullscreen results mode based on the selected_elem input string. Currently
   * the only two relevant input option are 'results' and 'heatmap'.
   */
  toggleFullscreen(selected_elem: string) {
    let elem: HTMLElement;

    if (selected_elem === "timeline")
      elem = this.timelineEl.nativeElement as HTMLElement;
    else return;

    // If fullscreen state variable is true, exit fullscreen mode. Otherwise, enter fullscreen mode
    this._fullscreen === true
      ? this.exitFullscreen(elem)
      : this.enterFullscreen(elem);
  }

  /**
   * Dedicated function for exiting a fullscreen mode heatmap element
   */
  tryExitFullscreenHeatmap() {
    if (this.timelineEl !== undefined) {
      let elem = this.timelineEl.nativeElement as HTMLElement;
      if (this._fullscreen) {
        this.exitFullscreen(elem);
      }
    }
  }

  /**
   * Trigger fullscreen mode on input template element
   */
  enterFullscreen(elem: HTMLElement) {
    this._fullscreen = true;
    let methodToInvoke =
      elem.requestFullscreen ||
      elem["mozRequestFullScreen"] /* Firefox */ ||
      elem["webkitRequestFullscreen"] /* Chrome, Safari and Opera */ ||
      elem["msRequestFullscreen"]; /* IE/Edge */
    if (methodToInvoke) methodToInvoke.call(elem);
  }

  /**
   * Exit fullscreen mode on input template element
   */
  exitFullscreen(elem: HTMLElement) {
    this._fullscreen = false;
    let doc = document as Document;

    // Check that current fullscreen element matches the target fullscreen element
    var check_elem = document.fullscreenElement;
    if (elem === check_elem) {
      let methodToInvoke =
        doc.exitFullscreen ||
        doc["mozCancelFullScreen"] /* Firefox */ ||
        doc["webkitExitFullscreen"] /* Chrome, Safari and Opera */ ||
        doc["msExitFullscreen"]; /* IE/Edge */
      if (methodToInvoke) methodToInvoke.call(doc);
    }
  }

  get isFullScreen(): boolean {
    return this._fullscreen;
  }

  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }
}
