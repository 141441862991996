<div [hidden]="isHidden()">

    <div *ngIf="searchable" class="p-1" [formGroup]="searchGroup">
      <input class="form-control form-control-sm" #searchInput
          type="search" spellcheck="false" autocomplete="off"
          formControlName="searchQuery"
          [placeholder]="'msg#facet.searchPlaceholder' | sqMessage">
    </div>

    <div class="position-relative">
      <sq-loading-bar [active]="searchActive | async"></sq-loading-bar>
    </div>

    <ul class="list-group list-group-flush">
      <ng-container *ngFor="let item of items"
          [ngTemplateOutlet]="itemTpl || defaultItemTpl"
          [ngTemplateOutletContext]="{$implicit: item, instance: this}">
      </ng-container>

      <li *ngIf="!suggests && data?.$hasMore"
          class="list-group-item list-group-item-action text-center"
          role="button" (click)="loadMore()">
          <span>{{'msg#facet.loadMore' | sqMessage}}</span>
      </li>

      <li class="list-group-item text-center py-3" *ngIf="suggests?.length === 0">
          <span>{{'msg#facet.searchNoResult' | sqMessage}}</span>
      </li>
    </ul>

</div>

<!-- Template for displaying an aggregation item-->
<ng-template #defaultItemTpl let-item>
    <li class="list-group-item list-group-item-action d-flex"
        [ngClass]="{'sq-selected': item.$selected, 'sq-filtered': item.$filtered}"
        role="button" (click)="filterItem(item, $event)">

        <!-- Tree aggregation opener -->
        <span *ngIf="data?.isTree" class="item-opener" [ngStyle]="{'--depth' : item.$level ?? 0}">
            <a *ngIf="item.hasChildren" href="#" (click)="open(item, $event)" [title]="(item.$opened ? 'msg#facet.closeItem' : 'msg#facet.openItem') | sqMessage">
                <span *ngIf="item.$opening" class="fas fa-sync fa-fw fa-spin"></span>
                <span *ngIf="!item.$opening && item.$opened" class="fas fa-caret-down fa-fw"></span>
                <span *ngIf="!item.$opening && !item.$opened" class="fas fa-caret-right fa-fw"></span>
            </a>
            <span *ngIf="!item.hasChildren" class="fas fa-fw"></span>
        </span>

        <!-- Checkbox for multi-selection -->
        <div>
            <input type="checkbox" class="form-check-input me-2" tabindex="-1"
                [checked]="item.$selected || item.$filtered"
                [disabled]="item.$filtered"
                (change)="selectItem(item)"
                (click)="$event.stopPropagation()"
                *ngIf="showCheckbox">
        </div>

        <!-- item display / value -->
        <span class="text-truncate me-auto" [title]="item | sqValue:item.$column">
            {{item | sqValue:item.$column}}
        </span>

        <!-- Item counter -->
        <span *ngIf="showCount && item.count" class="ms-2 text-muted small" style="z-index: 1;">{{item.count | sqNumber}}</span>
    </li>

    <!-- Tree aggregation sub-elements -->
    <ng-container *ngIf="item.$opened">
        <ng-container *ngFor="let i of item.items"
            [ngTemplateOutlet]="itemTpl || defaultItemTpl"
            [ngTemplateOutletContext]="{$implicit: i, instance: this}">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #headerTpl>
  <div class="flex-grow-1 text-nowrap text-end">
    <div class="btn-group btn-group-sm" role="group" *ngIf="showCheckbox && selected.length > 0">
      <button type="button" class="btn btn-primary" (click)="addFilter(selected)">
        <b>{{'msg#facet.apply' | sqMessage}}</b>
      </button>
      <button *ngIf="allowExclude || ((allowAnd || allowOr) && selected.length > 1)"
        type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
        data-bs-toggle="dropdown" aria-expanded="false">
        <span class="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li *ngIf="allowOr && selected.length > 1">
          <a class="dropdown-item" role="button" (click)="addFilter(selected)">{{'msg#facet.applyOr' | sqMessage}}</a>
        </li>
        <li *ngIf="allowAnd && selected.length > 1">
          <a class="dropdown-item" role="button" (click)="addFilter(selected, {and: true})">{{'msg#facet.applyAnd' | sqMessage}}</a>
        </li>
        <li *ngIf="allowExclude">
          <a class="dropdown-item" role="button" (click)="addFilter(selected, {not: true})">{{'msg#facet.applyNot' | sqMessage}}</a>
        </li>
      </ul>
    </div>

    <button class="btn btn-sm btn-light ms-1" *ngIf="data?.$filtered.length" [sqTooltip]="'msg#facet.clearSelects' | sqMessage" (click)="clearAllFilters()">
      <i class="sq-filter-clear"></i>
    </button>
  </div>
</ng-template>
